import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import CircleButton from 'components/utils/CircleButton'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'
import CustomTools from 'components/utils/CustomTools'
import Alert from 'components/utils/Alert'

import Issue from 'components/main/Issue'
//import Tests from 'components/main/Tests'
//import Lab from 'components/main/Lab'

import IssueInput from 'components/input/IssueInput'
import TestInput from 'components/input/TestInput'
import LabInput from 'components/input/LabInput'
import PresatInput from 'components/input/PresatInput'

import 'styles/drawings.css'

import { coordsNE, coordsXY, angle } from 'scripts/plans';

import { formatDateYMD, formatDateTime, getLocation, catchError, replaceStr, filterData, addActivity } from 'scripts/common'

import { addPending, selectData } from 'scripts/offline';

const PlanDrawings = (props) => {

  // ===========================

  //var plansdrawid; // events(): if an object is updated, save id for plansdraw()

  // drag related variables

  //console.log(JSON.stringify(props.distinctPresets))

  var side = 20; //length of + for coords

  // array for temporarily draw elements

  // both xy and ne are saved. xy is converted to ne, so that plansid will not dictate when to
  // show objects. if ne fall within boundaries of any plan from the JN and GD, they will show.
  // if object has planid of plan selected, you can use xy to plot and not convert from ne

  //var array_temp_n = [];
  //var array_temp_e = [];

  // drawing styles, use for tpts array above

  //var plansid; // continue phasing out this!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

  // ===========================

  // allows icons to be drawn on canvas

  //const material_font = new FontFace( 'material-icons', 'url(materialIcons.woff2)' );
  //document.fonts.add( material_font );

  // const canvasLineSolid = useRef(null)
  // const canvasLineDashS = useRef(null)
  // const canvasLineDashM = useRef(null)
  // const canvasLineDashL = useRef(null)
  // const canvasLineDashDot = useRef(null)
  // const canvasLineDashDot3 = useRef(null)

  const canvasPhoto = useRef(null)
  const video = useRef(null)

  const [isColor, setIsColor] = useState(false) // temporary --> will be replaced will style.colorOther

  const [presetTypes, setPresetTypes] = useState([])
  const [presets, setPresets] = useState([])

  const add = () => {}
  const edit = () => {}
  const planscolors = () => {}
  const classcolor_rainbow = () => {}

  // used for layer filters

  // moved to <Plans /> so that filter persists through zoom!!!!! const [distinctPresets, setDistinctPresets] = useState([])  

  // array for plansdraw

  const arrayDraw = useRef([])
  const arrayDrawTemp = useRef(arrayDraw) // for plansedit
  const arrayDrawUpdate = useRef([]) // stores changes from edit

  // array for issues

  const arrayIssue = useRef([])
  const arrayIssueTemp = useRef(arrayIssue) // for plansedit
  const arrayIssueUpdate = useRef([]) // stores changes from edit

  // actions related to issues

  const [fetchedAction, setFetchedAction] = useState([])

  // array for density tests

  const arrayTest = useRef([])
  const arrayTestTemp = useRef(arrayTest) // for plansedit
  const arrayTestUpdate = useRef([]) // stores changes from edit

  // array for lab samples

  const arrayLab = useRef([])
  const arrayLabTemp = useRef(arrayTest) // for plansedit
  const arrayLabUpdate = useRef([]) // stores changes from edit

  // array for presats samples

  const arrayPresat = useRef([])
  const arrayPresatTemp = useRef(arrayPresat) // for plansedit
  const arrayPresatUpdate = useRef([]) // stores changes from edit

  // both xy and ne are saved. xy is converted to ne, so that plansid will not dictate when to
  // show objects. if ne fall within boundaries of any plan from the JN and GD, they will show.
  // if object has planid of plan selected, you can use xy to plot and not convert from ne

  // why do i have tempX vs arrayTempX ? worth checking into to confirm

  const tempX = useRef(null) // for plansMode = test, lab, issue
  const tempY = useRef(null)

  const arrayTempX = useRef([])
  const arrayTempY = useRef([])

  // so arrayTempX/Y handle single objects before saving. The new temporary preset will save multiple objects.. thus I need a new ref
  // nomenclature is convoluted.. 

  const arrayTemporary = useRef([])
  const arrayTemporaryRedo = useRef([])
  const arrayTemporaryUndo = useRef([])

  // array for undo --> drawn pts that are removed, but may be redrawn with 'redo'

  const arrayUndoX = useRef([])
  const arrayUndoY = useRef([])

  // if plan coords updated and drawing exist, this allows updating the existing coordinates

  const offsetPt = useRef({n: 0, e: 0})

  // array stores info to display pts when tool.snap

  const arraySnap = useRef([])

  // plansedit --> if true, stores i/j for array

  const editing = useRef({
    object: null,
    id: null,
    i: null,
    j: null
  })

  // plansMode: this determines how plans are opened.
  // 1) plansmanage: add/edit plans for use in the field
  // 2) plansTest: locate density tests to be saved in field
  // 3) plansLab: locate lab sampless to be saved in field
  // 4) plansdraw: add/edit objects ie. drawn objects, tests, etc
  // 5) plansFilterPolygon: added to allow object capture based on polygon.. ie tests
  // 6) plansIssue: locate issues to be saved in field
  // 7) plansPresat: locate presats to be saved in field

  // this handles the crosshairs for plansmanage
  const pts = useRef([
    {
        id: "pt1",
        x: Number(props.planInfo.x1) || 300,
        y: Number(props.planInfo.y1) || 400,
        s: side,
        n: "Left Pt (Pt1)",
        fill: "Tomato", //red
        isDragging: false
    },
    {
        id: "pt2",
        x: Number(props.planInfo.x2) || 400,
        y: Number(props.planInfo.y2) || 400,
        s: side,
        n: "Right Pt (Pt2)",
        fill: "Tomato", //red
        isDragging: false
    },
    {
        id: "a1",
        x: Number(props.planInfo.ax1) || 500,
        y: Number(props.planInfo.ay1) || 400,
        s: side,
        n: "Left Side Angle",
        fill: "DodgerBlue", //blue
        isDragging: false
    },
    {
        id: "a2",
        x: Number(props.planInfo.ax2) || 600,
        y: Number(props.planInfo.ay2) || 400,
        s: side,
        n: "Right Side Angle",
        fill: "DodgerBlue", //blue
        isDragging: false
    }
  ])

  // i believe this is abandoned and a new approach when done in layers

  const history = useRef({
    minDisplay: '',
    maxDisplay: '',
    minValue: '',
    maxValue: '',
    start: '',
    end: ''
  })

  //const plansMode = useRef(props.mode)

  // plansDrawMode: keeps track of which draw feature was selected, default pencil
  // plansedit, planspencil, plansmarker, plansbe, planssurveypt, planscomment, etc.

  //const plansDrawMode = useRef('planspencil')

  const drawing = useRef({
    start: false,           // determines if a line was started
    active: false,          // determines if mousedown still
    offset: false,          // determines if a line was started while offset, starts on end()
    refOffset: false,       // this tracks if start() then connect with move() and finish with end()
    refOffsetStart: false,  // if tool.offset, this tracks the starting pt before actually starting
    refOffsetEnd: false,
    refStart: false,        // determines if a line was started while ref, starts on end()
    refEnd: false,          // determines if the line was finished, so that real line can begin
    drag: false,            // currently dragok, and used only for plansmanage...UPDATE LATER
    edit: false,            // handles the editing of objects from the wrench tool
    fill: false             // moved from style to here and used in redraw / addDrawing / offline (prevents unnecessary re-render)
  })

  const [camera, setCamera] = useState({
    start: true, 
    takePhoto: false,
    retry: false
  })

  const [style, setStyle] = useState({
    style: 'points', // default points; freehand vs points
    tool: '',
    color: '', // '#000000FF'; // black
    width: '', // 2.5; //thickness
    line: [], // default solid line
    lineName: 'solid', // this determines which line is selected, had issues comparing an array for the border
    //fill: '', // moving to a ref to avoid unnecessary render and fill disappears because when drawing saved, redraw('style') called and now redraw('fill')
    presetId: null,
    presetName: '' // default custom
  })

  const [tool, setTool] = useState({
    fastDrawing: false, // beta, keeps elev/desc from previous and submits on enter
    scaleDrawings: false, // scales the size of draing objects
    history: false,
    photo: false,
    style: false,
    draw: false,
    edit: false, // used to edit positions
    movePt: false, // used to move points
    insertPt: false, // used to insert points into existing lines
    deletePt: false, // used to delete points from existing lines
    offsetPt: false, // used to correct x,y and/or rotation if plans coordinates adjusted
    ref: false,
    snap: false,
    offset: props.user.device === 'desktop' ? false : true,
    fill: false, // set in toolFill() but not used. instead 'fill' passed as var to redraw().. resolve later
    locate: false,
    direction: false,
    add: false,
    more: false,
    custom: false // allows visiblity of custom tools
  })

  const [layer, setLayer] = useState({
    issue: false,
    failingTest: false,
    search: '', // moved to <plans /> as searchLayer
    startDate: '',
    endDate: '',
    testElev: true, // toggle on/off test elevs
    grading: true,
    post: true
  })  

  // original x,y --> initially set in start()
  // these are used for move() when scrollLeft/Top

  const position = useRef({
    startX: null,
    startY: null,
    originalX: null,
    originalY: null
  })

  // tracks center of window for zoomIn/zoomOut

  const zoom = useRef({
    x: 0,
    y: 0
  })

  // tracks slider for scaling drawings manually

  const sliderScale = useRef(1)

  const [displayCoords, setDisplayCoords] = useState({
    n: '',
    e: ''
  })

  const [isValidated, setIsValidated] = useState({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    description: '',
    elev: '',
    type: '',
    bearing: '',
    bearing90: '',
    bearingDipDirection: '',
    bearingStrike: '',
    dip: '',
    n: '', // used in addLocation
    e: '',
    n1: '', // used for manage --> plans. stores changes here before accepting edits
    e1: '',
    n2: '',
    e2: '',
    image: ''
  })

  const clearIsValidated = () => setIsValidated({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    description: '',
    elev: '',
    type: '',
    bearing: '',
    bearing90: '',
    bearingDipDirection: '',
    bearingStrike: '',
    dip: '',
    n: '',
    e: '',
    n1: '', // used for manage --> plans. stores changes here before accepting edits
    e1: '',
    n2: '',
    e2: '',
    image: ''
  })

  const [isValidatedEdit, setIsValidatedEdit] = useState({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    tool: '',
    color: '',
    width: '',
    line: '',
    fill: '',
    presetId: null,
    description: '',
    elev: '',
    type: '',
    bearing: '',
    bearing90: '',
    bearingDipDirection: '',
    bearingStrike: '',
    dip: '',
    n: '', // used in addLocation
    e: ''
  })

  const clearIsValidatedEdit = () => setIsValidatedEdit({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    tool: '',
    color: '',
    width: '',
    line: '',
    fill: '',
    presetId: null,
    description: '',
    elev: '',
    type: '',
    bearing: '',
    bearing90: '',
    bearingDipDirection: '',
    bearingStrike: '',
    dip: '',
    n: '',
    e: ''
  })

  const isEditedPosition = useRef(false) // tracks position edits
  const isEditedInfo = useRef(false) // tracks info edits

  const isEdited = () => isEditedInfo.current = true

  // used to update preset button if editing object info. modal renders before isValidatedEdit updates...

  const styleEdit = useRef({
    color: '',
    presetId: null,
    presetName: ''
  })

  const [isModal, setIsModal] = useState({    
    edit: false, // blah used for editing info
    help: false,
    alert: false,    
    alertContent: '',
    warning: 0,
    warningContent: '',
  })

  // separating from isModal to avoid headache of mix-matching add/edit purposes

  const [isInput, setIsInput] = useState({
    add: false, // add and edit allows control of tests/lab/issues
    edit: false,
    issue: false,
    action: false,
    test: false,
    lab: false,
    presat: false
  })

  useEffect(() => {

    let canvasDraw = props.canvasDraw.current

    // so google broke preventDefault for React and you cannot set passive: false within the element
    // therefore for now, addEvent here. this is an ongoing issue since 2017ish

    canvasDraw.addEventListener("dblclick", mInfo)

    canvasDraw.addEventListener("mousedown", mstart)
    canvasDraw.addEventListener("mousemove", mmove)
    canvasDraw.addEventListener("mouseup", mend)

    canvasDraw.addEventListener("touchstart", tstart)
    canvasDraw.addEventListener("touchmove", tmove, {passive: false})
    canvasDraw.addEventListener("touchend", tend)

    // canvasDraw.addEventListener('wheel', wheelZoom, {passive: false})
    //
    // canvasDraw.addEventListener('touchstart', pinchStart)
    // canvasDraw.addEventListener('touchmove', pinchMove, {passive: false})
    // canvasDraw.addEventListener('touchend', pinchEnd)
    //
    // document.getElementById('canvascontainer').addEventListener('touchmove', disablePinchZoom, {passive: false})
    // document.getElementById('canvascontainer').addEventListener('dblclick', centerPinchZoom)

    return () => {

      // need to remove previous add because each render will ++ and tool values are frozen for each...

      canvasDraw.removeEventListener("dblclick", mInfo)

      canvasDraw.removeEventListener("mousedown", mstart)
      canvasDraw.removeEventListener("mousemove", mmove)
      canvasDraw.removeEventListener("mouseup", mend)

      canvasDraw.removeEventListener("touchstart", tstart)
      canvasDraw.removeEventListener("touchmove", tmove, {passive: false})
      canvasDraw.removeEventListener("touchend", tend)
    }

    // added distinctPresets to allow dblClick edit, otherwise data isnt loaded before event added

  }, [tool, isValidated, props.distinctPresets]) // events

  useEffect(() => {

    tool.snap ? redraw('snap') : redraw()    

  }, [tool.snap])

  useEffect(() => {
    if (!props.isLoading) {      
      fetchData()
    } else {
      //console.log('here2')
    }
  }, [props.isLoading, props.planInfo])  

  useEffect(() => {

    redraw('style') // style used to tell the function to stroke, else the drawing wont show

  }, [style])

  useEffect(() => {

    redraw()

  }, [layer, props.distinctPresets, props.searchLayer])

  const fetchData = () => {

    if (props.mode === 'plansmanage') {

      setTimeout(function() {

        for (let i = 0; i < pts.current.length; i++) {

            let p = pts.current[i];

            drawPt(p.x, p.y, p.s, p.n, p.fill);

        }

      }, 1000)


    } else if (
      props.mode === 'plansIssue' || 
      props.mode === 'plansTest' || 
      props.mode === 'plansLab' ||
      props.mode === 'plansPresat' || 
      props.mode === 'plansdraw' || 
      props.mode === 'plansFilterPolygon'
    ) {
      
      if (props.user.offline && props.user.offlineJob === props.filter.jobNumber) {        

        selectData('PlansDrawDrawings').then(res => {
          arrayDraw.current = res.sort((a, b) => new Date(b.entrytime) - new Date(a.entrytime))
          console.log(`fetchData: ${JSON.stringify(arrayDraw.current)}`)
          arrayDrawTemp.current = arrayDraw.current
          redraw('refresh')
        })

        selectData('PlansDrawLab').then(res => {
          arrayLab.current = res.sort((a, b) => Number(b.sampleno) - Number(a.sampleno))
          arrayLabTemp.current = arrayLab.current
          redraw('refresh')
        })

        selectData('PlansDrawTests').then(res => {
          arrayTest.current = res.sort((a, b) => Number(b.testno) - Number(a.testno))
          arrayTestTemp.current = arrayTest.current
          redraw('refresh')
        })

        selectData('DistinctPresets').then(res => props.updateDistinctPresets(res))

        selectData('DrawingPresets').then(res => setPresets(res))

        selectData('PresetTypes').then(res => setPresetTypes(res))

      } else {

        fetch('/api/selectPlansDrawDrawings', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            filter: props.filter,
            planInfo: props.planInfo
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            arrayDraw.current = result.map(data => {

              let startDate = layer.startDate === '' ? new Date('01/01/2000') : new Date(layer.startDate)
              let endDate = layer.endDate === '' ? new Date() : new Date(layer.endDate)
              let entryTime = data.entrytime === null ? '' : formatDateTime(data.entrytime)              

              let filter = filterData(data, props.searchLayer)
              let visible = false
              //(!layer.failingTest && preset !== undefined && preset.status) &&
              if (
                (!layer.issue) &&
                (!layer.failingTest) &&
                ((tool.history && new Date(entryTime) >= startDate && new Date(entryTime) <= endDate) || !tool.history) &&
                (props.searchLayer === '' || filter)
              ) {
                visible = true
              }

              return ({...data, 
                x: data.x.split(","), 
                y: data.y.split(","), 
                n: data.n.split(","), 
                e: data.e.split(","), 
                visible: visible, 
                testdate: formatDateYMD(data.testdate), 
                entrytime: entryTime, 
                modtime: formatDateTime(data.modtime)
              })

            })

            arrayDrawTemp.current = arrayDraw.current

            //console.log(`result split: ${JSON.stringify(arrayDraw.current)}`)
            redraw('refresh')
          },
          (error) => catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'selectPlansDrawDrawings', JSON.stringify(error), props.user.username, props.user.device)

        )

        fetch('/api/selectPlansDrawIssues', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            filter: props.filter,
            planInfo: props.planInfo
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            arrayIssue.current = result.map(data => {

              let startDate = layer.startDate === '' ? new Date('01/01/2000') : new Date(layer.startDate)
              let endDate = layer.endDate === '' ? new Date() : new Date(layer.endDate)
              let issueDate = data.issueDate === null ? '' : formatDateYMD(data.issueDate)
              let entryTime = data.entrytime === null ? '' : formatDateTime(data.entrytime)              

              let filter = filterData(data, props.searchLayer)
              let visible = false
              
              if (    
                (!layer.issue) &&
                (!layer.failingTest) &&            
                ((tool.history && new Date(issueDate) >= startDate && new Date(issueDate) <= endDate) || !tool.history) &&
                (props.searchLayer === '' || filter)
              ) {
                visible = true
              }

              return ({...data, visible: visible, issueDate: issueDate, entrytime: entryTime, modtime: formatDateTime(data.modtime)})
            })
            
            arrayIssueTemp.current = arrayIssue.current

            redraw('refresh')

          },
          (error) => catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'selectPlansDrawIssues', JSON.stringify(error), props.user.username, props.user.device)

        )

        fetch('/api/selectIssueActions', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            filter: props.filter
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            setFetchedAction(result.map((data, i) => {

              return {...data,              
                entrytime: formatDateTime(data.entrytime),
                modtime: formatDateTime(data.modtime),
                actionDate: formatDateYMD(data.actionDate)
              }
  
            }))

          },
          (error) => catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'selectIssueActions', JSON.stringify(error), props.user.username, props.user.device)

        )

        fetch('/api/selectPlansDrawPresat', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            filter: props.filter,
            planInfo: props.planInfo
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            arrayPresat.current = result.map(data => {

              let startDate = layer.startDate === '' ? new Date('01/01/2000') : new Date(layer.startDate)
              let endDate = layer.endDate === '' ? new Date() : new Date(layer.endDate)
              let sampleDate = data.sampleDate === null ? '' : formatDateYMD(data.sampleDate)
              let entryTime = data.entrytime === null ? '' : formatDateTime(data.entrytime)
              let modTime = data.modtime === null ? '' : formatDateTime(data.modtime)              

              let filter = filterData(data, props.searchLayer)
              let visible = false
              //(!layer.failingTest && status) &&
              if (
                (!layer.issue) &&
                (!layer.failingTest) &&
                ((tool.history && new Date(sampleDate) >= startDate && new Date(sampleDate) <= endDate) || !tool.history) &&
                (props.searchLayer === '' || filter)
              ) {
                visible = true
              }

              return ({...data, visible: visible, sampleDate: sampleDate, entrytime: entryTime, modtime: modTime})
            })

            //arrayLab.current = result.map(data => ({...data, visible: true, entrytime: formatDateTime(data.entrytime, true), modtime: formatDateTime(data.modtime, true)}))
            arrayPresatTemp.current = arrayPresat.current

            redraw('refresh')

          },
          (error) => catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'selectPlansDrawPresat', JSON.stringify(error), props.user.username, props.user.device)

        )

        fetch('/api/selectPlansDrawLab', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            filter: props.filter,
            planInfo: props.planInfo
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            arrayLab.current = result.map(data => {

              let startDate = layer.startDate === '' ? new Date('01/01/2000') : new Date(layer.startDate)
              let endDate = layer.endDate === '' ? new Date() : new Date(layer.endDate)
              let sampleDate = data.sampledate === null ? '' : formatDateYMD(data.sampledate)
              let entryTime = data.entrytime === null ? '' : formatDateTime(data.entrytime)
              let modTime = data.modtime === null ? '' : formatDateTime(data.modtime)              

              let filter = filterData(data, props.searchLayer)
              let visible = false
              //(!layer.failingTest && status) &&
              if (
                (!layer.issue) &&
                (!layer.failingTest) &&
                ((tool.history && new Date(sampleDate) >= startDate && new Date(sampleDate) <= endDate) || !tool.history) &&
                (props.searchLayer === '' || filter)
              ) {
                visible = true
              }

              return ({...data, visible: visible, sampledate: sampleDate, entrytime: entryTime, modtime: modTime})
            })

            //arrayLab.current = result.map(data => ({...data, visible: true, entrytime: formatDateTime(data.entrytime, true), modtime: formatDateTime(data.modtime, true)}))
            arrayLabTemp.current = arrayLab.current

            redraw('refresh')

          },
          (error) => catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'selectPlansDrawLab', JSON.stringify(error), props.user.username, props.user.device)

        )

        fetch('/api/selectPlansDrawTests', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            filter: props.filter,
            planInfo: props.planInfo
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            arrayTest.current = result.map(data => {

              let startDate = layer.startDate === '' ? new Date('01/01/2000') : new Date(layer.startDate)
              let endDate = layer.endDate === '' ? new Date() : new Date(layer.endDate)
              let testDate = data.testdate === null ? '' : formatDateYMD(data.testdate)
              let entryTime = data.entrytime === null ? '' : formatDateTime(data.entrytime)
              let modTime = data.modtime === null ? '' : formatDateTime(data.modtime)              

              // this is for failingTest
              let failingTest = null

              if (data.passfail !== 'P') {
                //console.log(`test.passfail: ${test.passfail}`)
                failingTest = arrayTest.current.find(retest => retest.testno === data.testno && retest.passfail === 'P')
                //if (failingTest === undefined) console.warn(`listOfTests-->failingTest-->undefined.`)
              }

              let filter = filterData(data, props.searchLayer)
              let visible = false
              
              if (
                (!layer.issue) &&
                ((layer.failingTest && failingTest === undefined) || (!layer.failingTest)) &&
                ((tool.history && new Date(testDate) >= startDate && new Date(testDate) <= endDate) || !tool.history) &&
                (props.searchLayer === '' || filter)
              ) {
                visible = true
              }

              return ({...data, visible: visible, testdate: testDate, entrytime: entryTime, modtime: modTime, failingTest: failingTest === undefined ? true : false})
            })

            //arrayTest.current = result.map(data => ({...data, visible: true, entrytime: entryTime, modtime: formatDateTime(data.modtime)}))
            arrayTestTemp.current = arrayTest.current

            redraw('refresh')                     

          },
          (error) => catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'selectPlansDrawTests', JSON.stringify(error), props.user.username, props.user.device)

        )

      }

    } else {

      console.warn('Build --> plansMode: ' + JSON.stringify(props.mode))
      catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'fetchData', JSON.stringify(props.mode), props.user.username, props.user.device)

    }

  }

  const selectDrawing = (e) => {

    // if statement allows the editing of a drawing from layers and double click
    // since double click knows i, check if e is an event
    // dblClick helps bypass TD check below, TD handles if button selected instead on table row

    let i
    let dblClick = false
    let nodeName = ''

    if (e.target !== undefined) {

      let target = e.target
      //console.log(`target: ${target.nodeName}`)
      let tr = target.nodeName === 'I' ? target.parentNode.parentNode.parentNode : target.nodeName === 'Button' || target.nodeName === 'INPUT' ? target.parentNode.parentNode : target.parentNode
      let td = tr.getElementsByTagName('td')
      i = td[0].textContent
      nodeName = target.nodeName

    } else {

      dblClick = true
      i = e

    }

    if (i === '' || i === null) {
      alert('Error: data index not found. Contact an admin.')
    } else {

      if (nodeName === 'TD' || dblClick) {

        let preset = false
        if (arrayDraw.current[i].presetId === 0) {

          preset = true

          styleEdit.current = {
            color: arrayDraw.current[i].color,
            presetId: 0,
            presetName: 'Custom'
          }

        } else {

          let found = props.distinctPresets.find(preset => preset.id === arrayDraw.current[i].presetId)
          //console.log(JSON.stringify(found))
          if (found !== undefined) {

            preset = true
            //console.log(`1: ${JSON.stringify(styleEdit.current)}`)
            styleEdit.current = {
              color: found.color,
              presetId: found.presetId,
              presetName: found.presetName
            }
            //console.log(`2: ${JSON.stringify(styleEdit.current)}`)
          } else {
            preset = false
          }

        }

        if (preset === false) {
          alert(`Error: unknown presetName`)
        } else {

          // check for warning => 0: can edit/delete, 1: can edit, 2: cannot edit/delete
          let today = new Date();
          let entry = new Date(arrayDraw.current[i].entrytime)
          let timeDiff = Math.abs(entry.getTime() - today.getTime())
          let diffDays = timeDiff / (1000 * 3600 * 24) // 7 days?

          if (props.user.drawing < 2) {

            setIsModal(prevState => ({...prevState, warning: 2, warningContent: 'Cannot Edit/Delete: You do not have required permission. Contact an Admin.'}))

          } else if ((props.user.drawing === 2 || props.user.drawing === 3) && props.user.username !== arrayDraw.current[i].entryby) {

            setIsModal(prevState => ({...prevState, warning: 2, warningContent: `Cannot Edit/Delete: ${arrayDraw.current[i].entryby} is the owner.`}))

          } else if ((props.user.drawing === 2 || props.user.drawing === 3) && props.user.username === arrayDraw.current[i].entryby && diffDays > 7) {

            setIsModal(prevState => ({...prevState, warning: 2, warningContent: `Cannot Edit/Delete: Time has elasped (7 days). Contact a Manager`}))

          } else if (props.user.drawing === 4 && diffDays > 7) {

            setIsModal(prevState => ({...prevState, warning: 1, warningContent: `Cannot Delete: You do not have required permission. Contact an Admin.`}))

          } else {

            setIsModal(prevState => ({...prevState, warning: 0, warningContent: ``}))

          }

          //console.log(`data: ${JSON.stringify(arrayDraw.current[i])}`)

          let bearing = arrayDraw.current[i].bearing
          let bearing90 = ''
          let bearingDipDirection = ''
          let bearingStrike = ''

          // bearingStrike === 'W' && (bearingDipDirection === 'N' || bearingDipDirection === 'E') ? 180 - bearing90 :
          // bearingStrike === 'E' && (bearingDipDirection === 'S' || bearingDipDirection === 'E') ? 180 + bearing90 :
          // bearingStrike === 'W' && (bearingDipDirection === 'S' || bearingDipDirection === 'W') ? 360 - bearing90 :
          // bearingStrike === 'E' && (bearingDipDirection === 'N' || bearingDipDirection === 'W') ? bearing90 : ''

          if (bearing >= 0 && bearing <= 90) {

            bearing90 = bearing
            bearingDipDirection = 'N'
            bearingStrike = 'E'

          } else if (bearing >= 91 && bearing <= 180) {

            bearing90 = bearing - 90
            bearingDipDirection = 'N'
            bearingStrike = 'W'

          } else if (bearing >= 181 && bearing <= 270) {

            bearing90 = bearing - 180
            bearingDipDirection = 'S'
            bearingStrike = 'E'

          } else if (bearing >= 271 && bearing <= 360) {

            bearing90 = bearing - 270
            bearingDipDirection = 'S'
            bearingStrike = 'W'

          }

          setIsValidatedEdit(prevState => ({...prevState,
            entryby: arrayDraw.current[i].entryby,
            entrytime: arrayDraw.current[i].entrytime,
            entrylat: arrayDraw.current[i].entrylat,
            entrylng: arrayDraw.current[i].entrylng,
            entrydevice: arrayDraw.current[i].entrydevice,
            modby: arrayDraw.current[i].modby,
            modtime: arrayDraw.current[i].modtime,
            modlat: arrayDraw.current[i].modlat,
            modlng: arrayDraw.current[i].modlng,
            moddevice: arrayDraw.current[i].moddevice,
            id: arrayDraw.current[i].drawId,
            tool: arrayDraw.current[i].tool,
            color: arrayDraw.current[i].color,
            width: arrayDraw.current[i].width,
            line: arrayDraw.current[i].line,
            fill: arrayDraw.current[i].fill,
            presetId: arrayDraw.current[i].presetId,
            description: arrayDraw.current[i].description,
            elev: arrayDraw.current[i].elev,
            type: arrayDraw.current[i].type,
            bearing: bearing,
            bearing90: bearing90,
            bearingDipDirection: bearingDipDirection,
            bearingStrike: bearingStrike,
            dip: arrayDraw.current[i].dip,
            n: arrayDraw.current[i].n, // used in addLocation
            e: arrayDraw.current[i].e
          }))
          openEdit()

        }

        //viewInfo(i, 'draw')

      }

    }

  }  

  const selectIssue = (e) => {

    let target = e.target
    //console.log(`target: ${target.nodeName}`)
    let tr = target.nodeName === 'I' ? target.parentNode.parentNode.parentNode : target.nodeName === 'Button' || target.nodeName === 'INPUT' ? target.parentNode.parentNode : target.parentNode
    let td = tr.getElementsByTagName('td')
    let i = td[0].textContent

    if (i === '' || i === null) {
      alert('Error: data index not found. Contact an admin.')
    } else {

      if (target.nodeName === 'TD') viewInfo(i, 'issue')

    }

  }

  const selectTest = (e) => {

    let target = e.target
    //console.log(`target: ${target.nodeName}`)
    let tr = target.nodeName === 'I' ? target.parentNode.parentNode.parentNode : target.nodeName === 'Button' || target.nodeName === 'INPUT' ? target.parentNode.parentNode : target.parentNode
    let td = tr.getElementsByTagName('td')
    let i = td[0].textContent

    if (i === '' || i === null) {
      alert('Error: data index not found. Contact an admin.')
    } else {

      if (target.nodeName === 'TD') viewInfo(i, 'test')

    }

  }

  const selectLab = (e) => {

    let target = e.target
    //console.log(`target: ${target.nodeName}`)
    let tr = target.nodeName === 'I' ? target.parentNode.parentNode.parentNode : target.nodeName === 'Button' || target.nodeName === 'INPUT' ? target.parentNode.parentNode : target.parentNode
    let td = tr.getElementsByTagName('td')
    let i = td[0].textContent

    if (i === '' || i === null) {
      alert('Error: data index not found. Contact an admin.')
    } else {

      if (target.nodeName === 'TD') viewInfo(i, 'lab')

    }

  }

  const selectPresat = (e) => {

    let target = e.target
    //console.log(`target: ${target.nodeName}`)
    let tr = target.nodeName === 'I' ? target.parentNode.parentNode.parentNode : target.nodeName === 'Button' || target.nodeName === 'INPUT' ? target.parentNode.parentNode : target.parentNode
    let td = tr.getElementsByTagName('td')
    let i = td[0].textContent

    if (i === '' || i === null) {
      alert('Error: data index not found. Contact an admin.')
    } else {

      if (target.nodeName === 'TD') viewInfo(i, 'presat')

    }

  }

  const search = (e) => {
    let value = e.target.value
    props.updateSearchLayer(value)   
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    props.updateSearchLayer('')
  }

  const filterDate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    setLayer(prevState => ({...prevState, [name]: state ? value : null}))
  }

  const clearFilterDate = () => {
    document.getElementById('startDate').value = ''
    document.getElementById('endDate').value = ''
    setLayer(prevState => ({...prevState, startDate: '', endDate: ''}))
  }

  const validate = (event) => {

    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    if (name === 'bearingDipDirection' || name === 'bearingStrike' || name === 'bearing90') {

      let bearingDipDirection = name === 'bearingDipDirection' ? value : isModal.edit ? isValidatedEdit.bearingDipDirection : isValidated.bearingDipDirection
      let bearingStrike = name === 'bearingStrike' ? value : isModal.edit ? isValidatedEdit.bearingStrike : isValidated.bearingStrike
      let bearing90 = name === 'bearing90' ? Number(value) : isModal.edit ? Number(isValidatedEdit.bearing90) : Number(isValidated.bearing90)

      if (bearingDipDirection !== '' && bearingDipDirection !== null && bearingStrike !== '' && bearingStrike !== null && bearing90 !== '' && bearing90 !== null) {

        let bearing =
        bearingStrike === 'W' && (bearingDipDirection === 'N' || bearingDipDirection === 'E') ? 180 - bearing90 :
        bearingStrike === 'E' && (bearingDipDirection === 'S' || bearingDipDirection === 'E') ? 180 + bearing90 :
        bearingStrike === 'W' && (bearingDipDirection === 'S' || bearingDipDirection === 'W') ? 360 - bearing90 :
        bearingStrike === 'E' && (bearingDipDirection === 'N' || bearingDipDirection === 'W') ? bearing90 : ''

        isModal.edit ?
        setIsValidatedEdit(prevState => ({...prevState, bearingDipDirection: bearingDipDirection, bearingStrike: bearingStrike, bearing90: bearing90, bearing: bearing})) :
        setIsValidated(prevState => ({...prevState, bearingDipDirection: bearingDipDirection, bearingStrike: bearingStrike, bearing90: bearing90, bearing: bearing}))
        isModal.edit ? console.log('yes') : console.log('no')

      } else {

        isModal.edit ?
        setIsValidatedEdit(prevState => ({...prevState, bearingDipDirection: bearingDipDirection, bearingStrike: bearingStrike, bearing90: bearing90, bearing: ''})) :
        setIsValidated(prevState => ({...prevState, bearingDipDirection: bearingDipDirection, bearingStrike: bearingStrike, bearing90: bearing90, bearing: ''}))

      }

    } else if (name === 'bearing') {

      if (value !== '' && value !== null) {

        let bearing = Number(value)

        if (isModal.edit) {

          bearing >= 0 && bearing <= 90 ? setIsValidatedEdit(prevState => ({...prevState, bearingDipDirection: 'N', bearingStrike: 'E', bearing90: bearing, [name]: state ? value : null})) :
          bearing >= 91 && bearing <= 180 ? setIsValidatedEdit(prevState => ({...prevState, bearingDipDirection: 'N', bearingStrike: 'W', bearing90: 180-bearing, [name]: state ? value : null})) :
          bearing >= 181 && bearing <= 270 ? setIsValidatedEdit(prevState => ({...prevState, bearingDipDirection: 'S', bearingStrike: 'E', bearing90: bearing-180, [name]: state ? value : null})) :
          bearing >= 271 && bearing <= 360 ? setIsValidatedEdit(prevState => ({...prevState, bearingDipDirection: 'S', bearingStrike: 'W', bearing90: 360-bearing, [name]: state ? value : null})) :
          setIsValidatedEdit(prevState => ({...prevState, bearingDipDirection: '', bearingStrike: '', bearing90: '', [name]: state ? value : null}))

        } else {

          bearing >= 0 && bearing <= 90 ? setIsValidated(prevState => ({...prevState, bearingDipDirection: 'N', bearingStrike: 'E', bearing90: bearing, [name]: state ? value : null})) :
          bearing >= 91 && bearing <= 180 ? setIsValidated(prevState => ({...prevState, bearingDipDirection: 'N', bearingStrike: 'W', bearing90: 180-bearing, [name]: state ? value : null})) :
          bearing >= 181 && bearing <= 270 ? setIsValidated(prevState => ({...prevState, bearingDipDirection: 'S', bearingStrike: 'E', bearing90: bearing-180, [name]: state ? value : null})) :
          bearing >= 271 && bearing <= 360 ? setIsValidated(prevState => ({...prevState, bearingDipDirection: 'S', bearingStrike: 'W', bearing90: 360-bearing, [name]: state ? value : null})) :
          setIsValidated(prevState => ({...prevState, bearingDipDirection: '', bearingStrike: '', bearing90: '', [name]: state ? value : null}))

        }

      } else {

        isModal.edit ?
        setIsValidatedEdit(prevState => ({...prevState, bearingDipDirection: '', bearingStrike: '', bearing90: '', [name]: state ? value : null})) :
        setIsValidated(prevState => ({...prevState, bearingDipDirection: '', bearingStrike: '', bearing90: '', [name]: state ? value : null}))

      }

    } else {

      isModal.edit ?
      setIsValidatedEdit(prevState => ({...prevState, [name]: state ? value : null})) :
      setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))

    }

  }

  const validateOffsetPt = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    offsetPt.current = {...offsetPt.current, [name]: state ? value : 0}
  }

  const updateSliderScale = (event) => {
    //let name = event.target.getAttribute('name')
    //let state = event.target.reportValidity()
    //let type = event.target.type
    let value = event.target.value

    sliderScale.current = value

    redraw()

    //console.log(`sliderScale: ${sliderScale.current}`)
  }

  const missingLocation = () => openAlert('Missing Location')

  const highlightDrawing = (e) => {

    let tr = e.currentTarget        
    let td = tr.getElementsByTagName('td')
    let i = tr.getAttribute('data-i')

    // let target = event.target
    // let tr = target.nodeName === 'I' ? target.parentNode.parentNode.parentNode : target.nodeName === 'Button' || target.nodeName === 'INPUT' ? target.parentNode.parentNode : target.parentNode
    // let td = tr.getElementsByTagName('td')
    // let i = td[0].textContent
    let object = 
      td[3].textContent.toLowerCase() === 'issue' || 
      td[3].textContent.toLowerCase() === 'test' ||
      td[3].textContent.toLowerCase() === 'lab' || 
      td[3].textContent.toLowerCase() === 'presat' ? td[3].textContent.toLowerCase() : 'draw'

    // console.log(`
    // i: ${i}
    // `)

    redraw('select', i, object) // highlight selected

  }

  const directions = (event) => {
    let i = event.target.getAttribute('data-i')
    let id = event.target.getAttribute('data-id')
    let n,e

    if (id === 'draw') {
      n = arrayDraw.current[i].n[0]
      e = arrayDraw.current[i].e[0]
    } else if (id === 'test') {
      n = arrayTest.current[i].n
      e = arrayTest.current[i].e
    } else if (id === 'lab') {
      n = arrayLab.current[i].n
      e = arrayLab.current[i].e
    } else if (id === 'issue') {
      n = arrayIssue.current[i].n
      e = arrayIssue.current[i].e
    } else if (id === 'presat') {
      n = arrayPresat.current[i].n
      e = arrayPresat.current[i].e
    }

    console.log(`
    id: ${id}
    n: ${n}
    e: ${e}
    `)

    let coords = coordsNE(Number(n),Number(e), props.planInfo.m0, props.planInfo.m3, props.planInfo.m4, props.planInfo.m5, props.pdf.scale, props.planInfo.angle);

    redraw('select', i, id) // highlight selected

    drawMove(coords.x,coords.y)
  }

  const toggleVisible = (e) => {
    let i = e.target.getAttribute('data-i')
    let id = e.target.getAttribute('data-id')

    if (id === 'draw') {
      arrayDraw.current[i].visible = arrayDraw.current[i].visible ? false : true
    } else {
      arrayTest.current[i].visible = arrayTest.current[i].visible ? false : true
    }

    redraw()
  }

  const openHelp = () => setIsModal(prevState => ({...prevState, help: true}))

  const closeHelp = () => setIsModal(prevState => ({...prevState, help: false}))

  const openAlert = (content) => setIsModal(prevState => ({...prevState, alert: true, alertContent: content}))

  const closeAlert = () => setIsModal(prevState => ({...prevState, alert: false}))

  const editIssue = (i) => setIsInput(prevState => ({...prevState, issue: true, edit: true, i: i}))

  const editTest = (i) => setIsInput(prevState => ({...prevState, test: true, edit: true, i: i}))

  const editLab = (i) => setIsInput(prevState => ({...prevState, lab: true, edit: true, i: i}))

  const editPresat = (i) => setIsInput(prevState => ({...prevState, presat: true, edit: true, i: i}))

  const closeIssue = () => {
    fetchData()
    setIsInput(prevState => ({...prevState, issue: false, add: false, edit: false, i: null}))
  }

  const closeAction = () => {
    //fetchData()
    //setIsInput(prevState => ({...prevState, issue: false, add: false, edit: false, i: null}))
  }

  const closeTest = () => {
    fetchData()
    setIsInput(prevState => ({...prevState, test: false, add: false, edit: false, i: null}))
  }  

  const closeLab = () => {
    fetchData()
    setIsInput(prevState => ({...prevState, lab: false, add: false, edit: false, i: null}))
  }

  const closePresat = () => {
    fetchData()
    setIsInput(prevState => ({...prevState, presat: false, add: false, edit: false, i: null}))
  } 

  const openEdit = () => setIsModal(prevState => ({...prevState, edit: true}))

  const closeEdit = () => {
    if (isEditedInfo.current) {

      if (window.confirm('You have unsaved edits. Proceed?')) {
        isEditedInfo.current = false
        clearIsValidatedEdit()
        setIsModal(prevState => ({...prevState, edit: false}))
      }

    } else {
      isEditedInfo.current = false
      clearIsValidatedEdit()
      setIsModal(prevState => ({...prevState, edit: false}))
    }

  }

  const closeStyle = () => setTool(prevState => ({...prevState, style: false}))

  const closeLayer = () => setTool(prevState => ({...prevState, layer: false}))

  const closeModal = () => {
    if (
      props.mode === 'plansIssue' || 
      props.mode === 'plansTest' || 
      props.mode === 'plansLab' ||
      props.mode === 'plansPresat' || 
      props.mode === 'plansFilterPolygon'
    ) clearIsValidated()

    setTool(prevState => ({...prevState, add: false, style: false, locate: false}))
  }

  const toggleDirection = () => setTool(prevState => ({...prevState, direction: tool.direction ? false : true}))

  const toggleMore = () => setTool(prevState => ({...prevState, more: tool.more ? false : true}))

  const toggleHistory = () => setTool(prevState => ({...prevState, history: tool.history ? false : true}))

  const toggleTestElev = () => setLayer(prevState => ({...prevState, testElev: layer.testElev ? false : true}))

  const toggleGrading = () => setLayer(prevState => ({...prevState, grading: layer.grading ? false : true}))

  const togglePost = () => setLayer(prevState => ({...prevState, post: layer.post ? false : true}))

  const toggleMovePt = () => setTool(prevState => ({...prevState, movePt: tool.movePt ? false : true, insertPt: false, deletePt: false, offsetPt: false}))

  const toggleInsertPt = () => setTool(prevState => ({...prevState, movePt: false, insertPt: tool.insertPt ? false : true, deletePt: false, offsetPt: false}))

  const toggleDeletePt = () => setTool(prevState => ({...prevState, movePt: false, insertPt: false, deletePt: tool.deletePt ? false : true, offsetPt: false}))

  const toggleOffsetPt = () => setTool(prevState => ({...prevState, movePt: false, insertPt: false, deletePt: false, offsetPt: tool.offsetPt ? false : true}))

  const toggleCustom = () => setTool(prevState => ({...prevState, custom: tool.custom ? false : true}))

  const selectPresets = () => {

    if (props.user.offline && props.user.offlineJob === props.filter.jobNumber) {

      // do nothing.. already downloaded

      // selectData('Tests').then(res => {
      //   setFetchedData(res)
      //   setIsModal(prevState => ({...prevState, loading: false}))
      // })

    } else {

      fetch('/api/selectPresetTypes', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        }
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + result)
          setPresetTypes(result)

        },
        (error) => catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'selectPresetTypes', JSON.stringify(error), props.user.username, props.user.device)
      )

      fetch('/api/selectPresets', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        }
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + result)
          setPresets(result)
        },
        (error) => catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'selectPresets', JSON.stringify(error), props.user.username, props.user.device)
      )

    }

  }

  const offset = (mx,my) => {

    let offset = 50;

    if ( tool.offset ) {

        //console.log('offset yes: ' + mx-offset + ', ' + my-offset);

        return {
            x: mx - offset,
            y: my - offset
        };

    }

    //console.log('offset no: ' + mx + ', ' + my);

    return {
        x: mx,
        y: my
    };

  }

  const snap = (mx,my,object) => { // object for plansdraw_tools_join

    let ctx = props.canvasDraw.current.getContext('2d')
    let i,j,k,x,y,w,x1,y1,x2,y2,s1,s2,s3,s,a,h,d,sx,sy; // sx/sy added to prevent x/y crossover of vals?
    let coords,coords1,coords2;
    let shape;
    let xy;

    //console.log('before offset: mx: ' + mx + ' my: ' + my + ' position.current.startX: ' + position.current.startX + ' position.current.startY: ' + position.current.startY);

    if ( object !== 'join') {

        xy = offset(mx,my);

        mx = xy.x;
        my = xy.y;

    }

    //console.log('snap start: ' + mx + ', ' + my);

    // if snap or edit turned on

    if ( tool.snap ) {

        //console.log(`snap-->true`)

        if ( arraySnap.current.length > 0 ) {

            //console.log(`snap-->true-->length > 0`)

            for (i=0; i < arraySnap.current.length; i++) {

                shape = new Path2D();

                x = arraySnap.current[i].x;
                y = arraySnap.current[i].y;
                w = arraySnap.current[i].w;

                ctx.lineWidth = w;
                shape.rect(x - (w+10)/2, y - (w+10)/2, w+10, w+10);

                if (ctx.isPointInPath(shape, mx, my)) {

                  //console.log(`snap-->true-->length > 0-->isPointInPath`)

                  return {
                      x: x,
                      y: y
                  }

                }

            }

            // reset width just in case

            ctx.lineWidth = style.width; //im not sure this actually works..draw() returns a 12.5 width

        }

        for (i=0; i < arrayDraw.current.length; i++) {

            shape = new Path2D();

            ctx.lineWidth = Number(arrayDraw.current[i].width) + 10;

            for (j=0; j < arrayDraw.current[i].n.length; j++) {

                coords = coordsNE(Number(arrayDraw.current[i].n[j]),Number(arrayDraw.current[i].e[j]), props.planInfo.m0, props.planInfo.m3, props.planInfo.m4, props.planInfo.m5, props.pdf.scale, props.planInfo.angle);
                sx = coords.x;
                sy = coords.y;

                j === 0 ? shape.moveTo(sx, sy) : shape.lineTo(sx, sy);

            }

            if (ctx.isPointInStroke(shape, mx, my)) {

                for (j=0; j < arrayDraw.current[i].n.length; j++) {

                    shape = new Path2D();

                    ctx.lineWidth = Number(arrayDraw.current[i].width) + 10;

                    coords1 = coordsNE(Number(arrayDraw.current[i].n[j]),Number(arrayDraw.current[i].e[j]), props.planInfo.m0, props.planInfo.m3, props.planInfo.m4, props.planInfo.m5, props.pdf.scale, props.planInfo.angle);
                    x1 = coords1.x;
                    y1 = coords1.y;

                    shape.moveTo(x1, y1);

                    j === arrayDraw.current[i].n.length - 1 ? k = 0 : k = j + 1;

                    coords2 = coordsNE(Number(arrayDraw.current[i].n[k]),Number(arrayDraw.current[i].e[k]), props.planInfo.m0, props.planInfo.m3, props.planInfo.m4, props.planInfo.m5, props.pdf.scale, props.planInfo.angle);
                    x2 = coords2.x;
                    y2 = coords2.y;

                    shape.lineTo(x2, y2);

                    if (ctx.isPointInStroke(shape, mx, my)) {

                        // distance formula
                        s1 = Math.sqrt(Math.pow((x2-x1),2) + Math.pow((y2-y1),2));
                        s2 = Math.sqrt(Math.pow((x1-mx),2) + Math.pow((y1-my),2));
                        s3 = Math.sqrt(Math.pow((mx-x2),2) + Math.pow((my-y2),2));

                        // Heron's formula
                        s = (s1 + s2 + s3)/2;
                        a = Math.sqrt(s * (s - s1) * (s - s2) * (s - s3));
                        h = 2 * a/s1; // height

                        // pythagorean theorem --> a = sqrt(c^2 - b^2)
                        d = Math.sqrt(Math.pow(s2,2) - Math.pow(h,2)); // distance for vector calcs

                        //determine dx/dy per unit distance and add
                        x = x1 + d/s1 * (x2 - x1);
                        y = y1 + d/s1 * (y2 - y1);

                        return {
                            x: x,
                            y: y
                        };

                    }

                }

                break;

            }

        }

    }

    return {
        x: mx,
        y: my
    };

  }

  const snapBuildArray = (mx, my) => {
    //console.log('snapArray Start')

    // added mx, my to handle individual push, ie from temporary

    let i,j,w,coords,x,y

    if (mx !== undefined) {

      arraySnap.current.push({

        x: mx,
        y: my,
        w: 2.5

      });

    } else {

      arraySnap.current = [] // why cant this be built once in fetchData??

      //let drawings = [arrayDraw.current, arrayTemporary.current]

      //for (let a=0; a < drawings.length; a++) {

        let data = arrayDraw.current

        for (i=0; i < data.length; i++) {

            // build shapes

            for (j=0; j < data[i].n.length; j++) {

                coords = coordsNE(Number(data[i].n[j]),Number(data[i].e[j]), props.planInfo.m0, props.planInfo.m3, props.planInfo.m4, props.planInfo.m5, props.pdf.scale, props.planInfo.angle);
                x = coords.x;
                y = coords.y;

                w = Number(
                  data[i].presetId > 0 &&
                  (data[i].width === '' || data[i].width === null) ?
                  data[i].presetWidth :
                  data[i].width === '' || data[i].width === null ?
                  data[i].width : 0
                )

                arraySnap.current.push({

                    x: x,
                    y: y,
                    w: w

                });

            }

            //console.log(`arraySnap: ${JSON.stringify(arraySnap.current)}`)

        }      

        for (i=0; i < arrayTemporary.current.length; i++) {

          // build shapes

          arraySnap.current.push({

            x: x,
            y: y,
            w: 2.5

          });

          //console.log(`arraySnap: ${JSON.stringify(arraySnap.current)}`)

        }

      //}

    }

  }

  const draw = (mx,my,object) => { // object for plansdraw_tools_join / end()

    //console.log(`tool --> ${style.tool}`)

    let ctx = props.canvasDraw.current.getContext('2d')
    let xy = snap(mx,my,object); // linewidth modified here

    mx = xy.x
    my = xy.y

    displayPosition(mx,my)    

    ctx.globalAlpha = 1;
    ctx.lineJoin = "round";
    ctx.lineCap = "round"
    ctx.strokeStyle = ( !tool.ref && tool.offset && !drawing.current.offset ) ? 'red' : style.color; // so it shows easier against black
    ctx.lineWidth = ( !tool.ref && tool.offset && !drawing.current.offset ) ? '5' : style.width * props.pdf.scale * props.pdf.zoomScale;
    //console.log(`style line --> ${style.line}`)

    // if line style is dashed, difficult to see end of line
    //ctx.setLineDash(style.line);
    ctx.setLineDash([]);

    function drawStart() {

        console.log('drawStart')
        ctx.beginPath(); // begin
        ctx.moveTo(mx, my + 0.1); // from // the +0.1 is necessary for apple devices, wont draw if moveTo and lineTo are the same..

    }

    if (
      (!drawing.current.start) ||
      (tool.offset && !drawing.current.offset) ||
      (
        style.tool === 'photo' ||
        style.tool === 'planscomment' ||
        style.tool === 'plansbe' ||
        style.tool === 'planssurveypt' ||
        style.tool === 'planstext' ||
        style.tool === 'attitude' ||
        style.tool === 'point' ||
        props.mode === 'plansIssue' ||
        props.mode === 'plansTest' ||
        props.mode === 'plansLab' ||
        props.mode === 'plansPresat'
      )
    ) {

        if (!tool.ref) {

            drawStart()

        }

        drawing.current.start = true
        drawing.current.offset = object === 'end' || object === 'locate' ? true : false

        position.current.startX = mx
        position.current.startY= my

    }

    if (style.tool === "planspencil" || style.tool === "plansmarker") {  //|| props.mode === 'plansTest' // NEEDED? found 3/14/21

        ctx.lineTo(mx, my);
        ctx.stroke();

    }

    else if (style.tool === "plansarrow" || style.tool === "plansslope") {

        (style.tool === "plansarrow") ? plansArrow(mx,my,position.current.startX,position.current.startY) : plansSlope(mx,my,position.current.startX,position.current.startY);

    }

    else {

        drawStart()
        ctx.lineTo(mx, my);
        ctx.stroke();

    }

    return {
        x: mx,
        y: my
    };

  }

  const drawRef = (mx,my,object) => { // object is not used, but left in place for future

    let ctx = props.canvasDraw.current.getContext('2d')
    let xy = snap(mx,my,object); // linewidth modified here

    mx = xy.x
    my = xy.y

    displayPosition(mx,my)

    ctx.globalAlpha = 1
    ctx.lineJoin = "round"
    ctx.lineCap = "round"
    ctx.strokeStyle = 'red'
    ctx.lineWidth = '5'
    ctx.setLineDash([]);

    // check start
    if ((!tool.offset && tool.ref && !drawing.current.refStart) || (tool.offset && tool.ref && !drawing.current.refOffsetStart)) {

      console.log('begin LINE')

      ctx.beginPath(); // begin
      ctx.moveTo(mx, my + 0.1); // from // the +0.1 is necessary for apple devices, wont draw if moveTo and lineTo are the same..

      drawing.current.offset = object === 'end' || object === 'locate' ? true : false

      position.current.startX = mx
      position.current.startY = my      

    } else {

      // became necessary because of drawTemporary()
      // const move() calls refresh() and drawTemporary sets beginPath()
      // need to resolve this in move() as well because I cannot switch from Temporary and draw another preset

      ctx.beginPath(); // begin
      ctx.moveTo(position.current.startX, position.current.startY)

    }

    ctx.lineTo(mx, my);
    ctx.stroke();

    //console.log('continue LINE')

    return {
        x: mx,
        y: my
    }

  }

  const redraw = (object, selected, selectedObject) => {

    // selected is used for tests and lab when redrawing, otherwise it will plot twice and confuse people
    // object used for edit, snap, undo (stroke()?), ?style?, etc
    // object === 'refresh' for fetchData() and editDrawing()
    // object === 'select' for highlighting object

    let selectedColor = 'gold'

    // need to organize objects into summary...

    clear()

    // testNo: used below for tests
    // str and arr are used for line
    // ?? NOT USED NOW? // preset: handles layer_cb_
    // sx and sy: used for plansarrow/plansslope

    let i,j,x,y,n,e,testNo,str,arr,coords,layer_cb_id,sx,sy;

    let presetName, drawTool, color, width, line

    let ctx = props.canvasDraw.current.getContext('2d')

    let data = object === 'edit' ? arrayDrawTemp.current : arrayDraw.current

    // presetName, presetTool, presetColor, presetWidth, presetLine

    let presetStatus, testStatus, labStatus, presatStatus, issueStatus   

    for (i=0; i < data.length; i++) {  
      
      //console.log(`presetID: ${data[i].presetId}`)
      //console.log(`presat: ${JSON.stringify(props.distinctPresets.find(o => o.id === data[i].presetId))}`)

      presetStatus = props.distinctPresets.find(o => o.id === data[i].presetId).status
      presetStatus = presetStatus === undefined ? true : presetStatus      

      if (
        (data.[i].visible && data.[i].gradeid === 'RG' && layer.grading) || 
        (data.[i].visible && data.[i].gradeid === 'PG' && layer.post) && 
        (presetStatus)
      ) {

        n = data[i].n
        e = data[i].e

        //w = data[i].width * props.pdf.scale;

        //if (Number(selected) === i) console.log(`object: ${object} selected: ${selected} i: ${i}`)

        if (data[i].presetId === 0) {
          presetName = 'Custom'
          drawTool = data[i].tool
          color = object === 'select' && selectedObject === 'draw' && Number(selected) === i ? selectedColor : data[i].color
          width = data[i].width //* props.pdf.scale
          line = data[i].line
        } else {
          presetName = data[i].presetName
          drawTool = data[i].presetTool
          color = object === 'select' && selectedObject === 'draw' && Number(selected) === i ? selectedColor : data[i].presetColor
          width = data[i].presetWidth //* props.pdf.scale
          line = data[i].presetLine
        }

        //if (Number(selected) === i) console.log(`color: ${color}`)

        if (drawTool === "planspencil" || drawTool === "plansmarker" || drawTool === "plansarrow" || drawTool === "plansslope") {

            ctx.lineCap = "round";
            ctx.lineJoin = "round";
            ctx.lineWidth = width;

            str = line
            arr = str.toString().split(",");

            ctx.beginPath();
            ctx.setLineDash(arr);

            for ( j=0; j < data[i].n.length; j++ ) {

              //console.log(`redraw scale: ${props.pdf.scale}`)

              coords = coordsNE(Number(n[j]), Number(e[j]), props.planInfo.m0, props.planInfo.m3, props.planInfo.m4, props.planInfo.m5, props.pdf.scale, props.planInfo.angle );
              x = coords.x;
              y = coords.y;

              // now draw

              if (drawTool === "planspencil" || drawTool === "plansmarker") {

                ctx.strokeStyle = color;

                ( j === 0 ) ? ctx.moveTo(x, y) : ctx.lineTo(x, y);

              }

              else if (drawTool === "plansarrow" || drawTool === "plansslope") {

                if ( j === data[i].n.length - 1 ) {

                  coords = coordsNE( Number( n[j-1] ) , Number( e[j-1] ), props.planInfo.m0, props.planInfo.m3, props.planInfo.m4, props.planInfo.m5, props.pdf.scale, props.planInfo.angle );
                  sx = coords.x;
                  sy = coords.y;

                  ctx.strokeStyle = color;

                  (drawTool === "plansarrow" ) ? plansArrow(x,y,sx,sy) : plansSlope(x,y,sx,sy);

                }

                else {

                  ( j === 0 ) ? ctx.moveTo(x, y) : ctx.lineTo(x, y);

                }

              }

              // if plansedit or layer.snap selected

              if (object === 'edit' || object === 'snap' || tool.edit || tool.snap) {

                  //ctx.fillStyle = 'violet';
                  //ctx.strokeStyle = 'violet'

                  ctx.strokeStyle = 'violet'
                  ctx.lineWidth = 2;
                  ctx.setLineDash([]);

                  ctx.strokeRect(x - (Number(width)+10)/2 * props.pdf.scale, y - (Number(width)+10)/2 * props.pdf.scale, Number(width)+10, Number(width)+10);
                  //ctx.arc(x * props.pdf.scale, y * props.pdf.scale, Number(width)+10, 0, 2 * Math.PI)

                  ctx.strokeStyle = color;
                  ctx.lineWidth = width;
                  ctx.setLineDash(arr);

              }

            }

            if (data[i].fill === 'solid') {

                if (drawTool !== "plansmarker") {ctx.stroke();}

                ctx.globalAlpha = (drawTool === "plansmarker") ? 1 : 0.3;
                ctx.fillStyle = color;
                ctx.fill();
                ctx.globalAlpha = 1; // reset for future drawn objects

            }

            else {

                ctx.stroke();

            }

        }

        else {

          coords = coordsNE(Number(n),Number(e), props.planInfo.m0, props.planInfo.m3, props.planInfo.m4, props.planInfo.m5, props.pdf.scale, props.planInfo.angle);

          x = coords.x;
          y = coords.y;

          if (drawTool === "planscomment") {

              drawComment(x,y,color);

          }

          else if (drawTool === "plansbe") {

            //rect(x,y,data[i].elev,color);
            drawBottomElevation(x,y,data[i].elev,color,width)

          }

          else if (drawTool === "planssurveypt") {

              drawSurveyPt(x,y,data[i].elev,color);

          }

          else if (drawTool === "planstext") {

              drawText(x,y,data[i].description,color);

          }

          else if (drawTool === "attitude") {

              drawAttitude(x,y,data[i].dip,data[i].bearing,data[i].elev,data[i].type,color,width);

          } else if (drawTool === 'point') {

            if (Number(selected) === i) console.log(`color: ${color}`)

              drawPoint(x,y,data[i].elev,data[i].type,color,width)

          }

        }

      } else {
        //console.log(`visible: ${JSON.stringify(data[i])}`)
      }

    }

    // if (id === -1) { // issue
    //   arrayIssue.current = arrayIssue.current.map(data => ({...data, visible: status ? false : true}))
    //   arrayIssueTemp.current = arrayIssue.current
    // } else if (id === -2) { // test
    //   arrayTest.current = arrayTest.current.map(data => ({...data, visible: status ? false : true}))
    //   arrayTestTemp.current = arrayTest.current
    // } else if (id === -3) { // lab
    //   arrayLab.current = arrayLab.current.map(data => ({...data, visible: status ? false : true}))
    //   arrayLabTemp.current = arrayLab.current
    // } else if (id === -4) { // presat
    //   arrayPresat.current = arrayPresat.current.map(data => ({...data, visible: status ? false : true}))
    //   arrayPresatTemp.current = arrayPresat.current
    // } else if (id === -5) { // temp
    //   arrayTemporary.current = arrayTemporary.current.map(data => ({...data, visible: status ? false : true}))        
    // } else {
    //   arrayDraw.current = arrayDraw.current.map(data => (id === data.presetId ? {...data, visible: status ? false : true} : data))
    //   arrayDrawTemp.current = arrayDraw.current
    // }

    data = object === 'edit' ? arrayIssueTemp.current : arrayIssue.current    

    for (i=0; i < data.length; i++) {

      color = object === 'select' && selectedObject === 'issue' && Number(selected) === i ? selectedColor : data[i].resolved == 1 ? 'dodgerblue' : data[i].resolved == 2 ? 'orange' : 'tomato'

      issueStatus = props.distinctPresets.find(o => o.id === -1).status
      issueStatus = issueStatus === undefined ? true : issueStatus
      
      if (data.[i].visible && issueStatus) {        

        // a little confusing, but when object === 'lab' (drawing lab)
        // to avoid duplicate sampleno when editing, Number(selected) represents sampleno and not i
        // selected is usually i

        if (object !== 'issue' || (object === 'issue' && Number(selected) !== Number(data[i].issueNo))) {

          let n = data[i].resolved == 1 ? `${data[i].issueNo}✔` : data[i].resolved == 2 ? `${data[i].issueNo}☹` : data[i].issueNo

          coords = coordsNE(Number(data[i].n),Number(data[i].e), props.planInfo.m0, props.planInfo.m3, props.planInfo.m4, props.planInfo.m5, props.pdf.scale, props.planInfo.angle);
          x = coords.x;
          y = coords.y;
          drawIssue(x, y, n, color);

        }

      }

    }

    data = object === 'edit' ? arrayTestTemp.current : arrayTest.current

    for (i=0; i < data.length; i++) {      

      //console.log(`failing: ${JSON.stringify(data)}`)

      color = object === 'select' && selectedObject === 'test' && Number(selected) === i ? selectedColor : data[i].failingTest ? 'tomato' : 'black'

      testStatus = props.distinctPresets.find(o => o.id === -2).status
      testStatus = testStatus === undefined ? true : testStatus

      if (
        (data.[i].visible && data.[i].gradeid === 'RG' && layer.grading) || 
        (data.[i].visible && data.[i].gradeid === 'PG' && layer.post) && 
        (testStatus)
      ) {

        // a little confusing, but when object === 'test' (drawing a test)
        // to avoid duplicate testno when editing, Number(selected) represents testno and not i
        // selected is usually i

        if (object !== 'test' || (object === 'test' && Number(selected) !== Number(data[i].testno))) {

          testNo = data[i].pre + ' ' + data[i].testno + ' ' + data[i].suf;

          coords = coordsNE(Number(data[i].n),Number(data[i].e), props.planInfo.m0, props.planInfo.m3, props.planInfo.m4, props.planInfo.m5, props.pdf.scale, props.planInfo.angle);
          x = coords.x;
          y = coords.y;
          let elevd = layer.testElev ? data[i].elevd : ''
          //console.log(`x: ${x} -- y: ${y}`)
          drawTest(x, y, testNo.trim(), elevd, color);

        }

      }

    }

    data = object === 'edit' ? arrayLabTemp.current : arrayLab.current    

    for (i=0; i < data.length; i++) {

      color = object === 'select' && selectedObject === 'lab' && Number(selected) === i ? selectedColor : 'brown'

      labStatus = props.distinctPresets.find(o => o.id === -3).status
      labStatus = labStatus === undefined ? true : labStatus

      // if (
      //   (data.[i].visible && data.[i].gradeid === 'RG' && layer.grading) || 
      //   (data.[i].visible && data.[i].gradeid === 'PG' && layer.post) && 
      //   (labStatus)
      // ) {

      if (data.[i].visible && labStatus) {

        // a little confusing, but when object === 'lab' (drawing lab)
        // to avoid duplicate sampleno when editing, Number(selected) represents sampleno and not i
        // selected is usually i

        if (object !== 'lab' || (object === 'lab' && Number(selected) !== Number(data[i].sampleno))) {

          coords = coordsNE(Number(data[i].n),Number(data[i].e), props.planInfo.m0, props.planInfo.m3, props.planInfo.m4, props.planInfo.m5, props.pdf.scale, props.planInfo.angle);
          x = coords.x;
          y = coords.y;
          drawLab(x,y, data[i].sampleno,color);

        }

      }

    }

    data = object === 'edit' ? arrayPresatTemp.current : arrayPresat.current    

    for (i=0; i < data.length; i++) {

      color = object === 'select' && selectedObject === 'presat' && Number(selected) === i ? selectedColor : 'blue'

      presatStatus = props.distinctPresets.find(o => o.id === -4).status
      presatStatus = presatStatus === undefined ? true : presatStatus

      if (         
        (data.[i].visible && layer.post) && 
        (presatStatus)
      ) {


        // a little confusing, but when object === 'lab' (drawing lab)
        // to avoid duplicate sampleno when editing, Number(selected) represents sampleno and not i
        // selected is usually i

        //if (object !== 'presat' || (object === 'presat' && Number(selected) !== Number(data[i].sampleno))) {

          coords = coordsNE(Number(data[i].n),Number(data[i].e), props.planInfo.m0, props.planInfo.m3, props.planInfo.m4, props.planInfo.m5, props.pdf.scale, props.planInfo.angle);
          x = coords.x;
          y = coords.y;
          drawPresat(x,y, 'Presat',color);

        //}

      }

    }    

    // need to draw Temporary before Temp. Otherwise beginPath() interfers with drawing lines
    redrawTemporary(object)
    redrawTemp(object)
    //console.log('refresh')    

  }

  const redrawTemp = (object) => {

    let x,y,i;
    let color = (style.tool === "planspencil" || style.tool === "plansmarker") ? style.color : '#000000FF';
    let ctx = props.canvasDraw.current.getContext('2d')

    if (arrayTempX.current.length !== 0 && ((tool.ref && !drawing.current.refOffsetEnd) || style.tool === "planspencil" || style.tool === "plansmarker" || style.tool === "plansarrow" || style.tool === "plansslope")) {

        ctx.lineCap = "round";
        ctx.lineJoin = "round";
        ctx.strokeStyle = color;
        ctx.lineWidth = style.width * props.pdf.scale;

        ctx.beginPath();
        ctx.setLineDash(style.line);

        for (i=0; i < arrayTempX.current.length; i++) {

            x = arrayTempX.current[i] * props.pdf.scale;
            y = arrayTempY.current[i] * props.pdf.scale;

            ( x === 0 ) ? ctx.moveTo(x, y) : ctx.lineTo(x, y);

            //console.log(`redraw_temp drawing`)

        }

        if (object === 'undo' || object === 'style' || object === 'snap' || object === 'refresh') ctx.stroke()
        //ctx.stroke(); // causes the line to be drawn twice --> stroked in draw()

        if (drawing.current.fill === 'solid') {

          if (style.tool !== "plansmarker") ctx.stroke()

          ctx.globalAlpha = (style.tool === "plansmarker") ? 1 : 0.3;
          ctx.fillStyle = color;
          ctx.fill();
          ctx.globalAlpha = 1; // reset for future drawn objects

        }

    }

  }

  const redrawTemporary = (object) => {
    
    //console.log(`redrawTemporary: ${JSON.stringify(arrayTemporary.current)}`)

    let x,y
    let color = 'gold' //style.color //(style.tool === "planspencil" || style.tool === "plansmarker") ? style.color : '#000000FF';
    //let ctx = props.canvasDraw.current.getContext('2d')

    if (arrayTemporary.current.length !== 0) { // && ((tool.ref && !drawing.current.refOffsetEnd) || style.tool === "temporary")) {      

      for (let i=0; i < arrayTemporary.current.length; i++) {

        //console.log('drawing temporary')

        x = arrayTemporary.current[i].x * props.pdf.scale
        y = arrayTemporary.current[i].y * props.pdf.scale

        drawTemporary(x, y)

      }        

    }

  }

  const plansArrow = (x2,y2,x1,y1) => {

      let ctx = props.canvasDraw.current.getContext('2d')
      let dx = Math.round(x2 - x1,1);
      let dy = Math.round(y2 - y1,1);

      let theta = Math.atan2(dy, dx); // range (-PI, PI]
      theta *= -180 / Math.PI; //* -1; // rads to degs, range (-180, 180] || *= || x *= y  || x = x * y // the -1 reverses the angle
      theta = theta - 90;// - angle; // now set 0 on y axis and account for plan rotation
      //if (theta < 0) theta = 360 + theta; // range [0, 360)

      let l = 15 * props.pdf.scale;
      let rad1 = ( -225 + theta ) * Math.PI / 180;
      let rad2 = ( 225 + theta ) * Math.PI / 180;

      // distance formula
      let d = Math.sqrt(Math.pow((x2-x1),2) + Math.pow((y2-y1),2));

      //determine dx/dy per unit distance and add
      dy = l * Math.cos(rad1);
      dx = l * Math.sin(rad1);

      ctx.lineTo(x2, y2);

      ctx.lineTo(x2-dx, y2-dy);
      ctx.lineTo(x2, y2);

      dy = l * Math.cos(rad2);
      dx = l * Math.sin(rad2);

      ctx.lineTo(x2-dx, y2-dy);
      ctx.stroke();

  }
  //
  // function plans_colors(color,id) {
  //
  //     if (color === 'other') {
  //
  //         document.getElementById('inputplansdraw_style_color').css('display','block');
  //
  //     }
  //
  //     else {
  //
  //         // clear preset
  //
  //         $(".plansdraw_style_preset-border").css("border", "none");
  //
  //         // set colors
  //
  //         color = (color.length === 9) ? color.substring(0,7) : color; // if 8-digit hex, remove last 2-digits
  //         color += (id === 'plansmarker') ? '4D' : 'FF'; // 4D = 30%, FF = 100%
  //
  //         style.color = color;
  //
  //         // update planscolors if marker
  //
  //         plans_colors_update(color, id);
  //
  //         // redraw in case a new style was selected
  //
  //         redraw('style');
  //
  //         return color;
  //
  //     }
  //
  // }
  //
  // function plans_colors_update(color, id) {
  //
  //     let hex, rgb;
  //     let a = (id === 'plansmarker') ? '4D' : 'FF'; // 4D = 30%, FF = 100%
  //
  //     $(".planscolors").each(function() {
  //
  //         hex = $(this).attr("name");
  //
  //         if (hex !== 'other') {
  //
  //             rgb = hexToRgbA(hex,a);
  //
  //             $(this).css("color", rgb);
  //
  //             // console.log('colors: ' + hex);
  //             // console.log('colors: ' + $(this).css("color"));
  //
  //         }
  //
  //     });
  //
  //     hex = (color.length === 9) ? color.substring(0,7) : color; // if 8-digit hex, remove last 2-digits
  //     rgb = hexToRgbA(hex,a);
  //     $(".planswidth").css("color", rgb);
  //
  // }
  //
  // $(".planscolors").click(function() {
  //
  //     plans_colors($(this).attr("name"),plansDrawMode);
  //     // clear previous and select new one
  //
  //     $(".planscolors").css("border", "none");
  //     $(this).css("border", "1px solid red");
  //
  // });

  const plansIssue = (mx,my) => {

    let issueNo = props.data === undefined ? Math.max(...arrayIssue.current.map(o => o.issueNo)) + 1 : props.data.issueNo    

    tempX.current = mx
    tempY.current = my    

    redraw('issue', issueNo)    

    drawIssue(mx,my,issueNo);

}

  const plansTest = (mx,my) => {

    let pre = ''
    let testNo = ''
    let suf = ''
    let elevD = ''
    let n = ''

    if (props.data === undefined) {

      testNo = Math.max(...arrayTest.current.map(o => o.gradeid === props.filter.gradeId ? o.testno : 0)) + 1      

    } else {

      pre = props.data.pre === null ? '' : props.data.pre
      testNo = props.data.testNo === null ? '' : props.data.testNo
      suf = props.data.suf === null ? '' : props.data.suf
      elevD = props.data.elevD === null ? '' : props.data.elevD

    }    

    n = `${pre} ${testNo} ${suf}`    

    tempX.current = mx
    tempY.current = my    

    redraw('test', testNo)    

    drawTest(mx,my,n.trim(),elevD);

  }

  const plansLab = (mx,my) => {

    let sampleNo = props.data === undefined ? Math.max(...arrayLab.current.map(o => o.gradeid === props.filter.gradeId ? o.sampleno : 0)) + 1 : props.data.sampleNo    

    tempX.current = mx
    tempY.current = my    

    redraw('lab', sampleNo)

    drawLab(mx,my,sampleNo);

  }

  const plansPresat = (mx,my) => {

    let id = props.data === undefined ? Math.max(...arrayPresat.current.map(o => o.id)) + 1 : props.data.id    

    tempX.current = mx
    tempY.current = my    

    redraw('presat', id)

    drawPresat(mx,my,id);

  }

  const plansSlope = (x2,y2,x1,y1) => {

      let ctx = props.canvasDraw.current.getContext('2d')
      let dx = Math.round(x2 - x1,1);
      let dy = Math.round(y2 - y1,1);

      let theta = Math.atan2(dy, dx); // range (-PI, PI]
      theta *= -180 / Math.PI; //* -1; // rads to degs, range (-180, 180] || *= || x *= y  || x = x * y // the -1 reverses the angle
      theta = theta - 90;// - angle; // now set 0 on y axis and account for plan rotation
      //if (theta < 0) theta = 360 + theta; // range [0, 360)

      let l = 15 * props.pdf.scale;
      let rad1 = ( -45 + theta ) * Math.PI / 180;
      let rad2 = ( 45 + theta ) * Math.PI / 180;

      // distance formula
      let d = Math.sqrt(Math.pow((x2-x1),2) + Math.pow((y2-y1),2));

      //determine new end pt
      let x = x2 - l/d * (x2 - x1);
      let y = y2 - l/d * (y2 - y1);

      // ORIGINALLY x/y used in place of x2/y2 below
      // however, mousedown not drawing for slope if x/y
      // possibly (x2-x1) is zero? causes issue, not sure..
      // so I replaced x/y with x2/y2 for quick fix

      //determine dx/dy for slope arrows and add
      dy = l * Math.cos(rad1);
      dx = l * Math.sin(rad1);

      ctx.lineTo(x2, y2);
      ctx.lineTo(x2-dx, y2-dy);
      ctx.lineTo(x2, y2);

      dy = l * Math.cos(rad2);
      dx = l * Math.sin(rad2);

      ctx.lineTo(x2-dx, y2-dy);
      ctx.stroke();

  }
  //
  // function plansdraw_area(x,y,numPoints) {
  //
  //     let area = 0;         // Accumulates area in the loop
  //     let j = numPoints-1;  // The last vertex is the 'previous' one to the first
  //     let i = 0;
  //
  //     // for centering txt
  //
  //     let xi;
  //     let yi;
  //     let xj;
  //     let yj;
  //
  //     let xmin = Number(x[i]);
  //     let ymin = Number(y[i]);
  //     let xmax = Number(x[i]);
  //     let ymax = Number(y[i]);
  //
  //     let coord;
  //
  //     console.log('x: ' + x);
  //     console.log('y: ' + y);
  //
  //     for (i=0; i<numPoints-1; i++) {
  //
  //         xi = Number(x[i]);
  //         yi = Number(y[i]);
  //         xj = Number(x[j]);
  //         yj = Number(y[j]);
  //
  //         coord = coordsXY(xi,yi);
  //
  //         xi = coord.e;
  //         yi = coord.n;
  //
  //         coord = coordsXY(xj,yj);
  //
  //         xi = coord.e;
  //         yi = coord.n;
  //
  //         // center ish pt
  //
  //         if (xi > xmax) {
  //
  //             xmax = xi;
  //
  //         }
  //
  //         if (yi > ymax) {
  //
  //             ymax = yi;
  //
  //         }
  //
  //         if (xi < xmin) {
  //
  //             xmin = xi;
  //
  //         }
  //
  //         if (yi < ymin) {
  //
  //             ymin = yi;
  //
  //         }
  //
  //         // calculate area
  //
  //         area = area +  (xj + xi) * (yj - yi);
  //         //console.log('x[i]: ' + x[i]);
  //         console.log('xi: ' + xi);
  //         console.log('xj: ' + xj);
  //         console.log('yi: ' + yi);
  //         console.log('yj: ' + yj);
  //         console.log('area: ' + area);
  //         j = i;  //j is previous vertex to i
  //
  //     }
  //
  //     //return area/2;
  //
  //     // set font
  //
  //     let ctx = props.canvasDraw.current.getContext('2d')
  //     ctx.font = "15px Arial";
  //     ctx.fillText( Math.abs(area/2) + ' sqft', (xmax-xmin)/2, (ymax-ymin)/2 );
  //
  // }
  //


  const drawDistance = (mx,my,sx,sy) => {

      // this will handle the distance and angle on the canvas

      // these determine positions of filltext

      let w = window.innerWidth;
      let h = window.innerHeight;

      //console.log(`w: ${w} h: ${h}`)

      let x = mx;
      let y = my;

      //console.log(`x: ${x} y: ${y}`)

      let dx = x - position.current.originalX; // initially set in start()
      let dy = y - position.current.originalY;

      //console.log(`dx: ${dx} dy: ${dy}`)

      let px = document.getElementById('canvascontainer').scrollLeft;
      let py = document.getElementById('canvascontainer').scrollTop;

      //console.log(`px: ${px} py: ${py}`)

      // fetch N/E

      let coord1 = coordsXY(sx,sy,props.planInfo,props.pdf.scale,props.mode)
      let coord2 = coordsXY(mx,my,props.planInfo,props.pdf.scale,props.mode)
      let coord_e_diff = Math.round(coord1.e - coord2.e,1);
      let coord_n_diff = Math.round(coord1.n - coord2.n,1);
      let coord_e2 = coord_e_diff * coord_e_diff;
      let coord_n2 = coord_n_diff * coord_n_diff;
      let sqrt_coord = Math.sqrt(coord_e2 + coord_n2);
      let dist = Math.round(sqrt_coord,1) || 0;
      let angle = props.planInfo.angle

      let theta = Math.atan2(coord_n_diff, coord_e_diff); // range (-PI, PI]
      theta *= -180 / Math.PI; //* -1; // rads to degs, range (-180, 180] || *= || x *= y  || x = x * y // the -1 reverses the angle
      theta = theta - 90 - angle; // now set 0 on y axis and account for plan rotation
      if (theta < 0) theta = 360 + theta; // range [0, 360)

      // console.log(`coord1: ${JSON.stringify(coord1)} coord2: ${JSON.stringify(coord2)}`)
      // console.log(`coord_e_diff: ${coord_e_diff} coord_n_diff: ${coord_n_diff}`)
      // console.log(`coord_e2: ${coord_e2} coord_n2: ${coord_n2}`)
      // console.log(`sqrt_coord: ${sqrt_coord} dist: ${dist} angle: ${angle} theta: ${theta}`)

      let ctx = props.canvasDraw.current.getContext('2d')

      // set font

      ctx.font = "15px Arial";
      ctx.strokeStyle = "#000000FF" //default black
      ctx.fillStyle = "#000000FF" //default black

      // position polyline details

      if (x > px + w - 100) { // right

          if (y < py + 100) { // top

              ctx.fillText(dist + ' ft',mx - 100,my + 100 - 50);
              ctx.fillText('\u0394 X: ' + Math.abs(coord_e_diff),mx - 100,my + 100 - 65);
              ctx.fillText('\u0394 Y: ' + Math.abs(coord_n_diff),mx - 100,my + 100 - 80);
              ctx.fillText('\u2220: ' + Math.round(theta,1),mx - 100,my + 100 - 95);

          }

          else {

              ctx.fillText(dist + ' ft',mx - 100,my - 50);
              ctx.fillText('\u0394 X: ' + Math.abs(coord_e_diff),mx - 100,my - 65);
              ctx.fillText('\u0394 Y: ' + Math.abs(coord_n_diff),mx - 100,my - 80);
              ctx.fillText('\u2220: ' + Math.round(theta,1),mx - 100,my - 95);

          }

      }

      else if (x < px + 100) { // left

          if (y < py + 100) { // top

              ctx.fillText(dist + ' ft',mx + 100,my + 100 - 50);
              ctx.fillText('\u0394 X: ' + Math.abs(coord_e_diff),mx + 100,my + 100 - 65);
              ctx.fillText('\u0394 Y: ' + Math.abs(coord_n_diff),mx + 100,my + 100 - 80);
              ctx.fillText('\u2220: ' + Math.round(theta,1),mx + 100,my + 100 - 95);

          }

          else {

              ctx.fillText(dist + ' ft',mx,my - 50);
              ctx.fillText('\u0394 X: ' + Math.abs(coord_e_diff),mx,my - 65);
              ctx.fillText('\u0394 Y: ' + Math.abs(coord_n_diff),mx,my - 80);
              ctx.fillText('\u2220: ' + Math.round(theta,1),mx,my - 95);

          }

      }

      else if (y < py + 100) { // top

          if (x < px + 100) { // left

              ctx.fillText(dist + ' ft',mx + 100,my + 100 - 50);
              ctx.fillText('\u0394 X: ' + Math.abs(coord_e_diff),mx + 100,my + 100 - 65);
              ctx.fillText('\u0394 Y: ' + Math.abs(coord_n_diff),mx + 100,my + 100 - 80);
              ctx.fillText('\u2220: ' + Math.round(theta,1),mx + 100,my + 100 - 95);

          }

          else {

            ctx.fillText(dist + ' ft',mx - 100,my + 100 - 50);
            ctx.fillText('\u0394 X: ' + Math.abs(coord_e_diff),mx - 100,my + 100 - 65);
            ctx.fillText('\u0394 Y: ' + Math.abs(coord_n_diff),mx - 100,my + 100 - 80);
            ctx.fillText('\u2220: ' + Math.round(theta,1),mx - 100,my + 100 - 95);

          }

      }

      else if (y > py + h - 100) { // bottom

          ctx.fillText(dist + ' ft',mx,my - 50);
          ctx.fillText('\u0394 X: ' + Math.abs(coord_e_diff),mx,my - 65);
          ctx.fillText('\u0394 Y: ' + Math.abs(coord_n_diff),mx,my - 80);
          ctx.fillText('\u2220: ' + Math.round(theta,1),mx,my - 95);

      }

      else {

          ctx.fillText(dist + ' ft',mx,my - 50);
          ctx.fillText('\u0394 X: ' + Math.abs(coord_e_diff),mx,my - 65);
          ctx.fillText('\u0394 Y: ' + Math.abs(coord_n_diff),mx,my - 80);
          ctx.fillText('\u2220: ' + Math.round(theta,1),mx,my - 95);

      }

  }

  const drawMove = (mx,my) => {

    let ctx = props.canvasDraw.current.getContext('2d')
    let xmax = ctx.canvas.width;
    let ymax = ctx.canvas.height;

    // let w = window.innerWidth;
    // let h = window.innerHeight;

    // desktop version splits screen, so w/h of div is needed

    let w = document.getElementById('planscontainer').offsetWidth
    let h = document.getElementById('planscontainer').offsetHeight

    let x = mx * props.pdf.scale;
    let y = my * props.pdf.scale;

    let nx = 0,
        ny = 0

    if (x < w/2) {

        nx = 0

    } else if (x > xmax - w/2) {

        nx = x - w/2;

    } else if (x > w/2 && x < xmax - w/2) {

        nx = x - w/2;

    } else {

        nx = x - w/2;

    }

    if (y < h/2) {

        ny = 0

    } else if (y > ymax - h/2) {

        ny = y - h/2;

    } else if (y > h/2 && y < ymax - h/2) {

        ny = y - h/2;

    } else {

        ny = y - h/2;

    }

    //console.log(`nx: ${nx} ny: ${ny}`)

    //scrollTo(document.getElementById('canvascontainer'), nx, ny)

    document.getElementById('canvascontainer').scrollTop = ny
    document.getElementById('canvascontainer').scrollLeft = nx

  }

  const styleStyle = (e) => {
    let value = e.target.getAttribute('data-id')

    setStyle(prevState => ({...prevState, style: value}))
  }

  const stylePreset = (e) => {

    let name = e.target.getAttribute('data-name')

    if (isModal.edit && (name === 'issue' || name === 'test' || name === 'lab' || name === 'presat' || name === 'temporary')) {

      alert('You cannot convert a drawing to this object.')

    } else if (name === 'issue' || name === 'test' || name === 'lab' || name === 'presat' || name === 'temporary') {      

      setStyle(prevState => ({...prevState, tool: name}))
      setTool(prevState => ({...prevState, draw: true, style: false}))

    } else {

      let i = e.target.getAttribute('data-i')    
      let value = e.target.getAttribute('data-id')
      let arr = presets[i].line.split(",")

      //console.log(`stylePresetId: ${value} stylePresetName: ${name} tool: ${presets[i].tool} color: ${presets[i].color} width: ${presets[i].width} line: ${presets[i].line}`)

      if (isModal.edit && isValidatedEdit.presetId !== value) {
        isEdited()

        styleEdit.current = {
          color: presets[i].color,
          presetId: value,
          presetName: name
        }
      }

      isModal.edit ?
      setIsValidatedEdit(prevState => ({...prevState, presetId: value, presetName: name, tool: presets[i].tool, color: presets[i].color, width: presets[i].width, line: arr})) :
      setStyle(prevState => ({...prevState, presetId: value, presetName: name, tool: presets[i].tool, color: presets[i].color, width: presets[i].width, line: arr}))

      setTool(prevState => ({...prevState, draw: true, style: false}))

    }

    
  }

  const styleTool = (e) => {
    //console.log(`styleTool nodeName: ${e.target.nodeName}`)
    let value = e.target.getAttribute('data-id')

    setStyle(prevState => ({...prevState, tool: value, presetId: 0, presetName: 'custom'}))

  }

  const styleColor = (e) => {
    let value = e.target.getAttribute('data-id')

    setStyle(prevState => ({...prevState, color: value, presetId: 0, presetName: 'custom'}))
    setTool(prevState => ({...prevState, draw: true}))
  }

  const styleColorOther = () => {
    alert('under construction')
  }

  const styleWidth = (e) => {
    let value = e.target.getAttribute('data-id')

    setStyle(prevState => ({...prevState, width: value, presetId: 0, presetName: 'custom'}))
    setTool(prevState => ({...prevState, draw: true}))
  }

  const styleLine = (e) => {

    let name = e.target.getAttribute('data-name') // i struggled with comparing an array for the border, so this was a quick fix
    let value = e.target.getAttribute('data-id')
    let arr = value.split(",")

    setStyle(prevState => ({...prevState, line: arr, lineName: name, presetId: 0, presetName: 'custom'}))
    setTool(prevState => ({...prevState, draw: true}))
    redraw('style') // wont update with setState above...need useRef
  }

  // const styleFill = (e) => {
  //   let value = e.target.getAttribute('data-id')
  //
  //   //setStyle(prevState => ({...prevState, fill: value}))
  //   drawing.current.fill = 'solid'
  // }

  const layerPreset = (e) => {
    let i = Number(e.target.getAttribute('data-i'))
    let id = Number(e.target.getAttribute('data-id'))
    let status = props.distinctPresets[i].status

    if (id === -6) { // hide all //e.target.textContent === 'Hide All'

      // now update the data for redraw

      arrayDraw.current = arrayDraw.current.map(data => ({...data, visible: status}))
      arrayIssue.current = arrayIssue.current.map(data => ({...data, visible: status}))
      arrayTest.current = arrayTest.current.map(data => ({...data, visible: status}))
      arrayLab.current = arrayLab.current.map(data => ({...data, visible: status}))
      arrayPresat.current = arrayPresat.current.map(data => ({...data, visible: status}))
      
      // if (props.searchLayer === '') {

      //   arrayDraw.current = arrayDraw.current.map(data => ({...data, visible: status}))
      //   arrayIssue.current = arrayIssue.current.map(data => ({...data, visible: status}))
      //   arrayTest.current = arrayTest.current.map(data => ({...data, visible: status}))
      //   arrayLab.current = arrayLab.current.map(data => ({...data, visible: status}))
      //   arrayPresat.current = arrayPresat.current.map(data => ({...data, visible: status}))

      // } else {

      //   arrayDraw.current = arrayDraw.current.map(data => ({...data, visible: status ? filterData(data, props.searchLayer) : status}))
      //   arrayIssue.current = arrayIssue.current.map(data => ({...data, visible: status ? filterData(data, props.searchLayer) : status}))
      //   arrayTest.current = arrayTest.current.map(data => ({...data, visible: status ? filterData(data, props.searchLayer) : status}))
      //   arrayLab.current = arrayLab.current.map(data => ({...data, visible: status ? filterData(data, props.searchLayer) : status}))
      //   arrayPresat.current = arrayPresat.current.map(data => ({...data, visible: status ? filterData(data, props.searchLayer) : status}))

      // }

      arrayDrawTemp.current = arrayDraw.current
      arrayIssueTemp.current = arrayIssue.current
      arrayTestTemp.current = arrayTest.current
      arrayLabTemp.current = arrayLab.current
      arrayPresatTemp.current = arrayPresat.current

      //update layer presets

      props.updateDistinctPresets(props.distinctPresets.map((preset, j) => (i !== j ? {...preset, status: status} : {...preset, status: status ? false : true})))

      redraw()

    } else {

      // now update the data for redraw

      if (id === -1) { // issue
        arrayIssue.current = arrayIssue.current.map(data => ({...data, visible: status ? false : true}))
        arrayIssueTemp.current = arrayIssue.current
      } else if (id === -2) { // test
        arrayTest.current = arrayTest.current.map(data => ({...data, visible: status ? false : true}))
        arrayTestTemp.current = arrayTest.current
      } else if (id === -3) { // lab
        arrayLab.current = arrayLab.current.map(data => ({...data, visible: status ? false : true}))
        arrayLabTemp.current = arrayLab.current
      } else if (id === -4) { // presat
        arrayPresat.current = arrayPresat.current.map(data => ({...data, visible: status ? false : true}))
        arrayPresatTemp.current = arrayPresat.current
      } else if (id === -5) { // temp
        arrayTemporary.current = arrayTemporary.current.map(data => ({...data, visible: status ? false : true}))        
      } else {
        arrayDraw.current = arrayDraw.current.map(data => (id === data.presetId ? {...data, visible: status ? false : true} : data))
        arrayDrawTemp.current = arrayDraw.current
      }

      //update layer presets

      props.updateDistinctPresets(props.distinctPresets.map((preset, j) => (i === j ? {...preset, status: preset.status ? false : true} : preset)))

      redraw()

    }

  }

  const layerFailingTest = () => {

    //someone will click failingTest, but have tests turned off...and not understand...

    props.updateDistinctPresets(props.distinctPresets.map((preset) => (preset.presetName === 'Tests' ? {...preset, status: true} : preset)))
    setLayer(prevState => ({...prevState, failingTest: layer.failingTest ? false : true}))

  }

  const layerIssue = () => {

    //someone will click failingTest, but have tests turned off...and not understand...

    props.updateDistinctPresets(props.distinctPresets.map((preset) => (preset.presetName === 'Issue' ? {...preset, status: true} : preset)))
    setLayer(prevState => ({...prevState, issue: layer.issue ? false : true}))

  }

  const centerOfPlan = () => {

    // track center of screen for zoomIn /zoomOut

    let scrollY = document.getElementById('canvascontainer').scrollTop
    let scrollX = document.getElementById('canvascontainer').scrollLeft

    zoom.current.x = (scrollX + window.innerWidth/2) / props.pdf.scale
    zoom.current.y = (scrollY + window.innerHeight/2) / props.pdf.scale

    //console.log(`Zoom x: ${zoom.current.x} y: ${zoom.current.y}`)

  }

  const zoomAndCenter = (dx, dy) => {

    let ctx = props.canvasDraw.current.getContext('2d')
    let xmax = ctx.canvas.width;
    let ymax = ctx.canvas.height;

    let w = window.innerWidth;
    let h = window.innerHeight;

    let x = dx * props.pdf.scale;
    let y = dy * props.pdf.scale;

    let nx = 0,
        ny = 0

    if (x < w/2) {

        nx = 0

    } else if (x > xmax - w/2) {

        nx = x - w/2;

    } else if (x > w/2 && x < xmax - w/2) {

        nx = x - w/2;

    } else {

        nx = x - w/2;

    }

    if (y < h/2) {

        ny = 0

    } else if (y > ymax - h/2) {

        ny = y - h/2;

    } else if (y > h/2 && y < ymax - h/2) {

        ny = y - h/2;

    } else {

        ny = y - h/2;

    }

    //console.log(`nx: ${nx} ny: ${ny}`)

    document.getElementById('canvascontainer').scrollTop = ny
    document.getElementById('canvascontainer').scrollLeft = nx

  }

  // const zoomIntensity = 0.2
  // let scale = 1
  // let originx = 0;
  // let originy = 0;
  // let visibleWidth = width;
  // let visibleHeight = height;

  const wheelZoom = (e) => {

    // if (e.ctrlKey) {
    //
    //   e.preventDefault();
    //
    //   //let scale = props.pdf.scale
    //   let canvas = props.canvasDraw.current
    //   let bd = canvas.getBoundingClientRect();
    //   let mx = parseInt(e.pageX - bd.x);
    //   let my = parseInt(e.pageY - bd.y);
    //
    //   // ======================
    //
    //   let context = canvas.getContext("2d")
    //   let context2 = props.canvasPlan.current.getContext('2d')
    //
    //         // props.canvasDraw.current.style.transform = `scale(${scale})`;
    //         // props.canvasDraw.current.style.transformOrigin = `top left`;
    //         //
    //         // props.canvasPlan.current.style.transform = `scale(${scale})`;
    //         // props.canvasPlan.current.style.transformOrigin = `top left`;
    //
    //   // Get mouse offset.
    // const mousex = e.clientX - canvas.offsetLeft;
    // const mousey = e.clientY - canvas.offsetTop;
    // // Normalize mouse wheel movement to +1 or -1 to avoid unusual jumps.
    // const wheel = e.deltaY < 0 ? 1 : -1;
    //
    // // Compute zoom factor.
    // const zoom = Math.exp(wheel * zoomIntensity);
    //
    // // Translate so the visible origin is at the context's origin.
    // context.translate(originx, originy);
    // context2.translate(originx, originy);
    //
    // // Compute the new visible origin. Originally the mouse is at a
    // // distance mouse/scale from the corner, we want the point under
    // // the mouse to remain in the same place after the zoom, but this
    // // is at mouse/new_scale away from the corner. Therefore we need to
    // // shift the origin (coordinates of the corner) to account for this.
    // originx -= mousex/(scale*zoom) - mousex/scale;
    // originy -= mousey/(scale*zoom) - mousey/scale;
    //
    // // Scale it (centered around the origin due to the trasnslate above).
    // context.scale(zoom, zoom);
    // context2.scale(zoom, zoom);
    // // Offset the visible origin to it's proper position.
    // context.translate(-originx, -originy);
    // context2.translate(-originx, -originy);
    //
    // // Update scale and others.
    // scale *= zoom;
    // visibleWidth = width / scale;
    // visibleHeight = height / scale;
    //
    //   // ======================
    //
    //
    //
    //   // let delta = e.deltaY > 0 ? -0.02 : 0.02
    //   //
    //   // scale += delta //e.deltaY * -0.01 - 1;
    //   //
    //   // // Restrict scale
    //   // scale = Math.round(Math.min(Math.max(.125, scale), 4))
    //   //
    //   // props.canvasDraw.current.style.transform = `scale(${scale})`;
    //   // props.canvasDraw.current.style.transformOrigin = `top left`;
    //   //
    //   // props.canvasPlan.current.style.transform = `scale(${scale})`;
    //   // props.canvasPlan.current.style.transformOrigin = `top left`;
    //
    //   props.updateScale(scale)
    //
    //   redraw('refresh')
    //
    //   document.getElementById("plansZoom").innerHTML = props.pdf.scale * 100 + `%`
    //
    //   //document.getElementById('canvascontainer').height = props.canvasDraw.current.height
    //   //document.getElementById('canvascontainer').width = props.canvasDraw.current.width
    //
    //   // const rect = document.getElementById('canvascontainer').getBoundingClientRect();
    //   // const dx = pinch.current.startX - rect.left;
    //   // const dy = pinch.current.startY - rect.top;
    //
    //   //if (pinch.current.scale > 0.5 && pinch.current.scale < 4) {
    //
    //
    //
    //     //document.getElementById('canvascontainer').scrollLeft = e.pageX
    //     //document.getElementById('canvascontainer').scrollTop = e.pageY
    //
    //
    // }

  }

  // const pinch = useRef({
  //   startX: 0,
  //   startY: 0,
  //   endX: 0,
  //   endY: 0,
  //   deltaX1: 0,
  //   deltaY1: 0,
  //   deltaX2: 0,
  //   deltaY2: 0,
  //   initialPinchDistance: 0,
  //   pinchScale: 1,
  //   scale: props.pdf.scale
  // })
  //
  // const pinchStart = (e) => {
  //
  //   if (e.touches.length > 1) {
  //     //pinch.current.startX = (e.touches[0].pageX + e.touches[1].pageX) / 2;
  //     //pinch.current.startY = (e.touches[0].pageY + e.touches[1].pageY) / 2;
  //
  //     pinch.current.startX = e.touches[0].pageX
  //     pinch.current.startY = e.touches[0].pageY
  //
  //     pinch.current.deltaX1 = e.touches[1].pageX
  //     pinch.current.deltaY1 = e.touches[1].pageY
  //
  //     pinch.current.initialPinchDistance = Math.hypot((e.touches[1].pageX - e.touches[0].pageX), (e.touches[1].pageY - e.touches[0].pageY));
  //
  //     // let canvas = props.canvasDraw.current
  //     // let bd = canvas.getBoundingClientRect();
  //     // let px = e.touches[0].pageX;
  //     // let py = e.touches[0].pageY;
  //     // let mx = parseInt(px - bd.x);
  //     // let my = parseInt(py - bd.y);
  //     //
  //     // console.log(`bd: ${JSON.stringify(bd)}`)
  //
  //   } else {
  //     pinch.current.initialPinchDistance = 0;
  //   }
  //
  // }
  //
  // const pinchMove = (e) => {
  //
  //   if (pinch.current.initialPinchDistance <= 0 || e.touches.length < 2) { return }
  //
  //   e.preventDefault()
  //
  //   pinch.current.deltaX2 = e.touches[1].pageX
  //   pinch.current.deltaY2 = e.touches[1].pageY
  //
  //   let distance = Math.hypot((pinch.current.deltaX2 - pinch.current.startX), (pinch.current.deltaY2 - pinch.current.startY))
  //
  //   pinch.current.pinchScale = (distance / pinch.current.initialPinchDistance)
  //
  //   let scale = pinch.current.scale * pinch.current.pinchScale
  //
  //   scale = scale > 3 ? 3 : scale < 0.25 ? 0.25 : scale
  //
  //   // const awaitTransform = async () => {
  //   //
  //   //   props.canvasDraw.current.style.transform = `scale(${scale})`;
  //   //   props.canvasDraw.current.style.transformOrigin = `top left`;
  //   //
  //   //   props.canvasPlan.current.style.transform = `scale(${scale})`;
  //   //   props.canvasPlan.current.style.transformOrigin = `top left`;
  //   //
  //   //   return await Promise.resolve(setTimeout(true, 500))
  //   //
  //   // }
  //   //
  //   // awaitTransform()
  //   // .then(res => {
  //
  //     //if (pinch.current.scale > 0.5 && pinch.current.scale < 4) {
  //
  //       //let scale = pinch.current.scale + pinch.current.pinchScale
  //
  //       props.canvasDraw.current.style.transform = `scale(${scale})`;
  //       props.canvasDraw.current.style.transformOrigin = `top left`;
  //
  //       props.canvasPlan.current.style.transform = `scale(${scale})`;
  //       props.canvasPlan.current.style.transformOrigin = `top left`;
  //
  //       //pinch.current.previousDelta = pinchDelta
  //       //let centerX = pinch.current.startX - e.clientX / 2
  //
  //       //let clientX = e.clientX
  //
  //       pinch.current.endX = e.touches[1].pageX
  //       pinch.current.endY = e.touches[1].pageY
  //
  //       //let deltaX = (e.touches[0].pageX + e.touches[1].pageX) / 2;
  //       //let deltaY = (e.touches[0].pageY + e.touches[1].pageY) / 2;
  //       //console.log(`scale1 ${props.pdf.scale}`)
  //       //console.log(`scale2 ${scale}`)
  //       //console.log(`scale? ${props.pdf.scale * scale}`)
  //
  //       let canvas = props.canvasDraw.current
  //       let bd = canvas.getBoundingClientRect()
  //       let deltaX = (e.touches[0].pageX + e.touches[1].pageX) / 2
  //       let deltaY = (e.touches[0].pageY + e.touches[1].pageY) / 2
  //       let mx = parseInt(deltaX - bd.x) * props.pdf.scale * scale
  //       let my = parseInt(deltaY - bd.y) * props.pdf.scale * scale
  //
  //       console.log(`bd: ${JSON.stringify(bd)}`)
  //
  //       // let canvas = props.canvasDraw.current
  //       // let bd = canvas.getBoundingClientRect()
  //       // let mx = parseInt(e.touches[0].pageX - bd.x)
  //       // let my = parseInt(e.touches[0].pageY - bd.y)
  //
  //         // let ctx = props.canvasDraw.current.getContext('2d')
  //         // let xmax = ctx.canvas.width;
  //         // let ymax = ctx.canvas.height;
  //         //
  //         // let w = window.innerWidth;
  //         // let h = window.innerHeight;
  //         //
  //         // let x = dx * props.pdf.scale;
  //         // let y = dy * props.pdf.scale;
  //
  //         //let scrollY = document.getElementById('canvascontainer').scrollTop
  //         //let scrollX = document.getElementById('canvascontainer').scrollLeft
  //
  //         //zoom.current.x = (scrollX + window.innerWidth/2) / props.pdf.scale
  //         //zoom.current.y = (scrollY + window.innerHeight/2) / props.pdf.scale
  //
  //         // console.log(`data:
  //         //   px${px}
  //         //   py${py}
  //         //   mx${mx}
  //         //   my${my}
  //         //   bx${bd.x}
  //         //   by${bd.y}
  //         //   `)
  //
  //       // let scrollLeft = Math.max(0, (e.touches[0].pageX + e.touches[1].pageX) / 2 - window.screen.availWidth / 2)
  //       // let scrollTop = Math.max(0, (e.touches[0].pageY + e.touches[1].pageY) / 2 - window.screen.availHeight / 2)
  //
  //
  //       //let scrollLeft = (scrollX + window.innerWidth/2) / (props.pdf.scale * props.pdf.zoomScale)
  //       //let scrollTop = (scrollY + window.innerHeight/2) / (props.pdf.scale * props.pdf.zoomScale)
  //
  //       //let clientX = (e.touches[0].clientX + e.touches[1].clientX) / 2
  //       //let clientY = (e.touches[0].clientY + e.touches[1].clientY) / 2
  //
  //       //console.log(`screenX: ${clientX}`)
  //       //console.log(`screenY: ${clientY}`)
  //
  //       //pinch.current.startX = (e.touches[0].pageX + e.touches[1].pageX) / 2;
  //       //pinch.current.startY = (e.touches[0].pageY + e.touches[1].pageY) / 2;
  //
  //       //let positionX = (e.touches[0].pageX + e.touches[1].pageX) / 2 - clientX
  //       //let positionY = (e.touches[0].pageY + e.touches[1].pageY) / 2 - clientY
  //
  //       //console.log(`positionX: ${positionX}`)
  //       //console.log(`positionY: ${positionY}`)
  //
  //       document.getElementById('canvascontainer').scrollLeft = mx
  //       document.getElementById('canvascontainer').scrollTop = my
  //
  //     //}
  //
  //   //})
  //
  // }
  //
  // const pinchEnd = (e) => {
  //
  //   if (pinch.current.initialPinchDistance <= 0) { return; }
  //
  //   //props.canvasDraw.current.style.transform = `none`;
  //   //props.canvasDraw.current.style.transformOrigin = `unset`;
  //
  //   //props.canvasPlan.current.style.transform = `none`;
  //   //props.canvasPlan.current.style.transformOrigin = `unset`;
  //
  //   //PDFViewerApplication.pdfViewer.currentScale *= pinchScale;
  //   //const rect = document.getElementById('canvascontainer').getBoundingClientRect();
  //   //const dx = pinch.current.startX - rect.left;
  //   //const dy = pinch.current.startY - rect.top;
  //
  //   //if (pinch.current.scale > 0.5 && pinch.current.scale < 4) {
  //
  //   // pinch.current.deltaX2 = e.touches[1].pageX
  //   // pinch.current.deltaY2 = e.touches[1].pageY
  //   //
  //   //   //let pinchDistance = Math.hypot((pinch.current.deltaX2 - pinch.current.deltaX1), (pinch.current.deltaY2 - pinch.current.deltaY1))
  //   //
  //   //   //let scale = pinch.current.pinchScale + pinchDistance / pinch.current.initialPinchDistance
  //   //
  //   //
  //   //   let pinchDelta = Math.hypot((pinch.current.deltaX2 - pinch.current.deltaX1), (pinch.current.deltaY2 - pinch.current.deltaY1))
  //   //   let currentPinchDistance = Math.hypot((pinch.current.deltaX2 - pinch.current.startX), (pinch.current.deltaY2 - pinch.current.startY))
  //   //
  //   //   pinchDelta *= currentPinchDistance > pinch.current.previousPinchDistance ? 1 : -1
  //   //
  //   //   if (currentPinchDistance > pinch.current.previousPinchDistance) {console.log(`pinchDelta: +`)} else {console.log(`pinchDelta: -`)}
  //   //
  //   //   let scale = pinch.current.pinchScale + pinchDelta / pinch.current.initialPinchDistance
  //   //
  //   //   //document.getElementById('canvascontainer').scrollLeft += dx * (pinch.current.pinchScale - 1);
  //   //   //document.getElementById('canvascontainer').scrollTop += dy * (pinch.current.pinchScale - 1);
  //   //
  //   // //}
  //
  //   let scale = pinch.current.scale * pinch.current.pinchScale
  //
  //   scale = scale > 3 ? 3 : scale < 0.25 ? 0.25 : scale
  //
  //   pinch.current = {
  //     startX: 0,
  //     startY: 0,
  //     endX: 0,
  //     endY: 0,
  //     deltaX1: 0,
  //     deltaY1: 0,
  //     deltaX2: 0,
  //     deltaY2: 0,
  //     initialPinchDistance: 0,
  //     pinchScale: 1,
  //     scale: scale
  //   }
  //
  //   //console.log(`pdf scale3: ${scale}`)
  //
  //   props.updateScale(scale)
  //
  //   //console.log(`pdf scale4: ${scale}`)
  //
  //   redraw()
  //
  //
  // }

  const disablePinchZoom = (e) => {

    if (e.touches.length > 1) e.preventDefault()

  }

  const centerPinchZoom = (e) => {

    let canvas = props.canvasDraw.current
    let bd = canvas.getBoundingClientRect()

    // pinch.current.bX = bd.x
    // pinch.current.bY = bd.y

    document.getElementById('canvascontainer').scrollLeft = bd.x + bd.width / 2
    document.getElementById('canvascontainer').scrollTop = bd.y + bd.height / 2

    console.log('center')

  }

  const pinch = useRef({
    startX: 0,
    startY: 0,
    bX: 0,
    bY: 0,
    initialPinchDistance: 0,
    pinchScale: 1,
    scale: props.pdf.scale
  })

  const pinchStart = (e) => {

    if (e.touches.length > 1) {

      pinch.current.startX = (e.touches[0].pageX + e.touches[1].pageX) / 2
      pinch.current.startY = (e.touches[0].pageY + e.touches[1].pageY) / 2

      pinch.current.initialPinchDistance = Math.hypot((e.touches[1].pageX - e.touches[0].pageX), (e.touches[1].pageY - e.touches[0].pageY));

      //let scaledStartX = pinch.current.scale * pinch.current.startX
      //let scaledStartY = pinch.current.scale * pinch.current.startY

      //pinch.current.deltaX = pinch.current.startX - scaledStartX
      //pinch.current.deltaY = pinch.current.startY - scaledStartY

      let canvas = props.canvasDraw.current
      let bd = canvas.getBoundingClientRect()

      pinch.current.bX = bd.left
      pinch.current.bY = bd.top

    } else {
      pinch.current.initialPinchDistance = 0;
    }

  }

  const pinchMove = (e) => {

    if (pinch.current.initialPinchDistance <= 0 || e.touches.length < 2) return

    e.preventDefault()

    let pinchDistance = Math.hypot((e.touches[1].pageX - e.touches[0].pageX), (e.touches[1].pageY - e.touches[0].pageY))

    pinch.current.pinchScale = pinchDistance / pinch.current.initialPinchDistance

    let scale = pinch.current.scale * pinch.current.pinchScale

    scale = scale > 3 ? 3 : scale < 0.25 ? 0.25 : scale

    if (isNaN(scale) || scale === null) return


    // let currX = (e.touches[0].pageX + e.touches[1].pageX) / 2
    // let currY = (e.touches[0].pageY + e.touches[1].pageY) / 2

    let canvas = props.canvasDraw.current
    let bd = canvas.getBoundingClientRect()
    //let mx = (currX - bd.x) / pinch.current.scale
    //let my = (currY - bd.y) / pinch.current.scale

    let px = document.getElementById('canvascontainer').scrollLeft;
    let py = document.getElementById('canvascontainer').scrollTop;

    //let scaledStartX = pinch.current.scale * pinch.current.startX
    //let scaledStartY = pinch.current.scale * pinch.current.startY

    //pinch.current.deltaX = pinch.current.startX - scaledStartX
    //pinch.current.deltaY = pinch.current.startY - scaledStartY

    //let mx = (pinch.current.startX + px - pinch.current.bX) / pinch.current.scale
    //let my = (pinch.current.startY + py - pinch.current.bY) / pinch.current.scale

    let mx = ( -pinch.current.bX + px + pinch.current.startX ) / pinch.current.scale
    let my = ( -pinch.current.bY + py + pinch.current.startY ) / pinch.current.scale

    //let mx = ( px + pinch.current.startX )
    //let my = ( py + pinch.current.startY )

    console.log(`bd: ${JSON.stringify(bd)}`)
    console.log(`startX: ${pinch.current.startX}, startY: ${pinch.current.startY}`)
    console.log(`px: ${px}, py: ${py}`)
    console.log(`mx: ${mx}, my: ${my}`)

    props.updateScale(scale)

    document.getElementById("plansZoom").innerHTML = `${Math.round(props.pdf.scale * props.pdf.zoomScale * 100)}%`

    props.canvasDraw.current.style.transform = `scale(${scale})`;
    props.canvasDraw.current.style.transformOrigin = `${mx}px ${my}px`;

    props.canvasPlan.current.style.transform = `scale(${scale})`;
    props.canvasPlan.current.style.transformOrigin = `${mx}px ${my}px`;

  }

  const pinchEnd = (e) => {

    if (pinch.current.initialPinchDistance <= 0)  return

    let scale = pinch.current.scale * pinch.current.pinchScale

    scale = scale > 3 ? 3 : scale < 0.25 ? 0.25 : scale

    if (isNaN(scale) || scale === null) return

    props.updateScale(scale)

    document.getElementById("plansZoom").innerHTML = `${Math.round(props.pdf.scale * props.pdf.zoomScale * 100)}%`

    // let canvas = props.canvasDraw.current
    // let bd = canvas.getBoundingClientRect()
    //
    // pinch.current = {
    //   startX: 0,
    //   startY: 0,
    //   bX: bd.left,
    //   bY: bd.top,
    //   initialPinchDistance: 0,
    //   pinchScale: 1,
    //   scale: scale
    // }

    pinch.current = {
      startX: 0,
      startY: 0,
      bX: 0,
      bY: 0,
      initialPinchDistance: 0,
      pinchScale: 1,
      scale: scale
    }

    //console.log(`pdf scale3: ${scale}`)



    //console.log(`pdf scale4: ${scale}`)

    redraw('refresh')


  }

  const toolZoomIn = () => {

    centerOfPlan()

    props.zoomIn()

    zoomAndCenter(zoom.current.x,zoom.current.y)

    //document.getElementById("plansZoom").innerHTML = props.pdf.scale * 100 + `%`

    //redraw()

    //console.log(`In zoom x: ${zoom.current.x} y: ${zoom.current.y} scale: ${props.pdf.scale}`)

  }

  const toolZoomOut = () => {

    centerOfPlan()

    props.zoomOut()

    zoomAndCenter(zoom.current.x,zoom.current.y)

    //document.getElementById("plansZoom").innerHTML = props.pdf.scale * 100 + `%`

    //redraw()

    //console.log(`Out zoom x: ${zoom.current.x} y: ${zoom.current.y} scale: ${props.pdf.scale}`)

  }

  const toolRefresh = () => {
    openAlert('Refreshed')
    fetchData()
  }

  const toolDirection = (e) => {

    let id = e.target.getAttribute('data-id')

    function scrollTo(element, to, duration, direction) {
        let start =
            direction === 'up' || direction === 'down' ? element.scrollTop : element.scrollLeft,
            change = start + to,
            currentTime = 0,
            increment = 20;

        let animateScroll = function(){
            currentTime += increment;
            let val = Math.easeInOutQuad(currentTime, start, change, duration);
            if (direction === 'up' || direction === 'down') {
              element.scrollTop = val
            } else {
              element.scrollLeft = val
            }
            //element.scrollTop = val;
            if(currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    }

    //t = current time
    //b = start value
    //c = change in value
    //d = duration
    Math.easeInOutQuad = function (t, b, c, d) {
      t /= d/2;
    	if (t < 1) return c/2*t*t + b;
    	t--;
    	return -c/2 * (t*(t-2) - 1) + b;
    }

    if (id === 'up') {

        //document.getElementById('canvascontainer').animate({ scrollTop: '-=100' }, 500);
        scrollTo(document.getElementById('canvascontainer'), -100, 500,'up')

    } else if (id === 'down') {

        //document.getElementById('canvascontainer').animate({ scrollTop: '+=100' }, 500);
        scrollTo(document.getElementById('canvascontainer'), 100, 500, 'down')

    } else if (id === 'left') {

        //document.getElementById('canvascontainer').animate({ scrollLeft: '-=100' }, 500);
        scrollTo(document.getElementById('canvascontainer'), -100, 500, 'left')

    } else if (id === 'right') {

        //document.getElementById('canvascontainer').animate({ scrollLeft: '+=100' }, 500);
        scrollTo(document.getElementById('canvascontainer'), 100, 500, 'right')

    }

  }

  const toolEdit = () => {

    if (tool.edit === false) {

      redraw('edit') // turn on nodes
      snapBuildArray() // to snap to previous nodes
      setTool(prevState => ({...prevState, edit: true, draw: false}))

    } else {

      redraw() // turn off nodes
      setTool(prevState => ({...prevState, edit: false}))

    }

  }

  const toolEditAccept = () => {

    if (props.mode === 'plansmanage') {

      if (isValidated.n1 === '' && (props.planInfo.n1 === null || props.planInfo.n1 === '')) {
        alert('Please provide a North for Pt1')
      } else if (isValidated.e1 === '' && (props.planInfo.e1 === null || props.planInfo.e1 === '')) {
        alert('Please provide a East for Pt1')
      } else if (isValidated.n2 === '' && (props.planInfo.n2 === null || props.planInfo.n2 === '')) {
        alert('Please provide a North for Pt2')
      } else if (isValidated.e2 === '' && (props.planInfo.e2 === null || props.planInfo.e2 === '')) {
        alert('Please provide a East for Pt2')
      } else {

        let x1,y1,x2,y2,ax1,ay1,ax2,ay2,n1,e1,n2,e2,maxN,maxE,minN,minE,coords

        n1 = isValidated.n1 === '' ? props.planInfo.n1 : isValidated.n1
        e1 = isValidated.e1 === '' ? props.planInfo.e1 : isValidated.e1
        n2 = isValidated.n2 === '' ? props.planInfo.n2 : isValidated.n2
        e2 = isValidated.e2 === '' ? props.planInfo.e2 : isValidated.e2

        for (let i = 0; i < pts.current.length; i++) {

          if (pts.current[i].id === 'pt1') {

            x1 = pts.current[i].x;
            y1 = pts.current[i].y;

          }

          else if (pts.current[i].id === 'pt2') {

            x2 = pts.current[i].x;
            y2 = pts.current[i].y;

          }

          else if (pts.current[i].id === 'a1') {

            ax1 = pts.current[i].x;
            ay1 = pts.current[i].y;

          }

          else if (pts.current[i].id === 'a2') {

            ax2 = pts.current[i].x;
            ay2 = pts.current[i].y;

          }

        }

        let a = angle(ax1, ay1, ax2, ay2)

        let planInfo = {
          angle: a,
          x1: x1,
          y1: y1,
          x2: x2,
          y2: y2,
          n1: n1,
          e1: e1,
          n2: n2,
          e2: e2
        }

        coords = coordsXY(props.canvasDraw.current.width, props.canvasDraw.current.height, planInfo, 1, props.mode)

        maxN = coords.n
        maxE = coords.e

        coords = coordsXY(0, 0, planInfo, 1, props.mode)

        minN = coords.n
        minE = coords.e

        props.selectPlanCoords(x1,y1,x2,y2,n1,e1,n2,e2,ax1,ay1,ax2,ay2,a,maxN,maxE,minN,minE)

        props.closeModal()

      }

    } else if (props.mode === "plansdraw") {

      // console.log(`
      //   arrayDrawUpdate.current.length: ${JSON.stringify(arrayDrawUpdate.current)}
      //   arrayIssueUpdate.current.length ${JSON.stringify(arrayIssueUpdate.current)} 
      //   arrayTestUpdate.current.length ${JSON.stringify(arrayTestUpdate.current)} 
      //   arrayLabUpdate.current.length ${JSON.stringify(arrayLabUpdate.current)}
      //   arrayPresatUpdate.current.length ${JSON.stringify(arrayPresatUpdate.current)}
      // `)

      if (
        arrayDrawUpdate.current.length === 0 &&
        arrayIssueUpdate.current.length === 0 && 
        arrayTestUpdate.current.length === 0 && 
        arrayLabUpdate.current.length === 0 &&
        arrayPresatUpdate.current.length === 0
      ) {

        openAlert('Nothing to Save')

      } else if (arrayDrawUpdate.current.length > 0) {

        ///console.log(`update pos: ${JSON.stringify(arrayDrawUpdate.current)}`)

        getLocation(function(latlng){

          fetch('/api/updateDrawingPosition', {
            method: 'post',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              by: props.user.username,
              time: formatDateTime(new Date()),
              lat: latlng.lat,
              lng: latlng.lng,
              device: props.user.device,
              update: arrayDrawUpdate.current
            })
          })
          .then(res=>res.json())
          .then(
            (result) => {
              //console.log('result: ' + JSON.stringify(result))

              addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'update', 'drawing position', props.user.username)

              openAlert('Saved')
              fetchData()

              arrayDrawUpdate.current = []

              return true

            },
            (error) => {

              alert('Error: could not update drawing positions. Contact and admin.')
              catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'updateDrawingPosition', JSON.stringify(error), props.user.username, props.user.device)
            }
          )

        })

      }

      if (arrayIssueUpdate.current.length > 0) {

        getLocation(function(latlng){

          fetch('/api/updateIssuePosition', {
            method: 'post',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              by: props.user.username,
              time: formatDateTime(new Date()),
              lat: latlng.lat,
              lng: latlng.lng,
              device: props.user.device,
              update: arrayIssueUpdate.current
            })
          })
          .then(res=>res.json())
          .then(
            (result) => {
              //console.log('result: ' + JSON.stringify(result))

              addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'update', 'issue position', props.user.username)

              openAlert('Saved')
              fetchData()

              arrayIssueUpdate.current = []

              return true

            },
            (error) => {

              alert('Error: could not update issue positions. Contact and admin.')
              catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'updateIssuePosition', JSON.stringify(error), props.user.username, props.user.device)
            }
          )

        })

      }

      if (arrayTestUpdate.current.length > 0) {

        //console.log(`update pos: ${JSON.stringify(arrayTestUpdate.current)}`)

        getLocation(function(latlng){

          fetch('/api/updateTestPosition', {
            method: 'post',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              by: props.user.username,
              time: formatDateTime(new Date()),
              lat: latlng.lat,
              lng: latlng.lng,
              device: props.user.device,
              update: arrayTestUpdate.current
            })
          })
          .then(res=>res.json())
          .then(
            (result) => {
              //console.log('result: ' + JSON.stringify(result))

              addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'update', 'test position', props.user.username)

              openAlert('Saved')
              fetchData()

              arrayTestUpdate.current = []

              return true

            },
            (error) => {

              alert('Error: could not update test positions. Contact and admin.')
              catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'updateTestPosition', JSON.stringify(error), props.user.username, props.user.device)
            }
          )

        })

      }

      if (arrayLabUpdate.current.length > 0) {

        getLocation(function(latlng){

          fetch('/api/updateLabPosition', {
            method: 'post',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              by: props.user.username,
              time: formatDateTime(new Date()),
              lat: latlng.lat,
              lng: latlng.lng,
              device: props.user.device,
              update: arrayLabUpdate.current
            })
          })
          .then(res=>res.json())
          .then(
            (result) => {
              //console.log('result: ' + JSON.stringify(result))

              addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'update', 'lab position', props.user.username)

              openAlert('Saved')
              fetchData()

              arrayLabUpdate.current = []

              return true

            },
            (error) => {

              alert('Error: could not update lab positions. Contact and admin.')
              catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'updateLabPosition', JSON.stringify(error), props.user.username, props.user.device)
            }
          )

        })

      }

      if (arrayPresatUpdate.current.length > 0) {

        getLocation(function(latlng){

          fetch('/api/updatePresatPosition', {
            method: 'post',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              by: props.user.username,
              time: formatDateTime(new Date()),
              lat: latlng.lat,
              lng: latlng.lng,
              device: props.user.device,
              update: arrayPresatUpdate.current
            })
          })
          .then(res=>res.json())
          .then(
            (result) => {
              //console.log('result: ' + JSON.stringify(result))

              addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'update', 'presat position', props.user.username)

              openAlert('Saved')
              fetchData()

              arrayPresatUpdate.current = []

              return true

            },
            (error) => {

              alert('Error: could not update presat positions. Contact and admin.')
              catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'updatePresatPosition', JSON.stringify(error), props.user.username, props.user.device)
            }
          )

        })

      }

      isEditedPosition.current = false

    }

  }

  const clearUpdates = () => {
    arrayDrawUpdate.current = []
    arrayIssueUpdate.current = []
    arrayTestUpdate.current = []
    arrayLabUpdate.current = []
    arrayPresatUpdate.current = []
  }

  const toolEditCancel = () => {

    const cancelEdits = () => {

      clearUpdates()

      isEditedPosition.current = false

      openAlert('Edits removed')

      fetchData() //is this needed?? Using for now, because i temp still shows after cancel.. not sure why for now

    }

    if (isEditedPosition.current) {

      if (window.confirm('You have unsaved edits. Proceed?')) {
        cancelEdits()
        //redraw()
      }

    } else {
      openAlert('No Edits Found')
    }

  }

  const toolCoordsAccept = () => {    

    if (props.mode === 'plansFilterPolygon') {
      addFilterPolygon()
    } else {
      let coord
      
      // fetch N/E

      if (isValidated.n !== '' && isValidated.e !== '') {

        props.selectLocate(isValidated.n, isValidated.e)
        
      } else {

        coord = coordsXY(tempX.current, tempY.current, props.planInfo,props.pdf.scale,props.mode)

        props.selectLocate(coord.n, coord.e)
        
      }      

      tempX.current = null
      tempY.current = null
    }

    props.closePlan()
    props.closeModal()
  }

  const toolLocate = () => setTool(prevState => ({...prevState, locate: true}))

  const toolCenter = () => {

    let mx = arrayTempX.current[arrayTempX.current.length - 1]
    let my = arrayTempY.current[arrayTempY.current.length - 1]

    if (arrayTempX.current.length === 0) {

        alert('Cannot center until something is drawn.');
        //openAlert('Cannot center until something is drawn')

    }

    else {

        drawMove(mx,my);

    }

  }

  const toolDelete = () => {
    if (window.confirm('If you proceed, this will be deleted. Proceed?')) {
      clearIsValidated() // clear inputs
      props.savedData() // clears isChanged
      toolCancel() // resets drawing variables
    }
  }

  const toolCancel = () => {

    drawing.current = {
      start: false,
      active: false,
      offset: false,
      refOffset: false,
      refOffsetStart: false,
      refOffsetEnd: false,
      refStart: false,
      refEnd: false
    }

    drawing.current.fill = ''

    //setStyle(prevState => ({...prevState, fill: ''}))

    arrayTempX.current = []
    arrayTempY.current = []

    arrayUndoX.current = []
    arrayUndoY.current = []

    arrayDrawUpdate.current = []
    arrayTestUpdate.current = []

    arrayDrawTemp.current = arrayDraw.current

    redraw()

  }

  const toolRef = () => setTool(prevState => ({...prevState, ref: tool.ref ? false : true}))

  const toolOffset = () => setTool(prevState => ({...prevState, offset: tool.offset ? false : true}))  

  const toolSnap = () => setTool(prevState => ({...prevState, snap: tool.snap ? false : true}))    

  const toolJoin = () => {

    let x = arrayTempX.current[0]
    let y = arrayTempY.current[0]
    let lastX = arrayTempX.current[arrayTempX.current.length - 1]
    let lastY = arrayTempY.current[arrayTempY.current.length - 1]

    if (x !== lastX || y !== lastY) {

        addTemp(x * props.pdf.scale,y * props.pdf.scale)

        redraw() // prevents extra stroke()

        draw(x * props.pdf.scale,y * props.pdf.scale,'join') // * props.pdf.scale sets it back to neutral since temp /props.pdf.scale, join prevents extra stoke if offset on

        drawMove(x,y)

        //plansdraw_area(arrayTempX.current,arrayTempY.current,arrayTempX.current.length);

    }

    else {

        alert('It appears to be joined already.')

    }

  }

  const toolFill = () => {

    let lastX = arrayTempX.current[arrayTempX.current.length - 1]
    let lastY = arrayTempY.current[arrayTempY.current.length - 1]

    if (arrayTempX.current[0] === lastX && arrayTempY.current[0] === lastY) {

      // is the fill tool needed if i can just pass the value down, and the setState will not update for redraw() below

      //setTool(prevState => ({...prevState, fill: true})) // changes to false in redraw_temp()

      // fill in style appears to be a wasted render.. only used in addDrawing and offline (3 places). could use a ref

      //setStyle(prevState => ({...prevState, fill: 'solid'}))

      drawing.current.fill = 'solid'

      redraw() // prevents extra stroke()

    } else {

      // console.log('========FILL========')
      // console.log('temp_x: ' + arrayTempX.current[0]);
      // console.log('last_x: ' + last_x);
      // console.log('temp_y: ' + arrayTempY.current[0]);
      // console.log('last_y: ' + last_y);

      alert('You must first click -><- (Join)')

    }

  }

  const toolUndo = () => {

    if (arrayTempX.current.length === 0) {

        alert('Nothing to undo.');

    }

    else {

        let lastX = arrayTempX.current[arrayTempX.current.length - 1]
        let lastY = arrayTempY.current[arrayTempY.current.length - 1]

        // before undo, add to array

        arrayUndoX.current.push(lastX)
        arrayUndoY.current.push(lastY)

        // remove last point

        arrayTempX.current.pop()
        arrayTempY.current.pop()

        // reset the start --> for dimensions

        lastX = arrayTempX.current[arrayTempX.current.length - 1]
        lastY = arrayTempY.current[arrayTempY.current.length - 1]

        position.current.startX = lastX
        position.current.startY = lastY

        // clear the last 2 pts, so appears like the last line segment is cleared

        if (arrayTempX.current.length === 1) {

            lastX = arrayTempX.current[arrayTempX.current.length - 1].x
            lastY = arrayTempY.current[arrayTempY.current.length - 1].y

            // before undo, add to array

            arrayUndoX.current.push(lastX)
            arrayUndoY.current.push(lastY)

            // remove last point

            arrayTempX.current = []
            arrayTempY.current = []

            drawing.current = {
              start: false,
              active: false,
              offset: false,
              refOffset: false,
              refOffsetStart: false,
              refOffsetEnd: false,
              refStart: false,
              refEnd: false
            }

        }

        // clear fill in case

        drawing.current.fill = ''

        //setStyle(prevState => ({...prevState, fill: ''}))

        // now redraw arrayDraw and tpts

        redraw('undo'); // undo allows for stroke()

    }

  }

  const toolRedo = () => {

    if (arrayUndoX.current.length === 0) {

        alert('Nothing to redo.');

        // ensure that both x and y are clear

        arrayUndoX.current = []
        arrayUndoY.current = []

    } else {

        let lastX = arrayUndoX.current[arrayUndoX.current.length - 1];
        let lastY = arrayUndoY.current[arrayUndoY.current.length - 1];

        // before undo, add to array

        addTemp(lastX,lastY);

        // remove last point

        arrayUndoX.current.pop()
        arrayUndoY.current.pop()

        // reset the start --> for dimensions

        lastX = arrayTempX.current[arrayTempX.current.length - 1]
        lastY = arrayTempY.current[arrayTempY.current.length - 1]

        position.current.startX = lastX
        position.current.startY = lastY

        // this will redraw the last line segment

        if (arrayTempX.current.length === 1) {

            lastX = arrayUndoX.current[arrayUndoX.current.length - 1];
            lastY = arrayUndoY.current[arrayUndoY.current.length - 1];

            // before undo, add to array

            addTemp(lastX,lastY)

            // remove last point

            arrayUndoX.current.pop()
            arrayUndoY.current.pop()

        }

        // now draw

        draw(lastX * props.pdf.scale,lastY * props.pdf.scale)

        drawing.current.start = true

    }

  }

  const addFilterPolygon = () => {    

    if (props.user.drawing < 2) { // also on draw btn, so redundant, but safer
      alert('You do not have the required permission. Contact an admin.')
    } else if (!tool.locate && arrayTempX.current.length === 0) {
      alert('Nothing has been drawn.')
    } else if (!tool.locate && arrayTempX.current.length === 1 && (style.tool === 'planspencil' || style.tool === 'plansmarker' || style.tool === 'planarrow' || style.tool === 'plansslope')) {
      alert('Please provide a second point.')
    } else if (tool.locate && arrayTempX.current.length === 0 && (style.tool === 'planspencil' || style.tool === 'plansmarker' || style.tool === 'planarrow' || style.tool === 'plansslope')) {
      alert('Please provide two points.')
    } else if (tool.locate && arrayTempX.current.length === 1 && isValidated.n === '' & isValidated.e === '' && (style.tool === 'planspencil' || style.tool === 'plansmarker' || style.tool === 'planarrow' || style.tool === 'plansslope')) {
      alert('Please provide a second point.')
    } else if (tool.locate && arrayTempX.current.length === 0 && isValidated.n === '' & isValidated.e === '') {
      alert('Please provide a Northing and Easting.')    
    } else {

      async function waitFor() {

        let arrayTemp = {
          n: [],
          e: []
        }

        // sloppy, but this prevents objects having two coords if drawn first, then located. cleanup later
        if (tool.locate && isValidated.n !== '' && isValidated.e !== '' && style.tool !== 'planspencil' && style.tool !== 'plansmarker' && style.tool !== 'planarrow' && style.tool !== 'plansslope') {

          // do nothing

        } else {

          let coords

          for(let i=0; i < arrayTempX.current.length; i++) {
            let result = await Promise.resolve(i);
            //addTemp() resets x/y with pdfScale, so coords_xy() is redundant thus '* pdfScale'
            coords = coordsXY(arrayTempX.current[i] * props.pdf.scale, arrayTempY.current[i] * props.pdf.scale,props.planInfo,props.pdf.scale,props.mode);

            // arrayTempN.push(coords.n)
            // arrayTempE.push(coords.e)

            arrayTemp.n.push(coords.n)
            arrayTemp.e.push(coords.e)

          }

        }

        if (isValidated.n !== '') arrayTemp.n.push(isValidated.n)
        if (isValidated.e !== '') arrayTemp.e.push(isValidated.e)

        return arrayTemp

      }

      waitFor().then(arrayTemp => props.selectLocate(arrayTemp.n, arrayTemp.e))

    }

  }

  const toolAdd = () => {

    if (style.tool === 'issue') {

      arrayTempX.current.length === 0 ? alert('Nothing has been drawn.') : setIsInput(prevState => ({...prevState, issue: true, add: true}))

    } else if (style.tool === 'test') {

      arrayTempX.current.length === 0 ? alert('Nothing has been drawn.') : setIsInput(prevState => ({...prevState, test: true, add: true}))

    } else if (style.tool === 'lab') {

      arrayTempX.current.length === 0 ? alert('Nothing has been drawn.') : setIsInput(prevState => ({...prevState, lab: true, add: true}))

    } else {

      setTool(prevState => ({...prevState, add: true}))

    }
    
  }
  
  const uploadImage = (id, blob) => {

    let fileData = new FormData()
    fileData.append('filePath', 'images')
    fileData.append('fileName', `image${id}_1.pdf`)
    fileData.append('file', blob)

    fetch('/api/upload', {
      method: 'post',
      body: fileData
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + JSON.stringify(result))

        //console.log('daily uploaded')

      },
      (error) => {

        //alert('Error: could not upload Daily. Contact and admin.')
        catchError(props.filter.jobNumber, props.filter.gradeId, '', 'uploadImage', JSON.stringify(error), props.user.username, props.user.device)
      }
    )

  }

  const addDrawing = () => {

    if (props.user.drawing < 2) { // also on draw btn, so redundant, but safer
      alert('You do not have the required permission. Contact an admin.')
    } else if (!tool.locate && arrayTempX.current.length === 0) {
      alert('Nothing has been drawn.')
    } else if (!tool.locate && arrayTempX.current.length === 1 && (style.tool === 'planspencil' || style.tool === 'plansmarker' || style.tool === 'planarrow' || style.tool === 'plansslope')) {
      alert('Please provide a second point.')
    } else if (tool.locate && arrayTempX.current.length === 0 && (style.tool === 'planspencil' || style.tool === 'plansmarker' || style.tool === 'planarrow' || style.tool === 'plansslope')) {
      alert('Please provide two points.')
    } else if (tool.locate && arrayTempX.current.length === 1 && isValidated.n === '' & isValidated.e === '' && (style.tool === 'planspencil' || style.tool === 'plansmarker' || style.tool === 'planarrow' || style.tool === 'plansslope')) {
      alert('Please provide a second point.')
    } else if (tool.locate && arrayTempX.current.length === 0 && isValidated.n === '' & isValidated.e === '') {
      alert('Please provide a Northing and Easting.')
    } else if (style.presetId === 0 && (isValidated.description === '' || isValidated.description === null)) {
      alert('Custom drawings require a description.')
    } else if (style.tool === 'attitude' && (isValidated.type === '' || isValidated. dip === '' || isValidated.bearing === '')) {
      if (isValidated.type === '') {
        alert('Please provide a type.')
      } else if (isValidated.dip === '') {
        alert('Please provide a dip.')
      } else if (isValidated.bearing === '') {
        alert('Please provide a bearing.')
      } else {
        alert('Error: addDrawing unknown')
      }
    } else if (style.tool === 'point' && isValidated.type === '') {
      alert('Please provide a type.')
    } else if (style.tool === 'plansbe' && isValidated.elev === '') {
      alert('Please provide an elevation.')
    } else if (style.tool === 'planscomment' && isValidated.description === '') {
      alert('Please provide a description.')
    } else {

      async function waitFor() {

        let arrayTemp = {
          n: [],
          e: []
        }

        // sloppy, but this prevents objects having two coords if drawn first, then located. cleanup later
        if (tool.locate && isValidated.n !== '' && isValidated.e !== '' && style.tool !== 'planspencil' && style.tool !== 'plansmarker' && style.tool !== 'planarrow' && style.tool !== 'plansslope') {

          // do nothing

        } else {

          let coords

          for(let i=0; i < arrayTempX.current.length; i++) {
            let result = await Promise.resolve(i);
            //addTemp() resets x/y with pdfScale, so coords_xy() is redundant thus '* pdfScale'
            coords = coordsXY(arrayTempX.current[i] * props.pdf.scale, arrayTempY.current[i] * props.pdf.scale,props.planInfo,props.pdf.scale,props.mode);

            // arrayTempN.push(coords.n)
            // arrayTempE.push(coords.e)

            arrayTemp.n.push(coords.n)
            arrayTemp.e.push(coords.e)

          }

        }

        // why is this here and not in the if statement above?

        if (isValidated.n !== '') arrayTemp.n.push(isValidated.n)
        if (isValidated.e !== '') arrayTemp.e.push(isValidated.e)

        return arrayTemp

      }

      waitFor().then(arrayTemp => {

        if (props.user.bufferData) {

          let id = Math.max(...arrayDraw.current.map(o => o.gradeid === props.filter.gradeId ? o.drawId : 0)) + 1          

          addPending({
            actionId: 1,
            action: 'addDrawing',
            table: 'PlansDrawDrawings',
            jobNumber: props.filter.jobNumber,
            gradeId: props.filter.gradeId,
            entryby: props.user.username,
            entrytime: formatDateTime(new Date()),
            entrylat: '',
            entrylng: '',
            entrydevice: props.user.device,
            modby: '',
            modtime: null,
            modlat: '',
            modlng: '',
            moddevice: '',
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: '',
            lng: '',
            device: props.user.device,
            drawId: id,
            tool: style.tool,
            color: style.color,
            width: style.width,
            line: style.line,
            fill: drawing.current.fill,
            presetId: style.presetId,
            presetName: style.presetName,
            presetTool: style.tool,
            presetColor: style.color,
            presetWidth: style.width,
            presetLine: style.line,
            presetFill: drawing.current.fill,
            description: replaceStr(isValidated.description),
            elev: isValidated.elev,
            type: isValidated.type,
            bearing: isValidated.bearing,
            dip: isValidated.dip,
            x: arrayTempX.current,
            y: arrayTempY.current,
            n: arrayTemp.n,
            e: arrayTemp.e,
            visible: true
          })
          .then(
            (result) => {
              //console.log('result: ' + JSON.stringify(result))

              openAlert('Saved')

              if (!tool.fastDrawing) clearIsValidated()
              props.savedData()
              closeModal()
              toolCancel() // clears data
              //fetchData()

              arrayDraw.current = [
                {
                  syncedID: false,
                  entryby: props.user.username,
                  entrytime: formatDateTime(new Date()),
                  entrylat: '',
                  entrylng: '',
                  entrydevice: props.user.device,
                  drawId: id,
                  tool: style.tool,
                  color: style.color,
                  width: style.width,
                  line: style.line,
                  fill: drawing.current.fill,
                  presetId: style.presetId,
                  presetName: style.presetName,
                  presetTool: style.tool,
                  presetColor: style.color,
                  presetWidth: style.width,
                  presetLine: style.line,
                  presetFill: drawing.current.fill,
                  description: replaceStr(isValidated.description),
                  elev: isValidated.elev,
                  type: isValidated.type,
                  bearing: isValidated.bearing,
                  dip: isValidated.dip,
                  x: arrayTempX.current,
                  y: arrayTempY.current,
                  n: arrayTemp.n,
                  e: arrayTemp.e,
                  visible: true
                },
                ...arrayDraw.current
              ]

              arrayDrawTemp.current = arrayDraw.current

              redraw('refresh')

            },
            (error) => {

              alert('Error: could not save. Contact and admin.')
              catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'addDrawingOffline', JSON.stringify(error), props.user.username, props.user.device)
            }
          )

        } else {

          setIsModal(prevState => ({...prevState, saving: true}))

          getLocation(function(latlng){

            fetch('/api/addDrawing', {
              method: 'post',
              headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                jobNumber: props.filter.jobNumber,
                gradeId: props.filter.gradeId,
                by: props.user.username,
                time: formatDateTime(new Date()),
                lat: latlng.lat,
                lng: latlng.lng,
                device: props.user.device,
                tool: style.tool,
                color: style.color,
                width: style.width,
                line: style.line,
                fill: drawing.current.fill,
                presetId: style.presetId,
                description: replaceStr(isValidated.description),
                elev: isValidated.elev,
                type: isValidated.type,
                bearing: isValidated.bearing,
                dip: isValidated.dip,
                x: arrayTempX.current,
                y: arrayTempY.current,
                n: arrayTemp.n,
                e: arrayTemp.e,
                image: isValidated.image !== '' ? '1' : ''
              })
            })
            .then(res=>res.json())
            .then(
              (result) => {
                //console.log('result: ' + JSON.stringify(result))

                if (result.length > 0 && isValidated.image !== '') {
                  uploadImage(result[0].lastId, isValidated.image)
                } else {
                  alert('Error: id not found. Could not upload.')
                  catchError(isValidated.jobNumber, isValidated.gradeId, props.component, 'uploadImage', 'missing lastId', props.user.username, props.user.device)
                }

                addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'add', '', props.user.username)

                openAlert('Saved')

                if (!tool.fastDrawing) clearIsValidated()
                props.savedData()
                closeModal()
                toolCancel() // clears data
                fetchData()

                setIsModal(prevState => ({...prevState, saving: false}))

              },
              (error) => {
                setIsModal(prevState => ({...prevState, saving: false}))
                catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'addDrawing', JSON.stringify(error), props.user.username, props.user.device)
              }
            )

          })

        }

      })

    }

  }

  const editDrawing = () => {

    //alert('under construction')
    if (props.user.drawing < 2) { // also on draw btn, so redundant, but safer
      alert('You do not have the required permission. Contact an admin.')
    } else if (isEditedInfo.current === false) {
      alert('Nothing has been changed.')
    } else if (isValidatedEdit.presetId === 0 && (isValidatedEdit.description === '' || isValidatedEdit.description === null)) {
      alert('Custom drawings require a description.')
    } else if (isValidatedEdit.tool === 'attitude' && (isValidatedEdit.type === '' || isValidatedEdit. dip === '' || isValidatedEdit.bearing === '')) {
      if (isValidatedEdit.type === '') {
        alert('Please provide a type.')
      } else if (isValidatedEdit.dip === '') {
        alert('Please provide a dip.')
      } else if (isValidatedEdit.bearing === '') {
        alert('Please provide a bearing.')
      } else {
        alert('Error: editDrawing unknown')
      }
    } else if (isValidatedEdit.tool === 'plansbe' && isValidatedEdit.elev === '') {
      alert('Please provide an elevation.')
    } else if (isValidatedEdit.tool === 'planscomment' && isValidatedEdit.description === '') {
      alert('Please provide a description.')
    } else {

      if (props.user.bufferData) {

        addPending({
          actionId: 2,
          action: 'editDrawing',
          table: 'PlansDrawDrawings',
          jobNumber: props.filter.jobNumber,
          gradeId: props.filter.gradeId,
          entryby: isValidatedEdit.entryby,
          entrytime: isValidatedEdit.entrytime,
          entrylat: isValidatedEdit.entrylat,
          entrylng: isValidatedEdit.entrylng,
          entrydevice: isValidatedEdit.entrydevice,
          modby: props.user.username,
          modtime: formatDateTime(new Date()),
          modlat: '',
          modlng: '',
          moddevice: props.user.device,
          by: props.user.username,
          time: formatDateTime(new Date()),
          lat: '',
          lng: '',
          device: props.user.device,
          drawId: isValidatedEdit.id,
          tool: isValidatedEdit.tool,
          color: isValidatedEdit.color,
          width: isValidatedEdit.width,
          line: isValidatedEdit.line,
          fill: isValidatedEdit.fill,
          presetId: isValidatedEdit.presetId,
          presetName: isValidatedEdit.presetName,
          presetTool: isValidatedEdit.tool,
          presetColor: isValidatedEdit.color,
          presetWidth: isValidatedEdit.width,
          presetLine: isValidatedEdit.line,
          presetFill: isValidatedEdit.fill,
          description: replaceStr(isValidatedEdit.description),
          elev: isValidatedEdit.elev,
          type: isValidatedEdit.type,
          bearing: isValidatedEdit.bearing,
          dip: isValidatedEdit.dip,
          n: isValidatedEdit.n,
          e: isValidatedEdit.e,
          visible: true
        })
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            openAlert('Saved')

            clearIsValidatedEdit()
            isEditedInfo.current = false
            closeEdit()
            //fetchData()

            arrayDraw.current = arrayDraw.current.map(data =>
              data.drawId === isValidated.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                modlat: '',
                modlng: '',
                moddevice: props.user.device,
                drawId: isValidatedEdit.id,
                tool: isValidatedEdit.tool,
                color: isValidatedEdit.color,
                width: isValidatedEdit.width,
                line: isValidatedEdit.line,
                fill: isValidatedEdit.fill,
                presetId: isValidatedEdit.presetId,
                presetName: isValidatedEdit.presetName,
                presetTool: isValidatedEdit.tool,
                presetColor: isValidatedEdit.color,
                presetWidth: isValidatedEdit.width,
                presetLine: isValidatedEdit.line,
                presetFill: isValidatedEdit.fill,
                description: replaceStr(isValidatedEdit.description),
                elev: isValidatedEdit.elev,
                type: isValidatedEdit.type,
                bearing: isValidatedEdit.bearing,
                dip: isValidatedEdit.dip,
                n: isValidatedEdit.n,
                e: isValidatedEdit.e,
                visible: true
              } :
              data
            )

            arrayDrawTemp.current = arrayDraw.current

            redraw('refresh')

          },
          (error) => {

            alert('Error: could not save. Contact and admin.')
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'editDrawingOffline', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      } else {

        setIsModal(prevState => ({...prevState, saving: true}))

        getLocation(function(latlng){

          fetch('/api/editDrawing', {
            method: 'post',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              by: props.user.username,
              time: formatDateTime(new Date()),
              lat: latlng.lat,
              lng: latlng.lng,
              device: props.user.device,
              drawId: isValidatedEdit.id,
              tool: isValidatedEdit.tool,
              color: isValidatedEdit.color,
              width: isValidatedEdit.width,
              line: isValidatedEdit.line,
              fill: isValidatedEdit.fill,
              presetId: isValidatedEdit.presetId,
              description: replaceStr(isValidatedEdit.description),
              elev: isValidatedEdit.elev,
              type: isValidatedEdit.type,
              bearing: isValidatedEdit.bearing,
              dip: isValidatedEdit.dip,
              n: isValidatedEdit.n,
              e: isValidatedEdit.e
            })
          })
          .then(res=>res.json())
          .then(
            (result) => {
              //console.log('result: ' + JSON.stringify(result))

              addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'edit', '', props.user.username)

              openAlert('Saved')

              clearIsValidatedEdit()
              isEditedInfo.current = false
              closeEdit()
              fetchData()

              setIsModal(prevState => ({...prevState, saving: false}))

            },
            (error) => {
              setIsModal(prevState => ({...prevState, saving: false}))
              alert('Error: could not edit. Contact and admin.')
              catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'editDrawing', JSON.stringify(error), props.user.username, props.user.device)
            }
          )

        })

      }

    }

  }

  const deleteDrawing = () => {

    if (isModal.warning > 0) {
        alert('You do not have the required permission. Contact an admin.')
    } else {

      if (window.confirm('If you proceed, this will be deleted. Proceed?')) {

        if (props.user.bufferData) {

          addPending({
            actionId: 3,
            action: 'deleteDrawing',
            table: 'PlansDrawDrawings',
            drawId: isValidatedEdit.id
          })
          .then(
            (result) => {
              //console.log('result: ' + JSON.stringify(result))

              openAlert('Deleted')

              clearIsValidatedEdit()
              isEditedInfo.current = false
              closeEdit()
              arrayDraw.current = arrayDraw.current.filter(data => data.drawId !== isValidatedEdit.id)
              arrayDrawTemp.current = arrayDraw.current
              redraw('refresh')

            },
            (error) => {

              alert('Error: could not delete Drawing. Contact and admin.')
              catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'deleteDrawingOffline', JSON.stringify(error), props.user.username, props.user.device)
            }
          )

        } else {

          setIsModal(prevState => ({...prevState, saving: true}))

          fetch('/api/deleteDrawing', {
            method: 'post',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              drawId: isValidatedEdit.id
            })
          })
          .then(res=>res.json())
          .then(
            (result) => {
              //console.log('result: ' + JSON.stringify(result))

              addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'delete', '', props.user.username)

              openAlert('Deleted')

              clearIsValidatedEdit()
              isEditedInfo.current = false
              closeEdit()
              arrayDraw.current = arrayDraw.current.filter(data => data.drawId !== isValidatedEdit.id)
              redraw('refresh')

              setIsModal(prevState => ({...prevState, saving: false}))

            },
            (error) => {
              setIsModal(prevState => ({...prevState, saving: false}))
              alert('Error: could not delete Drawing. Contact and admin.')
              catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'deleteDrawing', JSON.stringify(error), props.user.username, props.user.device)
            }
          )

        }

      }

    }

  }

  const addLocation = () => {

    let ctx = props.canvasDraw.current.getContext('2d')
    let xMax = ctx.canvas.width
    let yMax = ctx.canvas.height

    let n = isValidated.n
    let e = isValidated.e

    if (n === null || n === '') {

        alert('Please provide a Northing.');

    } else if (e === null || e === '') {

        alert('Please provide a Easting.');

    } else {

        let coords = coordsNE(n,e, props.planInfo.m0, props.planInfo.m3, props.planInfo.m4, props.planInfo.m5, props.pdf.scale, props.planInfo.angle)
        let mx = coords.x;
        let my = coords.y;

        if (mx > xMax) {

          alert('The Easting coordinate is outside the page boundary.')

        } else if (my > yMax) {

          alert('The Northing coordinate is outside the page boundary.')

        } else if (style.tool === "planspencil" || style.tool === "plansmarker"  || style.tool === "plansarrow"  || style.tool === "plansslope" ) {

            if (drawing.current.offset) {

                setTool(prevState => ({...prevState, offset: false}))
                drawing.current.offset = false
                alert('Offset interfers with this tool and is no longer active.')

            }

            if (position.current.startX === mx && position.current.startY === my) {

                console.log('drawtools_locate: same coords entered')

            }

            else {

                redraw(); // clear previous move

                draw(mx, my, 'locate');

                drawDistance(mx,my,position.current.startX,position.current.startY); // this will handle the distance and angle on the canvas

                addTemp(mx, my);

                // update startX and startY

                position.current.startX = mx
                position.current.startY = my

            }

        }

        else if (
          style.tool === "planscomment" || 
          style.tool === "plansbe" || 
          style.tool === "planssurveypt" || 
          style.tool === "planstext" || 
          style.tool === "attitude" || 
          style.tool === 'point' ||
          style.tool === 'issue' ||
          style.tool === 'test' ||
          style.tool === 'lab' ||
          style.tool === 'presat'
          ) {

            arrayTempX.current = [];
            arrayTempY.current = [];

            redraw(); // clear previous move


            let description = isValidated.description
            let bearing = isValidated.bearing
            let dip = isValidated.dip
            let type = isValidated.type
            let elev = isValidated.elev

            if (description === null || description === '') description = '?'

            if (bearing === null || bearing === '') bearing = 0

            if (dip === null || dip === '') dip = '?'

            if (elev === null || elev === '') elev = '?'

            if (style.tool === 'plansbe') {
                
              drawBottomElevation(mx,my,elev,style.color,style.width)

            } else if (style.tool === 'planssurveypt') {

              drawSurveyPt(mx,my,elev)

            } else if (style.tool === 'planstext') {

              drawText(mx,my,description)

            } else if (style.tool === 'attitude') {
                
              drawAttitude(mx,my,dip,bearing,elev,type,style.color,style.width)

            } else if (style.tool === 'point') {
              
              drawPoint(mx,my,elev,type,style.color,style.width)

            } else if (style.tool === 'issue') {              
              
              plansIssue(mx,my)

            } else if (style.tool === 'test') {              
              
              plansTest(mx,my)

            } else if (style.tool === 'lab') {              
              
              plansLab(mx,my)

            } else if (style.tool === 'presat') {              
              
              plansPresat(mx,my)

            }

            addTemp(mx, my); // add starting pt to tpts

            // hide locate modal and show add modal

            //setTool(prevState => ({...prevState, add: true, locate: false}))

        }

        else if (props.mode === "plansIssue" || props.mode === "plansTest" || props.mode === "plansLab" || props.mode === "plansPresat") {

          // THIS IS TEMP!!!!!!!! I am slowing integrating field tests into plansdraw_tools
          // update: 2023-02-21 --> og thought was to allow adding tests directly in plansDraw, but most likely will not

          redraw() // clear previous move

          switch (props.mode) {
            case 'plansIssue':
              plansIssue(mx,my)
              break
            case 'plansTest':
              plansTest(mx,my)
              break
            case 'plansLab':
              plansLab(mx,my)
              break
            case 'plansPresat':
              plansPresat(mx,my)
              break
            default:
              console.log(`...`)
          }          

          // hide locate modal

          setTool(prevState => ({...prevState, locate: false}))


        }

        else {

            alert('Error: the drawing tool was not identified. Contact an admin.');

        }

        props.changedData() // tracks unsaved if closing window

        drawMove(coords.x,coords.y);

        // update coords on plan for visual

        //setDisplayCoords({n: coords.n, e: coords.e})

        //document.getElementById("planscoordsmsg").innerHTML = 'N: ' + n + '<br>E: ' + e
        document.getElementById("planscoordsmsg").innerHTML = `
          Z: ${Math.round(props.pdf.scale * props.pdf.zoomScale * 100)}%<br>
          N: ${n}<br>
          E: ${e}
        `

        // clear the vals for the next pt

        setIsValidated(prevState => ({...prevState, n: '', e: ''}))

    }

  }

  const toolLayer = () => setTool(prevState => ({...prevState, layer: tool.layer ? false : true}))

  const toolLayerExpand = () => {

    let w = window.innerWidth;

    if (w > 900) {

      document.getElementById("planslayertable").css("width",900);

    } else {

      document.getElementById("planslayertable").css("width", 'calc(100% - 20px)');

    }

    //$(".expand").toggle();
    document.getElementById("planslayerexpand").css("display","none");
    document.getElementById("planslayerreduce").css("display","block");

  }

  const toolLayerReduce = () => {

    document.getElementById("planslayertable").css("width","");
    //$(".expand").css("display","none");
    document.getElementById("planslayerreduce").css("display","none");
    document.getElementById("planslayerexpand").css("display","block");

  }

  // const drawStyleLinePattern = (ctx,pattern) => {
  //
  //   ctx.strokeStyle = "#000000FF";
  //   ctx.lineJoin = "round";
  //   ctx.lineWidth = "100";
  //
  //   ctx.beginPath();
  //   ctx.setLineDash(pattern);
  //   ctx.moveTo(0, 0);
  //   ctx.lineTo(300, 0);
  //   ctx.stroke();
  //
  // }

  const toolFastDrawing = () => {
    if (tool.fastDrawing) {
      clearIsValidated()
      setTool(prevState => ({...prevState, fastDrawing: false}))
    } else {
      setTool(prevState => ({...prevState, fastDrawing: true}))
    }

  }

  const toolScaleDrawings = () => setTool(prevState => ({...prevState, scaleDrawings: tool.scaleDrawings ? false : true}))

  const toolPhoto = () => tool.photo ? setTool(prevState => ({...prevState, photo: false})) : setTool(prevState => ({...prevState, photo: true, draw: false}))

  const startCamera = async () => {

    //console.log('start')
    let canvas = document.getElementById('canvasPhoto')
    let video = document.getElementById('video')
    // let stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false })
    // video.srcObject = stream

    canvas.hidden = true
    video.hidden = false

    setCamera(prevState => ({...prevState, start: false, takePhoto: true}))

    // Start video camera
    navigator.mediaDevices.getUserMedia({
      video: {facingMode: "environment"},
      audio: false
    })
    .then(stream => {
      video.srcObject = stream
      video.onloadedmetadata = function(e) {
        video.play()
        //console.log('streaming')        
        
        let width = 320
        let height = video.videoHeight / (video.videoWidth / width)

        video.setAttribute("width", width);
        video.setAttribute("height", height);
        canvas.setAttribute("width", width);
        canvas.setAttribute("height", height);

      }
    })
    .catch(err => console.log(`startCamera Error: ${err}`))

  }

  const takePhoto = () => {

    let canvas = document.getElementById('canvasPhoto')
    let video = document.getElementById('video')    
    
    setCamera(prevState => ({...prevState, retry: true, takePhoto: false}))

    canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height)
   	//let dataUrl = document.getElementById('canvasPhoto').toDataURL('image/jpeg')

    canvas.toBlob(
      (blob) => {
        
        if (blob === null) {
          alert('Image could not be created')
        } else {  
          setIsValidated(prevState => ({...prevState, image: blob}))
        }

      },
      "image/jpeg",
      1, // JPEG at 100% quality
    )     

    //  const fullQuality = canvas.toDataURL("image/jpeg", 1.0);
    //  // data:image/jpeg;base64,/9j/4AAQSkZJRgABAQ…9oADAMBAAIRAxEAPwD/AD/6AP/Z"
    //  const mediumQuality = canvas.toDataURL("image/jpeg", 0.5);
    //  const lowQuality = canvas.toDataURL("image/jpeg", 0.1);

    let stream = video.srcObject
    let tracks = stream.getTracks()

    tracks.forEach(track => track.stop())
    video.srcObject = null
    canvas.hidden = false
    video.hidden = true

   	// data url of the image
   	//console.log(`image: ${dataUrl}`)

  }

  const savePhoto = () => {

    setTool(prevState => ({...prevState, photo: false}))

    setCamera(prevState => ({...prevState, start: true, takePhoto: false, retry: false}))

  }

  const closePhoto = () => {

    setIsValidated(prevState => ({...prevState, image: ''}))

    setTool(prevState => ({...prevState, photo: false}))

    setCamera(prevState => ({...prevState, start: true, takePhoto: false, retry: false}))

  }

  const toolClose = () => {
    if (isEditedPosition.current) {
      if (window.confirm('You have unsaved edits. Proceed?')) {
        props.closeModal()
      }
    } else {
      props.closeModal()
    }
  }

  // Test and Lab are the same.. best to consolidate them later

  const toolDrawOther = () => {    

    if (props.user.drawing < 2) { // should also be under test, so redundant, but safer
      alert('You do not have the required permission. Contact an admin.')
    } else {
      tool.draw ? setTool(prevState => ({...prevState, draw: false})) : setTool(prevState => ({...prevState, draw: true}))
    }

    if (props.mode === 'plansFilterPolygon') {      

      setStyle(prevState => ({...prevState, presetId: 0, presetName: 'Filter', tool: 'planspencil', color: '#ffa500FF', width: 2.5, line: [5, 5]}))

    }

  }

  const toolDraw = () => {    

    if (props.user.drawing < 2) { // also on addDrawing, so redundant, but safer
      alert('You do not have the required permission. Contact an admin.')
    } else if (style.tool === '' && (style.presetId === null || style.presetId === '')) {

      setTool(prevState => ({...prevState, note: false, photo: false}))

      toolStyle()

    } else {

      tool.draw ? setTool(prevState => ({...prevState, draw: false})) : setTool(prevState => ({...prevState, note: false, photo: false, draw: true}))

    }

  }

  const toolStyle = () => {

    selectPresets()

    setTool(prevState => ({...prevState, style: true}))

  }


//     //========================================
//     //==     drawing utility for canvas     ==
//     //========================================

  // draw a single Pt
  const drawPt = (x, y, s, n, fill) => {

    let ctx = props.canvasDraw.current.getContext('2d')

    ctx.strokeStyle = fill; //red
    ctx.lineJoin = "miter"; //miter (point) round (rounded) bevel (flat)
    ctx.lineWidth = 2;
    ctx.font = "15px Arial";
    ctx.fillstyle = fill;

    ctx.beginPath(); //needed or wont clear

    // console.log(`
    //   x: ${x}
    //   y: ${y}
    //   s: ${s}
    //   x-s: ${x-s}
    //   x+s: ${x+s}
    //   y-s: ${y-s}
    //   y+s: ${y+s}
    // `)

    ctx.moveTo(x,y-s);
    ctx.lineTo(x,y+s);
    ctx.lineTo(x,y);
    ctx.lineTo(x-s,y);
    ctx.lineTo(x+s,y);

    ctx.stroke();

    ctx.fillText(n,x+s,y-s);

  }

  const drawAttitude = (x, y, dip, bearing, elev, type, color, width) => {

    let ctx = props.canvasDraw.current.getContext('2d')
    let s = 30 / sliderScale.current //props.pdf.zoomScale
    let f = 12 / sliderScale.current //props.pdf.zoomScale
    let padding = 5 / sliderScale.current //props.pdf.zoomScale
    let h = f + padding
    let dx, dy

    ctx.font = f + "px Arial"
    ctx.strokeStyle = color
    ctx.fillStyle = color
    ctx.lineJoin = "miter" //miter (point) round (rounded) bevel (flat)
    ctx.lineCap = "miter"
    ctx.lineWidth = width //* props.pdf.scale; //2 * props.pdf.scale;

    let angle = Number(props.planInfo.angle) || 0
    let r = Number(bearing) || 0

    // ok, so given deg => rad, the point of rotation is down and counter-closewise. :[
    // so 180 is added to r, and rad is * -1 to reverse direction and flips everything
    // for r > 360, they appear to plot correctly without adjustment

    r = 90 + angle + r

    let rad = r * Math.PI / 180 * -1

    // r = angle + r
    //
    // let rad = r * Math.PI / 180

    ctx.beginPath(); //needed or wont clear

    ctx.setLineDash([])
    ctx.moveTo(x,y)

    // draw dip direction only if value provided

    //if (dip !== '?') {

      // top point

      // <option value="bedding">Bedding</option>
      // <option value="oBedding">Overturned Bedding</option>
      // <option value="hBedding">Horizontal Bedding</option>
      // <option value="vBedding">Vertical Bedding</option>
      // <option value="joint">Joint</option>
      // <option value="vJoint">Vertical Joint</option>
      // <option value="foliation">Foliation</option>
      // <option value="vFoliation">Vertical Foliation</option>
      // <option value="faultShear">Fault of Shear</option>
      // <option value="vFault">Vertical Fault</option>
      // <option value="SlipSlide">Slip Surface or Slide Plane</option>

      dx = (0)*Math.cos(rad)-(-(s/3))*Math.sin(rad)
      dy = (0)*Math.sin(rad)+(-(s/3))*Math.cos(rad)

      ctx.lineTo(x+dx,y-dy);
      ctx.lineTo(x,y);

    //}

    // left point

    dx = (-(s/3*2))*Math.cos(rad)-(0)*Math.sin(rad)
    dy = (-(s/3*2))*Math.sin(rad)+(0)*Math.cos(rad)

    ctx.lineTo(x+dx,y-dy);
    ctx.lineTo(x,y);

    // right point

    dx = ((s/3*2))*Math.cos(rad)-(0)*Math.sin(rad)
    dy = ((s/3*2))*Math.sin(rad)+(0)*Math.cos(rad)

    ctx.lineTo(x+dx,y-dy);
    ctx.lineTo(x,y);

    ctx.stroke();

//================

// Per Mike, removed

    // let bearingAlt =
    // bearing >= 0 && bearing <= 90 ? `N${bearing}W` :
    // bearing >= 91 && bearing <= 180 ? `N${180-bearing}E` :
    // bearing >= 181 && bearing <= 270 ? `N${bearing-180}W` :
    // bearing >= 271 && bearing <= 360 ? `N${360-bearing}E` : '?'
    //
    // ctx.fillText(`Type: ${type}`,x,y - 4*h);
    // ctx.fillText(`Elev: ${elev}`,x,y - 3*h);
    // ctx.fillText(`\u2220B: ${bearingAlt} (${bearing})`,x,y - 2*h);
    ctx.fillText(`Dip: ${dip}`,x,y - h);

    ctx.fillText(`${type}`,x,y - 2*h)

  }

    const drawComment = (x, y, color) => {

      let h = 18 / sliderScale.current //props.pdf.zoomScale
      let w = 25 / sliderScale.current //props.pdf.zoomScale
      let p = 5 / sliderScale.current //props.pdf.zoomScale // tail? start point
      let ctx = props.canvasDraw.current.getContext('2d')

      ctx.strokeStyle = color; //default black
      ctx.fillStyle = color;
      ctx.lineWidth = 1; // default
      ctx.lineCap = "round";
      ctx.lineJoin = "round";

      ctx.beginPath(); // begin

      ctx.setLineDash([]);
      ctx.moveTo(x, y); // from
      ctx.lineTo(x+p, y-p);
      ctx.lineTo(x+w, y-p);
      ctx.lineTo(x+w, y-p-h);
      ctx.lineTo(x, y-p-h);

      ctx.fill();

      ctx.clearRect(x+p, y-12 / sliderScale.current, 10 / sliderScale.current, 2 / sliderScale.current);
      ctx.clearRect(x+p, y-15 / sliderScale.current, 15 / sliderScale.current, 2 / sliderScale.current);
      ctx.clearRect(x+p, y-18 / sliderScale.current, 15 / sliderScale.current, 2 / sliderScale.current);

      ctx.closePath();
      ctx.stroke();

    }

    // draw a single rect (bottom elevation)

    const drawBottomElevation = (mx, my, elev, color, width) => {

      let ctx = props.canvasDraw.current.getContext('2d')

      let f = 12 / sliderScale.current //props.pdf.zoomScale
      ctx.font = f + "px Arial";
      let padding = 5 / sliderScale.current //props.pdf.zoomScale
      let r = 8 / sliderScale.current //props.pdf.zoomScale

      let w = ctx.measureText(elev).width / sliderScale.current //props.pdf.zoomScale

      ctx.strokeStyle = color
      ctx.fillStyle = color
      ctx.lineJoin = "miter" //miter (point) round (rounded) bevel (flat)
      ctx.lineCap = "miter"
      ctx.lineWidth = width //* props.pdf.scale; //2 * props.pdf.scale;

      ctx.beginPath() //needed or wont clear
      ctx.setLineDash([])

      ctx.arc(mx, my, r, 0, 2 * Math.PI)
      ctx.moveTo(mx,my)
      ctx.lineTo(mx,my+r)
      ctx.lineTo(mx,my-r)
      ctx.lineTo(mx,my)
      ctx.lineTo(mx-r,my)
      ctx.lineTo(mx+r,my)
      ctx.stroke()
      ctx.fillText(elev,mx - w/2,my + 2*r + padding)

    }

    const drawPoint = (mx, my, elev, type, color, width) => {

      let ctx = props.canvasDraw.current.getContext('2d')

      let f = 12 / sliderScale.current //props.pdf.zoomScale
      ctx.font = f + "px Arial";
      let padding = 5 / sliderScale.current //props.pdf.zoomScale
      let r // radius

      let w = ctx.measureText(elev).width;

      ctx.strokeStyle = color;
      ctx.fillStyle = color;
      ctx.lineJoin = "miter"; //miter (point) round (rounded) bevel (flat)
      ctx.lineCap = "miter";
      ctx.lineWidth = width //* props.pdf.scale; //2 * props.pdf.scale;

      ctx.beginPath(); //needed or wont clear
      ctx.setLineDash([]);

      if (type === 'pointOfInterest') {

        r = 10 / sliderScale.current //props.pdf.zoomScale

        ctx.moveTo(mx,my);
        ctx.lineTo(mx,my-r*2);
        ctx.lineTo(mx+r,my-r);
        ctx.lineTo(mx,my-r);
        ctx.fill()
        ctx.stroke();
        ctx.fillText(elev,mx - w/2,my + r + padding)

      } else if (type === 'contact') {

        r = 15 / sliderScale.current //props.pdf.zoomScale

        ctx.rect(mx-r/2, my-r/2, r, r);
        ctx.moveTo(mx,my);
        ctx.lineTo(mx-r/2,my-r/2);
        ctx.lineTo(mx+r/2,my+r/2);
        ctx.lineTo(mx,my);
        ctx.lineTo(mx+r/2,my-r/2);
        ctx.lineTo(mx-r/2,my+r/2);
        ctx.stroke();
        ctx.fillText(elev,mx - w/2,my + r + padding)

      } else { // controlPoint, if no type selected yet, default

        r = 8 / sliderScale.current //props.pdf.zoomScale

        ctx.arc(mx, my, r, 0, 2 * Math.PI);
        ctx.moveTo(mx,my);
        ctx.lineTo(mx,my+r);
        ctx.lineTo(mx,my-r);
        ctx.lineTo(mx,my);
        ctx.lineTo(mx-r,my);
        ctx.lineTo(mx+r,my);
        ctx.stroke();
        ctx.fillText(elev,mx - w/2,my + 2*r + padding)

      }

    }

    const drawTemporary = (mx, my) => {

      let ctx = props.canvasDraw.current.getContext('2d')

      let color = 'gold'
      let width = 2.5
      // let f = 12 / sliderScale.current //props.pdf.zoomScale
      // ctx.font = f + "px Arial";
      // let padding = 5 / sliderScale.current //props.pdf.zoomScale
      let r // radius

      //let w = ctx.measureText(elev).width;

      ctx.strokeStyle = color;
      ctx.fillStyle = color;
      ctx.lineJoin = "miter"; //miter (point) round (rounded) bevel (flat)
      ctx.lineCap = "miter";
      ctx.lineWidth = width //* props.pdf.scale; //2 * props.pdf.scale;

      ctx.beginPath(); //needed or wont clear
      ctx.setLineDash([]);

      r = 10 / sliderScale.current //props.pdf.zoomScale

      ctx.moveTo(mx,my)
      ctx.lineTo(mx,my-r*2)
      ctx.lineTo(mx+r,my-r)
      ctx.lineTo(mx,my-r)
      ctx.fill()
      ctx.stroke()

    }

    // draw a single rect (bottom elevation)
    function drawText(x, y, n, color) {

      let ctx = props.canvasDraw.current.getContext('2d')

      let f = 30 / sliderScale.current //props.pdf.zoomScale
      ctx.font = f + "px Arial"

      let padding = 5 / sliderScale.current //props.pdf.zoomScale

      let w = ctx.measureText(n).width + padding * 2
      let h = f + 5; // for padding

      if (w/2 > x) {

          x = 0;

      } else {

          x = x - w/2;

      }

      if (f/2 > y) {

          y = 0;

      } else {

          y = y - f/2;

      }

      ctx.strokeStyle = color; //black
      ctx.fillStyle = color;
      //ctx.lineJoin = "miter"; //miter (point) round (rounded) bevel (flat)
      //ctx.lineWidth = 2;

      ctx.fillText(n, x + padding, y + h - padding); // 5 for padding

    }

    // draw a survey point
    const drawSurveyPt = (x, y, n, color) => {

      let ctx = props.canvasDraw.current.getContext('2d')

      let f = 15 / sliderScale.current //props.pdf.zoomScale
      ctx.font = f + "px Arial"
      let padding = 5 / sliderScale.current //props.pdf.zoomScale

      let w = ctx.measureText(n).width / sliderScale.current //props.pdf.zoomScale
      let h = 30 * (Math.sqrt(3)/2) / sliderScale.current //props.pdf.zoomScale

      ctx.strokeStyle = "#000000FF"; //black
      ctx.fillStyle = color;
      ctx.lineJoin = "miter"; //miter (point) round (rounded) bevel (flat)
      ctx.lineCap = "miter";
      ctx.lineWidth = 2;

      ctx.beginPath(); //needed or wont clear

      ctx.setLineDash([]);
      ctx.moveTo(x,y);
      ctx.lineTo(x+f,y-h);
      ctx.lineTo(x+f+w,y-h);

      ctx.stroke();

      ctx.fillText(n,x + f,y - h - padding); // the 5 is for padding

    }

    const drawIssue = (x, y, n, c) => {

      //let t = 'XX'
      let h = 18 / sliderScale.current //props.pdf.zoomScale
      let w = 25 / sliderScale.current //props.pdf.zoomScale
      let p = 5 / sliderScale.current //props.pdf.zoomScale // tail? start point
      let f = 12 / sliderScale.current //props.pdf.zoomScale
      let o = 15 / sliderScale.current //props.pdf.zoomScale
      let ctx = props.canvasDraw.current.getContext('2d')

      ctx.font = f + "px Arial"

      let nw = ctx.measureText(n).width

      ctx.strokeStyle = "#000000FF"; //black
      ctx.fillStyle = c !== undefined ? c : 'tomato';
      ctx.lineWidth = 1; // default
      ctx.lineCap = "round";
      ctx.lineJoin = "round";

      ctx.beginPath(); // begin

      ctx.setLineDash([]);
      ctx.moveTo(x, y); // from
      ctx.lineTo(x+p, y-p);
      ctx.lineTo(x+w, y-p);
      ctx.lineTo(x+w, y-p-h);
      ctx.lineTo(x, y-p-h);

      ctx.fill();

      ctx.fillStyle = "white"

      ctx.fillText(n,x+p+(o/2)-nw/2,y-o+f/2)

      // ctx.clearRect(x+p, y-12 / sliderScale.current, 10 / sliderScale.current, 2 / sliderScale.current);
      // ctx.clearRect(x+p, y-15 / sliderScale.current, 15 / sliderScale.current, 2 / sliderScale.current);
      // ctx.clearRect(x+p, y-18 / sliderScale.current, 15 / sliderScale.current, 2 / sliderScale.current);

      // needed?

      //ctx.closePath();
      //ctx.stroke();

    }

    const drawTest = (mx, my, n, el, color) => {

      let ctx = props.canvasDraw.current.getContext('2d')

      let w = 15 / sliderScale.current //props.pdf.zoomScale
      let f = 12 / sliderScale.current //props.pdf.zoomScale
      let offset = w/3;

      let sx = mx - w / 2;
      let sy = my + w / 2 * Math.tan(30*Math.PI/180); //must convert degrees to radians
      let h = w * (Math.sqrt(3)/2);

      ctx.font = f + "px Arial";

      let nw = ctx.measureText(n).width;
      let ew = ctx.measureText(el).width;

      ctx.strokeStyle = color !== undefined ? color : "#000000FF"; //black
      ctx.fillStyle = color !== undefined ? color : "#000000FF"; //black color;
      ctx.lineJoin = "miter"; //miter (point) round (rounded) bevel (flat)
      ctx.lineWidth = 1;

      ctx.beginPath(); //needed or wont clear

      ctx.setLineDash([]);
      ctx.moveTo(sx,sy);
      ctx.lineTo(sx+w,sy);
      ctx.lineTo(sx+w/2,sy-h);

      ctx.fill();

      ctx.fillText(n,sx+w/2 - nw/2,sy - h - offset);
      ctx.fillText(el,sx+w/2 - ew/2,sy + f + offset);

    }

    const drawLab = (mx, my, n, color) => {
      
      let ctx = props.canvasDraw.current.getContext('2d')

      let w = 15 / sliderScale.current //props.pdf.zoomScale
      let f = 12 / sliderScale.current //props.pdf.zoomScale
      let offset = w/3;

      ctx.font = f + "px Arial";

      let nw = ctx.measureText(n).width

      ctx.strokeStyle = color !== undefined ? color : "#000000FF"; //black
      ctx.fillStyle = color !== undefined ? color : 'brown';
      ctx.lineJoin = "miter"; //miter (point) round (rounded) bevel (flat)
      ctx.lineWidth = 1;

      ctx.beginPath(); //needed or wont clear

      ctx.fillRect(mx-w/2, my-w/2, w, w)

      ctx.fillText(n, mx-nw/2,my-w/2 - offset)

    }

    const drawPresat = (mx, my, n, color) => {
      
      let ctx = props.canvasDraw.current.getContext('2d')

      let w = 15 / sliderScale.current //props.pdf.zoomScale
      let f = 12 / sliderScale.current //props.pdf.zoomScale
      let offset = w/3;

      ctx.font = f + "px Arial";

      let nw = ctx.measureText(n).width

      ctx.strokeStyle = color !== undefined ? color : "#000000FF"; //black
      ctx.fillStyle = color !== undefined ? color : 'blue';
      ctx.lineJoin = "miter"; //miter (point) round (rounded) bevel (flat)
      ctx.lineWidth = 1;

      ctx.beginPath(); //needed or wont clear

      ctx.fillRect(mx-w/2, my-w/2, w, w)

      ctx.fillText(n, mx-nw/2,my-w/2 - offset)

    }

    function angle(x1, y1, x2, y2) {

        var dy = y2 - y1;
        var dx = x2 - x1;
        var theta = Math.atan2(dy, dx); // range (-PI, PI]
        theta *= 180 / Math.PI; //* -1; // rads to degs, range (-180, 180] || *= || x *= y  || x = x * y // the -1 reverses the angle
        return theta;
        //leave as range (-180, 180) --> the +/- determines quadrant --> see planscoords()

        // if (theta < 0) theta = 360 + theta; // range [0, 360)
        // return theta;
    }

    function clear() {

      let ctx = props.canvasDraw.current.getContext('2d')

      if (ctx !== null) ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    }

    const events = (mx,my,object) => {

      // ========

      const found = (object, object2, id, i, j) => {        

        i = Number(i)

        // entry can only edit if < 24 hrs and they entered it

        let entryBy =
        object2 === 'draw' ? arrayDraw.current[i].entryby :
        object2 === 'test' ? arrayTest.current[i].entryby :
        object2 === 'lab' ? arrayLab.current[i].entryby :
        object2 === 'issue' ? arrayIssue.current[i].entryby :
        arrayPresat.current[i].entryby

        let entryTime =
        object2 === 'draw' ? arrayDraw.current[i].entrytime :
        object2 === 'test' ? arrayTest.current[i].entrytime :
        object2 === 'lab' ? arrayTest.current[i].entrytime :
        object2 === 'issue' ? arrayIssue.current[i].entryby :
        arrayPresat.current[i].entrytime

        let today = new Date()
        let entry = new Date(entryTime)
        let timeDiff = Math.abs(entry.getTime() - today.getTime())
        let diffDays = timeDiff / (1000 * 3600 * 24) // 7 days?


        // console.log(`
        //   permission: ${props.user.drawing}
        //   entry: ${entry}
        //   timeDiff: ${timeDiff}
        //   diffDays: ${diffDays}
        //   userName: ${props.user.username}
        //   entryBy: ${entryBy}
        // `)

        if (object === 'info') {          

          if (object2 !== 'draw') {            

            viewInfo(i, object2)

          } else {

            selectDrawing(i)

          }

        }

        else if (tool.edit) {

          if ( props.user.drawing < 4 && props.user.username !== entryBy ) {

            alert('Cannot Edit: ' + entryBy + ' is the owner. Contact a Manager.');

          }

          else if ( props.user.drawing < 4 && diffDays > 7 ) {

            alert('Cannot Edit: time to edit has expired (7 days). Contact a Manager.');

          }

          else if ( props.user.drawing > 3 || (props.user.drawing > 1 && diffDays < 7 && props.user.username === entryBy) ) {

            if (tool.movePt) {

              startEdit(object2, id, i, j)

            } else if (tool.insertPt) {

              let coords = coordsXY(mx,my,props.planInfo,props.pdf.scale,props.mode)

              let n = coords.n;
              let e = coords.e;

              arrayDrawTemp.current[i].n.splice(j, 0, n)
              arrayDrawTemp.current[i].e.splice(j, 0, e)

              arrayDrawUpdate.current.push({

                  id: arrayDrawTemp.current[i].drawId,
                  n: arrayDrawTemp.current[i].n,
                  e: arrayDrawTemp.current[i].e

              })

              isEditedPosition.current = true

              scroll(mx,my)

              redraw('edit')

            } else if (tool.deletePt) {

              arrayDrawTemp.current[i].n.splice(j, 1)
              arrayDrawTemp.current[i].e.splice(j, 1)

              arrayDrawUpdate.current.push({

                  id: arrayDrawTemp.current[i].drawId,
                  n: arrayDrawTemp.current[i].n,
                  e: arrayDrawTemp.current[i].e

              })

              isEditedPosition.current = true

              scroll(mx,my)

              redraw('edit')

            }

          } else {
            console.log(`found: cannot edit`)
          }

        }

      }

      // ========

      let ctx = props.canvasDraw.current.getContext('2d')
      let shape; // used in events() ==> stores Path2D();
      let id; // identifies selected object

      let i,j,x,y,f,w,h,p,dy,coords;

      if (props.mode === 'plansdraw') {
          //console.log('events --> plansdraw');
        if (object === 'info' || tool.edit) {
            //console.log('events --> plansdraw--> info || edit')
          for (i=0; i < arrayDraw.current.length; i++) {

            if (arrayDraw.current[i].visible) {

              if (
                arrayDraw.current[i].tool === "planspencil" ||
                arrayDraw.current[i].tool === "plansmarker" ||
                arrayDraw.current[i].tool === "plansarrow" ||
                arrayDraw.current[i].tool === "plansslope"
              ) {

                // build shapes

                shape = new Path2D()

                ctx.lineWidth = 15 //Number(arrayDraw.current[i].width) + 10

                if (object === 'info') {
                  //console.log(`length: ${arrayDraw.current[i].n.length}`)
                  for (j=0; j < arrayDraw.current[i].n.length; j++) {

                    coords = coordsNE(Number(arrayDraw.current[i].n[j]),Number(arrayDraw.current[i].e[j]), props.planInfo.m0, props.planInfo.m3, props.planInfo.m4, props.planInfo.m5, props.pdf.scale, props.planInfo.angle);
                    x = coords.x;
                    y = coords.y;

                    //ctx.lineWidth = Number(arrayDraw.current[i].width)

                    j === 0 ? shape.moveTo(x, y) : shape.lineTo(x, y)
                    //j === 0 ? ctx.moveTo(x, y) : ctx.lineTo(x, y)


                  }

                  //ctx.stroke(shape)

                  // rather than break after first object, loop through all and if multi
                  // then open list of found, which can then be select. also expand width of object, ie lines

                  // shape clicked?
                  if (ctx.isPointInStroke(shape, mx, my) || ctx.isPointInPath(shape, mx, my) && arrayDraw.current[i].fill === 'solid') {                    
                    found(object, 'draw', arrayDraw.current[i].drawId, i)
                    break
                  }

                } else if (tool.edit && tool.insertPt) {

                  let endX, endY, startX, startY

                  for (j=0; j < arrayDraw.current[i].n.length; j++) {

                    if (j !== 0) { // start at second point

                      // shape is per object, path is per line segment

                      let path = new Path2D()

                      if (j === 1) {

                        coords = coordsNE(Number(arrayDraw.current[i].n[0]),Number(arrayDraw.current[i].e[0]), props.planInfo.m0, props.planInfo.m3, props.planInfo.m4, props.planInfo.m5, props.pdf.scale, props.planInfo.angle);
                        startX = coords.x
                        startY = coords.y

                        coords = coordsNE(Number(arrayDraw.current[i].n[j]),Number(arrayDraw.current[i].e[j]), props.planInfo.m0, props.planInfo.m3, props.planInfo.m4, props.planInfo.m5, props.pdf.scale, props.planInfo.angle);
                        endX = coords.x
                        endY = coords.y

                      } else {

                        startX = endX
                        startY = endY

                        coords = coordsNE(Number(arrayDraw.current[i].n[j]),Number(arrayDraw.current[i].e[j]), props.planInfo.m0, props.planInfo.m3, props.planInfo.m4, props.planInfo.m5, props.pdf.scale, props.planInfo.angle);
                        endX = coords.x
                        endY = coords.y

                      }

                      path.moveTo(startX, startY)
                      path.lineTo(endX, endY)                      

                      // shape clicked?
                      if (ctx.isPointInStroke(path, mx, my)) {
                        found(object, 'draw', arrayDraw.current[i].drawId, i, j)
                        break
                      }

                    }

                  }

                } else if (tool.edit) {

                  for (j=0; j < arrayDraw.current[i].n.length; j++) {

                    coords = coordsNE(Number(arrayDraw.current[i].n[j]),Number(arrayDraw.current[i].e[j]), props.planInfo.m0, props.planInfo.m3, props.planInfo.m4, props.planInfo.m5, props.pdf.scale, props.planInfo.angle);
                    x = coords.x
                    y = coords.y

                    w = Number(arrayDraw.current[i].width)

                    shape.rect(x - (w+10)/2, y - (w+10)/2, w+10, w+10)

                    // shape clicked?
                    if (ctx.isPointInStroke(shape, mx, my) || ctx.isPointInPath(shape, mx, my)) {
                      
                      found(object, 'draw', arrayDraw.current[i].drawId, i, j)
                      break
                    }

                  }

                }

              } else if (
                arrayDraw.current[i].tool === 'planscomment' ||
                arrayDraw.current[i].tool === 'plansbe' ||
                arrayDraw.current[i].tool === 'planssurveypt' ||
                arrayDraw.current[i].tool === 'planstext' ||
                arrayDraw.current[i].tool === 'attitude' ||
                arrayDraw.current[i].tool === 'point'
              ) {

                shape = new Path2D();

                coords = coordsNE(Number(arrayDraw.current[i].n),Number(arrayDraw.current[i].e), props.planInfo.m0, props.planInfo.m3, props.planInfo.m4, props.planInfo.m5, props.pdf.scale, props.planInfo.angle);
                x = coords.x;
                y = coords.y;

                // build shape

                if (arrayDraw.current[i].tool === 'planscomment') {

                  h = 18  * props.pdf.scale;
                  w = 25  * props.pdf.scale;
                  p = 5 * props.pdf.scale;

                  shape.moveTo(x, y);
                  shape.lineTo(x+p, y-p);
                  shape.lineTo(x+w, y-p);
                  shape.lineTo(x+w, y-p-h);
                  shape.lineTo(x, y-p-h);
                  shape.lineTo(x, y);

                } else {

                  f = 15;
                  let t = (ctx.measureText(arrayDraw.current[i].description).width + 10) * props.pdf.scale; // 10 for padding
                  h = (f + 5) * props.pdf.scale; // for padding

                  if (arrayDraw.current[i].tool === 'planstext') {

                    shape.rect(x-t/2, y-h/2, t, h);

                  }

                  else if (arrayDraw.current[i].tool === 'planssurveypt') {

                    dy = 30 * (Math.sqrt(3)/2) * props.pdf.scale; // accounts for the '/' shape of the surveypt

                    shape.rect(x + 15 * props.pdf.scale, y - dy - 5 * props.pdf.scale, t, h * -1);

                  } else if (arrayDraw.current[i].tool === 'plansbe' || arrayDraw.current[i].tool === 'point' || arrayDraw.current[i].tool === 'attitude') {

                    // let r = 15

                    // shape.rect(x-r/2, y-r/2, r, r);

                    let w = 15; // * props.pdf.scale;
                    let p = 10

                    shape.rect(x-w/2, y-w/2, w + p, w + p)

                  }

                }

                // shape clicked?
                if (ctx.isPointInPath(shape, mx, my)) {
                  found(object, 'draw', arrayDraw.current[i].drawId, i, 0)
                  break
                }

              }

            }


          }

          // now issues

          console.log('events --> plansdraw--> info || edit --> issues')

          for (i=0; i < arrayIssue.current.length; i++) {

            if (arrayIssue.current[i].visible) {

              shape = new Path2D();

              coords = coordsNE(Number(arrayIssue.current[i].n),Number(arrayIssue.current[i].e), props.planInfo.m0, props.planInfo.m3, props.planInfo.m4, props.planInfo.m5, props.pdf.scale, props.planInfo.angle);
              x = coords.x;
              y = coords.y;

              h = 18  * props.pdf.scale;
              w = 25  * props.pdf.scale;
              p = 5 * props.pdf.scale;

              shape.moveTo(x, y);
              shape.lineTo(x+p, y-p);
              shape.lineTo(x+w, y-p);
              shape.lineTo(x+w, y-p-h);
              shape.lineTo(x, y-p-h);
              shape.lineTo(x, y);

              // shape clicked?
              if (ctx.isPointInPath(shape, mx, my)) {
                found(object, 'issue', arrayIssue.current[i].id, i, 0)
                break
              }

            }

          }

          // now tests

          console.log('events --> plansdraw--> info || edit --> tests')

          for (i=0; i < arrayTest.current.length; i++) {

            if (arrayTest.current[i].visible) {

              shape = new Path2D();

              coords = coordsNE(Number(arrayTest.current[i].n),Number(arrayTest.current[i].e), props.planInfo.m0, props.planInfo.m3, props.planInfo.m4, props.planInfo.m5, props.pdf.scale, props.planInfo.angle);
              x = coords.x;
              y = coords.y;

              // let side = 15 //* props.pdf.scale; //side of triangle
              // let sx = x - side / 2;
              // let sy = y + side / 2 * Math.tan(30*Math.PI/180); //must convert degrees to radians
              // let h = side * (Math.sqrt(3)/2);

              // shape.moveTo(sx,sy);
              // shape.lineTo(sx+side,sy);
              // shape.lineTo(sx+side/2,sy-h);
              // shape.lineTo(sx,sy);

              let w = 15; // * props.pdf.scale;
              let p = 10

              shape.rect(x-w/2, y-w/2, w + p, w + p)

              // shape clicked?
              if (ctx.isPointInPath(shape, mx, my)) {
                found(object, 'test', arrayTest.current[i].id, i, 0)
                break
              }

            }

          }

          // now lab

          for (i=0; i < arrayLab.current.length; i++) {

            if (arrayLab.current[i].visible) {

              shape = new Path2D();

              coords = coordsNE(Number(arrayLab.current[i].n),Number(arrayLab.current[i].e), props.planInfo.m0, props.planInfo.m3, props.planInfo.m4, props.planInfo.m5, props.pdf.scale, props.planInfo.angle);
              x = coords.x;
              y = coords.y;

              let w = 15; // * props.pdf.scale;
              let p = 10

              shape.rect(x-w/2, y-w/2, w + p, w + p)

              // shape clicked?
              if (ctx.isPointInPath(shape, mx, my)) {
                found(object, 'lab', arrayLab.current[i].id, i, 0)
                break
              }

            }

          }

          // now presats

          for (i=0; i < arrayPresat.current.length; i++) {

            if (arrayPresat.current[i].visible) {

              shape = new Path2D();

              coords = coordsNE(Number(arrayPresat.current[i].n),Number(arrayPresat.current[i].e), props.planInfo.m0, props.planInfo.m3, props.planInfo.m4, props.planInfo.m5, props.pdf.scale, props.planInfo.angle);
              x = coords.x;
              y = coords.y;

              let w = 15; // * props.pdf.scale;
              let p = 10

              shape.rect(x-w/2, y-w/2, w + p, w + p)

              // shape clicked?
              if (ctx.isPointInPath(shape, mx, my)) {
                found(object, 'presat', arrayPresat.current[i].id, i, 0)
                break
              }

            }

          }

        }

      }

      ctx.lineWidth = style.width;

    }

    const viewInfo = (i, object) => {

        //plansdrawid = id;

        console.log('viewInfo ' + object)

        if (object === 'draw') {

          //console.log('found draw')

            let today = new Date();
            let entry = new Date(arrayDraw.current[i].entrytime)
            let timeDiff = Math.abs(entry.getTime() - today.getTime())
            let diffDays = timeDiff / (1000 * 3600 * 24) // 7 days?


            if (props.user.userlevel === 'guest') {

                alert('Preset: ' + arrayDraw.current[i].presetName + '\nDescription: ' + arrayDraw.current[i].description + '\nEntry by: ' + arrayDraw.current[i].entryby + ' @ ' + arrayDraw.current[i].entrytime + '\nMod by: ' + arrayDraw.current[i].modby + ' @ ' + arrayDraw.current[i].modtime);

            }

            else if ( props.user.drawing > 3 || (props.user.drawing > 1 && diffDays < 7 && props.user.username === arrayDraw.current[i].entryby) ) { // 1 day (24 hours) as requested by Dave

              alert('Preset: ' + arrayDraw.current[i].presetName + '\nDescription: ' + arrayDraw.current[i].description + '\nEntry by: ' + arrayDraw.current[i].entryby + ' @ ' + arrayDraw.current[i].entrytime + '\nMod by: ' + arrayDraw.current[i].modby + ' @ ' + arrayDraw.current[i].modtime);

            }

            else if (props.user.drawing > 1 && props.user.username !== arrayDraw.current[i].entryby) {

                alert('(!) - Cannot Edit/Delete: ' + arrayDraw.current[i].entryby + ' is the owner. Contact a Manager.\nPreset: ' + arrayDraw.current[i].preset + '\nDescription: ' + arrayDraw.current[i].description + '\nEntry by: ' + arrayDraw.current[i].entryby + ' @ ' + arrayDraw.current[i].entrytime + '\nMod by: ' + arrayDraw.current[i].modby + ' @ ' + arrayDraw.current[i].modtime);

            }

            else if (props.user.drawing > 1 && diffDays > 7 && props.user.username === arrayDraw.current[i].entryby) {

                alert('(!) - Cannot Edit/Delete: Time has elasped (7 days). Contact a Manager.\nPreset: ' + arrayDraw.current[i].preset + '\nDescription: ' + arrayDraw.current[i].description + '\nEntry by: ' + arrayDraw.current[i].entryby + ' @ ' + arrayDraw.current[i].entrytime + '\nMod by: ' + arrayDraw.current[i].modby + ' @ ' + arrayDraw.current[i].modtime);

            } else {

                // console.log('props.user.userlevel: ' + props.user.userlevel);
                // console.log('days: ' + days);
                // console.log('props.user.username: ' + props.user.username);
                // console.log('arrayDraw.current[i].entryby: ' + arrayDraw.current[i].entryby);

                alert('Error: viewInfo userlevel not recognized. Contact an Admin.');

            }

        } else if (object === 'issue') {
          
          editIssue(i)

        } else if (object === 'test') {

          editTest(i)

            // //console.log('found test')

            // let desc = '\nTest No: ' + arrayTest.current[i].pre + arrayTest.current[i].testno + arrayTest.current[i].suf;
            // desc += '\nTest Date: ' + arrayTest.current[i].testdate;
            // desc += '\nTest Type: ' + arrayTest.current[i].testtype;
            // desc += '\nLocation: ' + arrayTest.current[i].location;
            // desc += '\nN: ' + arrayTest.current[i].n + '\nE: ' + arrayTest.current[i].e;
            // desc += '\nEl/D: ' + arrayTest.current[i].elevd;
            // desc += '\nMC: ' + arrayTest.current[i].moistcont + '\nDry Dens: ' + arrayTest.current[i].drydens;
            // desc += '\nCurve ' + arrayTest.current[i].curve + ': ' + arrayTest.current[i].optd + 'pcf @ ' + arrayTest.current[i].optm + '%';
            // desc += '\nRel Comp: ' + arrayTest.current[i].relcomp + '\nReq Comp: ' + arrayTest.current[i].reqcomp + '\nP/F: ' + arrayTest.current[i].passfail;
            // desc += '\nEntry by: ' + arrayTest.current[i].entryby + ' @ ' + arrayTest.current[i].entrytime + '\nMod by: ' + arrayTest.current[i].modby + ' @ ' + arrayTest.current[i].modtime;

            // alert(desc);

        } else if (object === 'lab') {

          editLab(i)

            // //console.log('found test')

            // let desc = '\nSample No: ' + arrayLab.current[i].sampleno;
            // desc += '\nSample Date: ' + arrayLab.current[i].sampledate;
            // desc += '\nSample Type: ' + arrayLab.current[i].sampletype;
            // desc += '\nLocation: ' + arrayLab.current[i].location;
            // desc += '\nRepresents: ' + arrayLab.current[i].represents;
            // desc += '\nPurpose: ' + arrayLab.current[i].purpose;
            // desc += '\nDescription: ' + arrayLab.current[i].description;
            // desc += '\nColor: ' + arrayLab.current[i].color;
            // desc += '\nN: ' + arrayLab.current[i].n + '\nE: ' + arrayLab.current[i].e;
            // desc += '\nEntry by: ' + arrayLab.current[i].entryby + ' @ ' + arrayLab.current[i].entrytime + '\nMod by: ' + arrayLab.current[i].modby + ' @ ' + arrayLab.current[i].modtime;

            // alert(desc);

          } else if (object === 'presat') {

            editPresat(i)
  
          }

    }



    const startEdit = (object, id, i, j) => {

      drawing.current.edit = true
      editing.current.object = object
      editing.current.id = id
      editing.current.i = i
      editing.current.j = j // used for pencil/marker

    }

    const moveEdit = (mx, my) => {

      if (drawing.current.edit) {

        let i = editing.current.i;
        let j = editing.current.j;

        let temp =
        editing.current.object === 'draw' ? arrayDrawTemp.current :
        editing.current.object === 'issue' ? arrayIssueTemp.current :
        editing.current.object === 'test' ? arrayTestTemp.current :
        editing.current.object === 'lab' ? arrayLabTemp.current :
        arrayPresatTemp.current

        let last = temp[i].n.length-1;
        let n_first = temp[i].n[j];
        let n_last = temp[i].n[last];

        let index;

        let xy = snap(mx,my);

        mx = xy.x;
        my = xy.y;

        // display coords on plan

        let coords = coordsXY(mx,my,props.planInfo,props.pdf.scale,props.mode)

        let n = coords.n;
        let e = coords.e;

        //document.getElementById("planscoordsmsg").innerHTML = 'N: ' + n + '<br>E: ' + e
        document.getElementById("planscoordsmsg").innerHTML = `
          Z: ${Math.round(props.pdf.scale * props.pdf.zoomScale * 100)}%<br>
          N: ${n}<br>
          E: ${e}
        `

        function drawUpdate() {

            let updates =
            editing.current.object === 'draw' ? arrayDrawUpdate.current :
            editing.current.object === 'issue' ? arrayIssueUpdate.current :
            editing.current.object === 'test' ? arrayTestUpdate.current :
            editing.current.object === 'lab' ? arrayLabUpdate.current :
            arrayPresatUpdate.current

            if (updates.find(({ id }) => id === editing.current.id)) {

                index = updates.findIndex( update => update.id === editing.current.id );
                updates[index].n = temp[i].n;
                updates[index].e = temp[i].e;

            }

            else {

                updates.push({

                    id: editing.current.id,
                    n: temp[i].n,
                    e: temp[i].e

                });

            }
            
            //console.log('updates: ' + JSON.stringify(updates));

            editing.current.object === 'draw' ? arrayDrawTemp.current = temp :
            editing.current.object === 'issue' ? arrayIssueTemp.current = temp :
            editing.current.object === 'test' ? arrayTestTemp.current = temp :
            editing.current.object === 'lab' ? arrayLabTemp.current = temp :
            arrayPresatTemp.current = temp

            editing.current.object === 'draw' ? arrayDrawUpdate.current = updates :
            editing.current.object === 'issue' ? arrayIssueUpdate.current = updates :
            editing.current.object === 'test' ? arrayTestUpdate.current = updates :
            editing.current.object === 'lab' ? arrayLabUpdate.current = updates :
            arrayPresatUpdate.current = updates

            //console.log('draw: ' + JSON.stringify(arrayDrawUpdate.current));
            // console.log('tests: ' + JSON.stringify(arrayTestUpdate.current));

        }

        //console.log(`editing.current.object`, editing.current.object)

        // update
        if (
          editing.current.object === 'issue' ||
          editing.current.object === 'test' || 
          editing.current.object === 'lab' ||
          editing.current.object === 'presat'
        ) {

            temp[i].n = n;
            temp[i].e = e;

            drawUpdate();

        } else if ((j === 0 && n_first === n_last) || (j === last && n_first === n_last)) {

            temp[i].n[j] = n;
            temp[i].e[j] = e;

            temp[i].n[last] = n;
            temp[i].e[last] = e;

            drawUpdate();

        }

        else {

            temp[i].n[j] = n;
            temp[i].e[j] = e;

            drawUpdate();

        }

        isEditedPosition.current = true

        scroll(mx,my);

        redraw('edit');

      }

    }

    function endEdit() {

      drawing.current.edit = false;
      editing.current.object = null;
      editing.current.id = null;
      editing.current.i = null;
      editing.current.j = null;
      //console.log('endEdit');

    }

    const offsetPtEdit = async () => {

      // clear previous preview

      arrayDrawUpdate.current = []
      arrayTestUpdate.current = []
      arrayLabUpdate.current = []

      let offsetE = Number(offsetPt.current.e)
      let offsetN = Number(offsetPt.current.n)

      for (let i=0; i < arrayDrawTemp.current.length; i++) {

        //console.log(`before ${i}`)
        let result = await Promise.resolve(i)

        if (arrayDrawTemp.current[i].visible) {

          for (let j=0; j < arrayDrawTemp.current[i].e.length; j++) {
            //console.log(`before e ${j}`)
            let result = await Promise.resolve(j);

            arrayDrawTemp.current[i].e[j] = offsetE + Number(arrayDrawTemp.current[i].e[j])
            //console.log(`after e ${j}`)
          }

          //console.log(`after e loop`)

          for (let j=0; j < arrayDrawTemp.current[i].n.length; j++) {
            //console.log(`before n ${j}`)
            let result = await Promise.resolve(j);

            arrayDrawTemp.current[i].n[j] = offsetN + Number(arrayDrawTemp.current[i].n[j])
            //console.log(`after n ${j}`)
          }

          //console.log(`after n loop`)

          arrayDrawUpdate.current.push({

            id: arrayDrawTemp.current[i].drawId,
            n: arrayDrawTemp.current[i].n,
            e: arrayDrawTemp.current[i].e

          })

        }

        //console.log(`after ${i}`)

      }

      // update issues

      for (let i=0; i < arrayIssueTemp.current.length; i++) {

        //console.log(`before Issue ${i}`)
        let result = await Promise.resolve(i)

        if (arrayIssueTemp.current[i].visible) {

          arrayIssueTemp.current[i].e = offsetE + Number(arrayIssueTemp.current[i].e)
          arrayIssueTemp.current[i].n = offsetN + Number(arrayIssueTemp.current[i].n)

          arrayIssueUpdate.current.push({

            id: arrayIssueTemp.current[i].id,
            n: arrayIssueTemp.current[i].n,
            e: arrayIssueTemp.current[i].e

          })

        }

        //console.log(`after Issue  ${i}`)

      }

      // update tests

      for (let i=0; i < arrayTestTemp.current.length; i++) {

        //console.log(`before test ${i}`)
        let result = await Promise.resolve(i)

        if (arrayTestTemp.current[i].visible) {

          arrayTestTemp.current[i].e = offsetE + Number(arrayTestTemp.current[i].e)
          arrayTestTemp.current[i].n = offsetN + Number(arrayTestTemp.current[i].n)

          arrayTestUpdate.current.push({

            id: arrayTestTemp.current[i].id,
            n: arrayTestTemp.current[i].n,
            e: arrayTestTemp.current[i].e

          })

        }

        //console.log(`after test  ${i}`)

      }

      // update lab

      for (let i=0; i < arrayLabTemp.current.length; i++) {

        //console.log(`before lab ${i}`)
        let result = await Promise.resolve(i)

        if (arrayLabTemp.current[i].visible) {

          arrayLabTemp.current[i].e = offsetE + Number(arrayLabTemp.current[i].e)
          arrayLabTemp.current[i].n = offsetN + Number(arrayLabTemp.current[i].n)

          arrayLabUpdate.current.push({

            id: arrayLabTemp.current[i].id,
            n: arrayLabTemp.current[i].n,
            e: arrayLabTemp.current[i].e

          })

        }

        //console.log(`after lab ${i}`)

      }

      // update presat

      for (let i=0; i < arrayPresatTemp.current.length; i++) {

        //console.log(`before Presat ${i}`)
        let result = await Promise.resolve(i)

        if (arrayPresatTemp.current[i].visible) {

          arrayPresatTemp.current[i].e = offsetE + Number(arrayPresatTemp.current[i].e)
          arrayPresatTemp.current[i].n = offsetN + Number(arrayPresatTemp.current[i].n)

          arrayPresatUpdate.current.push({

            id: arrayPresatTemp.current[i].id,
            n: arrayPresatTemp.current[i].n,
            e: arrayPresatTemp.current[i].e

          })

        }

        //console.log(`after lab ${i}`)

      }

      //console.log(`finished looping`)

      redraw('edit')

      return true

    }

    function mInfo(e) {

      let canvas = props.canvasDraw.current
      let bd = canvas.getBoundingClientRect();
      let mx = parseInt(e.pageX - bd.x);
      let my = parseInt(e.pageY - bd.y);

      // if (tool.draw || props.mode === 'planscoords') {
      //
      //   if (style.tool === 'planspencil' || style.tool === 'plansmarker') e.preventDefault()
      //   start(mx,my)
      //
      // } else if (tool.edit || tool.info) {

        //alert('dbl clicked')

        events(mx,my,'info')

      //}

    }

    const mstart = (e) => {

      //console.log('mstart');

      let canvas = props.canvasDraw.current
      let bd = canvas.getBoundingClientRect();
      let mx = parseInt(e.pageX - bd.x);
      let my = parseInt(e.pageY - bd.y);

      if (props.mode === 'plansmanage') {

        start(mx,my)

      } else if (tool.draw) { //  || props.mode === 'planscoords' // causing issues with scrool

        if (style.tool === 'planspencil' || style.tool === 'plansmarker') e.preventDefault()
        start(mx,my)

      } else if (tool.edit) {

        events(mx,my)

      } else {

        displayPosition(mx,my)

      }

    }

    const mmove = (e) => { //  || props.mode === 'planscoords' // causing issues with scrool

      let canvas = props.canvasDraw.current
      let bd = canvas.getBoundingClientRect();
      let mx = parseInt(e.pageX - bd.x);
      let my = parseInt(e.pageY - bd.y);

      if (tool.draw || props.mode === 'plansmanage') {

        e.preventDefault()
        move(mx,my)

      } else if (tool.edit) {

        e.preventDefault()
        moveEdit(mx,my)

      } else {

        displayPosition(mx,my)

      }

    }

    const mend = (e) => {

      //console.log('mend');
      let canvas = props.canvasDraw.current
      let bd = canvas.getBoundingClientRect();
      let px = e.pageX;
      let py = e.pageY;
      let mx = parseInt(px - bd.x);
      let my = parseInt(py - bd.y);

      if (tool.draw || props.mode === 'plansmanage') { //  || props.mode === 'planscoords' // causing issues with scrool

        end(mx,my,px,py);

      } else if (tool.edit) {

        endEdit()

      } else {

        displayPosition(mx,my)

      }

    }

    function tstart(e) {

      let canvas = props.canvasDraw.current
      let bd = canvas.getBoundingClientRect();
      let mx = parseInt(e.touches[0].pageX - bd.x);
      let my = parseInt(e.touches[0].pageY - bd.y);


      if (tool.draw) { //  || props.mode === 'planscoords' // causing issues with scrool

        start(mx,my)

      } else if (tool.edit) {

        e.stopPropagation(); // why is this needed? wasnt need for mstart....

        events(mx,my)

      } else {

        displayPosition(mx,my)

      }

    }

    const tmove = (e) => {

      let canvas = props.canvasDraw.current
      let bd = canvas.getBoundingClientRect()
      let mx = parseInt(e.touches[0].pageX - bd.x)
      let my = parseInt(e.touches[0].pageY - bd.y)

      if (tool.draw) { //  || props.mode === 'planscoords' // causing issues with scrool

        e.stopPropagation() // needed?
        e.preventDefault()

        move(mx,my)

      } else if (tool.edit) {

        e.stopPropagation() // needed?
        e.preventDefault()

        moveEdit(mx,my)

      } else {

        displayPosition(mx,my)

      }

    }

    function tend(e) {

      let canvas = props.canvasDraw.current
      let bd = canvas.getBoundingClientRect();
      let px = e.changedTouches[0].pageX;
      let py = e.changedTouches[0].pageY;
      let mx = parseInt(px - bd.x);
      let my = parseInt(py - bd.y);

      if (tool.draw) { //  || props.mode === 'planscoords' // causing issues with scrool

        end(mx,my,px,py);

      } else if (tool.edit) {

        endEdit()

      } else {

        displayPosition(mx,my)

      }

    }

    // handle mousedown events
    const start = (mx,my) => {

      let p,xy

      if (props.mode === 'plansmanage') {

          // test each rect to see if mouse is inside

          console.log(`start plansManage`)

          position.current.drag = false;

          for (let i = 0; i < pts.current.length; i++) {

              p = pts.current[i];

              if (mx > (p.x - p.s) && mx < (p.x + p.s) && my > (p.y-p.s) && my < (p.y + p.s)) {

                  // if yes, set that pts isDragging=true

                  position.current.drag = true;
                  p.isDragging = true;

              }
          }

          // save the current mouse position

          position.current.startX = mx
          position.current.startY = my

      }

      else if (props.mode === 'plansIssue' || props.mode === 'plansTest' || props.mode === 'plansLab' || props.mode === 'plansPresat') {

        snapBuildArray(); // build array for snap

        position.current.originalX = mx
        position.current.originalY = my // set here in case drawing begins in scrolling zone

        xy = draw(mx,my); // this will connect each point

      }

      else if (props.mode === 'plansdraw' || props.mode === 'plansFilterPolygon') {

        if (tool.draw) {

          if ( (style.tool !== '' && tool.offset && tool.ref && !drawing.current.refOffsetEnd) || (style.tool !== '' && !tool.offset && tool.ref && !drawing.current.refStart) ) {

            //console.log('start ref: ' + tool.ref)
            //console.log('start ref_start: ' + drawing.current.refStart)

            snapBuildArray(); // build array for snap

            position.current.originalX = mx
            position.current.originalY = my // set here in case drawing begins in scrolling zone

            xy = drawRef(mx,my); // this will connect each point

            if (!tool.offset) {

              addTemp(xy.x, xy.y)

              drawing.current.refStart = true

              console.log('start ref_start2: ' + drawing.current.refStart)
            }

            else {

              drawing.current.refOffset = true

              //console.log('start drawing.current.refOffset: ' + drawing.current.refOffset)

            }

            //console.log('start arrayX: ' + arrayTempX.current + ', arrayY: ' + arrayTempY.current)

          }

          else if (
            style.tool === 'planspencil' || 
            style.tool === 'plansmarker' || 
            style.tool === 'plansarrow' || 
            style.tool === 'plansslope'
          ) {

            if (!drawing.current.start ) {

              //console.log('start before draw')

              snapBuildArray(); // build array for snap

              position.current.originalX = mx
              position.current.originalY = my // set here in case drawing begins in scrolling zone

              xy = draw(mx,my); // this will connect each point

              if (!tool.offset && !tool.ref) {addTemp(xy.x, xy.y);};

            } else {
              displayPosition(mx,my)
            }

            // indicate a new line segment is being actively drawn

            drawing.current.active = true

          }

          else if (
            style.tool === 'planscomment' || 
            style.tool === 'plansbe' || 
            style.tool === 'planssurveypt' || 
            style.tool === 'planstext' || 
            style.tool === 'attitude' || 
            style.tool === 'point' ||
            style.tool === 'issue' ||
            style.tool === 'test' ||
            style.tool === 'lab' ||
            style.tool === 'presat' ||
            style.tool === 'temporary'
          ) {

            snapBuildArray(); // build array for snap

            position.current.originalX = mx
            position.current.originalY = my // set here in case drawing begins in scrolling zone

            xy = draw(mx,my); // this will connect each point
            // dont need xy here
          }

        }

      }

    }

    // handle mouse moves
    const move = (mx,my) => {

      //console.log(`x: ${positionRef.current}`)
      //console.log(`mx: ${mx}, my: ${my}`)
      //console.log(`startX: ${position.current.startX}, startY: ${position.current.startY}`)

      let dx,dy,p,xy,description;

      if (props.mode === 'plansmanage') {

        if (position.current.drag) {

          dx = mx - position.current.startX
          dy = my - position.current.startY

          clear() // clear previous

          for (let i = 0; i < pts.current.length; i++) {

            let p = pts.current[i];

            if (p.isDragging) {

              pts.current[i].x += dx
              pts.current[i].y += dy

              scroll(mx,my);
            }

            drawPt(p.x, p.y, p.s, p.n, p.fill);

          }

          // reset the starting mouse position for the next mousemove
          position.current.startX = mx
          position.current.startY = my

        }

        // assign coords

        let x1,y1,x2,y2,ax1,ay1,ax2,ay2,n1,e1,n2,e2,plan

        n1 = isValidated.n1 === '' ? props.planInfo.n1 : isValidated.n1
        e1 = isValidated.e1 === '' ? props.planInfo.e1 : isValidated.e1
        n2 = isValidated.n2 === '' ? props.planInfo.n2 : isValidated.n2
        e2 = isValidated.e2 === '' ? props.planInfo.e2 : isValidated.e2

        for (let i = 0; i < pts.current.length; i++) {

          if (pts.current[i].id === 'pt1') {

            x1 = pts.current[i].x;
            y1 = pts.current[i].y;

          }

          else if (pts.current[i].id === 'pt2') {

            x2 = pts.current[i].x;
            y2 = pts.current[i].y;

          }

          else if (pts.current[i].id === 'a1') {

            ax1 = pts.current[i].x;
            ay1 = pts.current[i].y;

          }

          else if (pts.current[i].id === 'a2') {

            ax2 = pts.current[i].x;
            ay2 = pts.current[i].y;

          }

        }

        plan = {
          x1: x1,
          y1: y1,
          x2: x2,
          y2: y2,
          n1: n1,
          e1: e1,
          n2: n2,
          e2: e2,
          angle: angle(ax1, ay1, ax2, ay2)
        }

        //console.log(`n1: ${isValidated.n1}, e1: ${isValidated.e1}`)

        //console.log(`plan: ${JSON.stringify(plan)}`)

        let coords = coordsXY(mx,my,plan,props.pdf.scale,props.mode)

        //document.getElementById("planscoordsmsg").innerHTML = 'N: ' + coords.n + '<br>E: ' + coords.e
        document.getElementById("planscoordsmsg").innerHTML = `
          Z: ${Math.round(props.pdf.scale * props.pdf.zoomScale * 100)}%<br>
          N: ${coords.n}<br>
          E: ${coords.e}
        `

      }

      else if (props.mode === "plansIssue" || props.mode === "plansTest" || props.mode === "plansLab" || props.mode === "plansPresat") {

        if (drawing.current.start ) {

          switch (props.mode) {
            case 'plansIssue':
              redraw('issue', props.data.issueNo) // clear previous move
              break
            case 'plansTest':
              redraw('test', props.data.testNo) // clear previous move
              break
            case 'plansLab':
              redraw('lab', props.data.sampleNo) // clear previous move
              break
            case 'plansPresat':
              redraw('presat', props.data.id) // clear previous move
              break
            default:
              console.log(`...`)
          }          

          xy = draw(mx,my); // this will connect each point

          scroll(mx,my);

        }

      }      

      else if (props.mode === 'plansdraw' || props.mode === 'plansFilterPolygon') {

        if (tool.draw) {

          if ( (style.tool !== '' && tool.offset && tool.ref && drawing.current.refOffset && !drawing.current.refOffsetEnd) || (style.tool !== '' && !tool.offset && tool.ref && drawing.current.refStart && !drawing.current.refEnd) ) {

            // if (tool.offset) {
            //     console.log('move drawing.current.refOffset: ' + drawing.current.refOffset)
            //     console.log('move drawing.current.refOffset_initial: ' + drawing.current.refOffset_initial)
            //     console.log('move drawing.current.refOffsetStart: ' + drawing.current.refOffsetStart)
            //     console.log('move drawing.current.refOffsetEnd: ' + drawing.current.refOffsetEnd)
            // }
            //
            // else {
            //     console.log('move ref_start: ' + drawing.current.refStart)
            //     console.log('move ref_end: ' + drawing.current.refEnd)
            // }

            redraw(); // clear previous move

            xy = drawRef(mx,my); // this will connect each point

            if ( !tool.ref && tool.offset && !drawing.current.offset ) {
              //console.log('ignore distance')
            } else {
              drawDistance(xy.x,xy.y,position.current.startX,position.current.startY)
            }

            //scroll(xy.x,xy.y);
            scroll(mx,my);

            // console.log('position.current.startX: ' + position.current.startX + ', position.current.startY: ' + position.current.startY)
            // console.log('X: ' + xy.x + ' Y: ' + xy.y)
            // console.log('move arrayX: ' + arrayTempX.current + ', arrayY: ' + arrayTempY.current)

          }

          else if (
            style.tool === 'planspencil' || 
            style.tool === 'plansmarker' || 
            style.tool === 'plansarrow'  || 
            style.tool === 'plansslope'
          ) {

            if (drawing.current.start  && drawing.current.active) {

              //console.log('drawing')

              if (style.style === 'points') {

                redraw(); // clear previous move                

                xy = draw(mx,my); // this will connect each point

                if ( tool.offset && drawing.current.offset === false ) {
                  //console.log('ignore distance')
                } else {
                  drawDistance(xy.x,xy.y,position.current.startX,position.current.startY)
                }

                //scroll(xy.x,xy.y);
                scroll(mx,my);

              }

              else if (style.style === 'freehand') {

                  addTemp(mx, my);

                  draw(mx,my);

                  // save the current mouse position

                  position.current.startX = mx
                  position.current.startY = my

              }

            } else {
              displayPosition(mx,my)
            }

          }

          else if (
            style.tool === 'planscomment' || 
            style.tool === 'plansbe' || 
            style.tool === 'planssurveypt' || 
            style.tool === 'planstext' || 
            style.tool === 'attitude' || 
            style.tool === 'point' ||
            style.tool === 'issue' ||
            style.tool === 'test' ||
            style.tool === 'lab' ||
            style.tool === 'presat' ||
            style.tool === 'temporary'
          ) {

            if (drawing.current.start ) {

              //console.log('move DRAWING')

              redraw(); // clear previous move

              xy = draw(mx,my); // this will connect each point

              scroll(mx,my);

            } else {
              displayPosition(mx,my)
            }

          }

        }

      }

    }

    // handle mouseup events
    const end = (mx,my) => {

      const drawObject = () => {
        //console.log('end plansDrawMode: ' + style.tool)

        let xy = snap(mx,my);

        mx = xy.x;
        my = xy.y;

        displayPosition(mx,my)

        // clear temp for new position

        arrayTempX.current = []
        arrayTempY.current = []

        redraw(); // clear previous move

        // now draw

        // if (style.tool === 'planscomment') {

        //     drawComment(mx,my,style.color); // draw comment
        
        // } else {

          // if object is moved and has a desc already, draw with desc

          //let str = document.getElementById('descriptionplansdraw').val();

          let str = isValidated.description
          let bearing = isValidated.bearing
          let dip = isValidated.dip
          let type = isValidated.type
          let elev = isValidated.elev

          if (str === null || str === '') str = '?'

          if (bearing === null || bearing === '') bearing = 0

          if (dip === null || dip === '') dip = '?'

          if (style.tool === 'planscomment') {

            drawComment(mx,my,style.color); // draw comment

          } else if (style.tool === 'plansbe') {

            drawBottomElevation(mx,my,elev,style.color,style.width)

          } else if (style.tool === 'planssurveypt') {

            drawSurveyPt(mx,my,str); // draw be

          } else if (style.tool === 'planstext') {

            drawText(mx,my,str); // draw be

          } else if (style.tool === 'attitude') {

            drawAttitude(mx,my,dip,bearing,elev,type,style.color,style.width); // draw be

          } else if (style.tool === 'point') {

            drawPoint(mx,my,elev,type,style.color,style.width); // draw be

          } else if (style.tool === 'issue') {

            plansIssue(mx,my)

          } else if (style.tool === 'test') {

            plansTest(mx,my)

          } else if (style.tool === 'lab') {

            plansLab(mx,my)

          } else if (style.tool === 'presat') {

            plansPresat(mx,my)

          }           

        //}

        // temporary handled differently... pts added within function drawTemporary

        if (style.tool === 'temporary') {

          drawTemporary(mx,my)

          snapBuildArray(mx, my); // build array for snap

          arrayTemporary.current.push({x: mx/props.pdf.scale, y: my/props.pdf.scale})

        } else {

          addTemp(mx, my); // add starting pt to tpts          

          props.changedData() // tracks unsaved if closing window

        }

        drawing.current.start = false

      }

      if (props.mode === 'plansmanage') {

          let x1,y1,x2,y2,ax1,ay1,ax2,ay2;
          let str; // used for be and surveypt below

          // clear all the dragging flags
          position.current.drag = false;

          for (let i = 0; i < pts.current.length; i++) {

            pts.current[i].isDragging = false

          }

      } else if (props.mode === "plansIssue" || props.mode === "plansTest" || props.mode === "plansLab" || props.mode === "plansPresat") {
        
        let xy = snap(mx,my)

        switch (props.mode) {
          case 'plansIssue':
            plansIssue(xy.x,xy.y)
            break
          case 'plansTest':
            plansTest(xy.x,xy.y)
            break
          case 'plansLab':
            //console.log(`planMode: ${props.mode}`)
            plansLab(xy.x,xy.y)
            break
          case 'plansPresat':
            //console.log(`planMode: ${props.mode}`)
            plansPresat(xy.x,xy.y)
            break
          default:
            console.log(`...`)
        }
          
        drawing.current.start = false // allows moving test before end()        

      } else if (props.mode === 'plansdraw' || props.mode === 'plansFilterPolygon') {

        if (tool.draw) {

          if ((style.tool !== '' && !tool.offset && tool.ref && !drawing.current.refEnd) || (style.tool !== '' && tool.offset && tool.ref && !drawing.current.refOffsetEnd)) {

            redraw()

            let xy = drawRef(mx,my)

            drawDistance(xy.x,xy.y,position.current.startX,position.current.startY);
            addTemp(xy.x, xy.y)

            position.current.startX = xy.x
            position.current.startY = xy.y

            // console.log('end ref_offset: ' + drawing.current.refOffset)
            // console.log('end ref_offset_start: ' + drawing.current.refOffsetStart)
            // console.log('end ref_offset_end: ' + drawing.current.refOffsetEnd)
            //console.log('end ref_start: ' + drawing.current.refStart)
            //console.log('end ref_end: ' + drawing.current.refEnd)

            if ( !tool.offset ) {

              drawing.current.refEnd = drawing.current.refStart ? true : false
              drawing.current.refStart = true

              // console.log('end ref_start2: ' + drawing.current.refStart)
              // console.log('end ref_end2: ' + drawing.current.refEnd)
            } else {

              if (drawing.current.refOffsetStart) {

                drawing.current.refOffsetEnd = true

              }

              drawing.current.refOffset = false
              drawing.current.refOffsetStart =  true

              // console.log('end ref_offset: ' + drawing.current.refOffset)
              // console.log('end ref_offset_start: ' + drawing.current.refOffsetStart)
              // console.log('end ref_offset_end: ' + drawing.current.refOffsetEnd)

            }

            if ( (!tool.offset && drawing.current.refEnd) || tool.offset && drawing.current.refOffsetEnd) {

              if (
                style.tool === 'planscomment' || 
                style.tool === 'plansbe' || 
                style.tool === 'planssurveypt' || 
                style.tool === 'planstext' || 
                style.tool === 'attitude' || 
                style.tool === 'point' ||
                style.tool === 'issue' ||
                style.tool === 'test' ||
                style.tool === 'lab' ||
                style.tool === 'presat' ||
                style.tool === 'temporary'
              ) {

                drawObject()

                // reset so that you can use ref on additional objects
                // reset on drawing cancel and save

                drawing.current.refStart = false
                drawing.current.refEnd = false
                drawing.current.refOffsetStart = false
                drawing.current.refOffsetEnd = false

              }

              else {

                arrayTempX.current.shift()
                arrayTempY.current.shift()

              }

            }

            //console.log('end arrayX: ' + arrayTempX.current + ', arrayY: ' + arrayTempY.current)

          } else if (
            style.tool === 'planspencil' || 
            style.tool === 'plansmarker' || 
            style.tool === 'plansarrow' || 
            style.tool === 'plansslope'
          ) {

            if (style.style === 'points') {

              if (drawing.current.start) {

                let lastX = arrayTempX.current[arrayTempX.current.length - 1]
                let lastY = arrayTempY.current[arrayTempY.current.length - 1]

                // didnt move from last position.current. if new drawing, added from start(), so prevent adding same x/y if no movement, ie they just clicked

                if (lastX !== mx || lastY !== my) {

                  redraw() // clears arrow/slope if used

                  let xy = draw(mx,my,'end') // this will connect each point

                  drawDistance(xy.x,xy.y,position.current.startX,position.current.startY)
                  addTemp(xy.x, xy.y)

                  position.current.startX = xy.x
                  position.current.startY = xy.y

                }

              }

              drawing.current.active = false;

            }

            else if (style.style === 'freehand') {

                drawing.current.active = false;

            }

             props.changedData() // tracks unsaved if closing window

            // document.getElementById("plansdraw_tools_add").css("display","block");
            // document.getElementById("plansdraw_tools_edit").css("display","none");
            // document.getElementById("plansdraw_tools").css("display", "block");

          } else if (
            style.tool === 'planscomment' || 
            style.tool === 'plansbe' || 
            style.tool === 'planssurveypt' || 
            style.tool === 'planstext' || 
            style.tool === 'attitude' || 
            style.tool === 'point' ||
            style.tool === 'issue' ||
            style.tool === 'test' ||
            style.tool === 'lab' ||
            style.tool === 'presat' ||
            style.tool === 'temporary'
          ) {

            drawObject()

          }

        }

      }

    }

    function scroll(mx,my) {

      // if drawn to an edge, move screen

      let w = window.innerWidth;
      let h = window.innerHeight;

      let x = mx;
      let y = my;

      let dx = x - position.current.originalX; //ox (original) initially set in start()
      let dy = y - position.current.originalY;

      let px = document.getElementById('canvascontainer').scrollLeft;
      let py = document.getElementById('canvascontainer').scrollTop;

      // scroll to

      if (x > px + w - 100 && dx > 0) { // right

        document.getElementById('canvascontainer').scrollLeft += dx/1.5;

      }

      else if (x < px + 100 && dx < 0) { // left

        document.getElementById('canvascontainer').scrollLeft += dx/1.5;

      }

      else if (y < py + 100 && dy < 0) { // top

        document.getElementById('canvascontainer').scrollTop += dy/1.5;

      }

      else if (y > py + h - 100 && dy > 0) { // bottom

        document.getElementById('canvascontainer').scrollTop += dy/1.5;

      }

      position.current.originalX = mx
      position.current.originalY = my

    }

  const addTemp = (x, y) => {

    //(x/pdfScale) handled in function plansdraw() --> coordsXY()
    //x/y will be phased out and this function is used loosely

    arrayTempX.current.push(x/props.pdf.scale)
    arrayTempY.current.push(y/props.pdf.scale)
  }

  const displayPosition = (x,y) => {

    let coords = coordsXY(x,y,props.planInfo,props.pdf.scale,props.mode)

    //document.getElementById("planscoordsmsg").innerHTML = 'N: ' + coords.n + '<br>E: ' + coords.e
    document.getElementById("planscoordsmsg").innerHTML = `
      Z: ${Math.round(props.pdf.scale * props.pdf.zoomScale * 100)}%<br>
      N: ${coords.n}<br>
      E: ${coords.e}
    `

  }

  const onEnter = (e) => {
    
    if (e.key === 'Enter' && tool.draw && tool.fastDrawing) {
      console.log('enter')
      addDrawing()
    }
  }

  // ================= SLIDER ======================

  var thumbsize = 14;

  function drawSlider(slider,splitvalue) {

    /* set function vars */
    var min = slider.querySelector('.min');
    var max = slider.querySelector('.max');
    var lower = slider.querySelector('.lower');
    var upper = slider.querySelector('.upper');
    var legend = slider.querySelector('.legend');
    var thumbsize = parseInt(slider.getAttribute('data-thumbsize'));
    var rangewidth = parseInt(slider.getAttribute('data-rangewidth'));
    var rangemin = parseInt(slider.getAttribute('data-rangemin'));
    var rangemax = parseInt(slider.getAttribute('data-rangemax'));

    /* set min and max attributes */
    min.setAttribute('max',splitvalue);
    max.setAttribute('min',splitvalue);

    /* set css */
    min.style.width = parseInt(thumbsize + ((splitvalue - rangemin)/(rangemax - rangemin))*(rangewidth - (2*thumbsize)))+'px';
    max.style.width = parseInt(thumbsize + ((rangemax - splitvalue)/(rangemax - rangemin))*(rangewidth - (2*thumbsize)))+'px';
    min.style.left = '0px';
    max.style.left = parseInt(min.style.width)+'px';
    //min.style.top = lower.offsetHeight+'px';
    //max.style.top = lower.offsetHeight+'px';
    //legend.style.marginTop = min.offsetHeight+'px';
    //slider.style.height = (lower.offsetHeight + min.offsetHeight)+'px';
    //slider.style.height = (lower.offsetHeight + min.offsetHeight + legend.offsetHeight)+'px';

    /* correct for 1 off at the end */
    if(max.value>(rangemax - 1)) max.setAttribute('data-value',rangemax);

    /* write value and labels */
    max.value = max.getAttribute('data-value');
    min.value = min.getAttribute('data-value');

    //console.log(`max: ${max.value}, min: ${min.value}`)
    //lower.innerHTML = min.getAttribute('data-value');
    //upper.innerHTML = max.getAttribute('data-value');

    //history.current.start = history.current.start + 1
    //history.current.end = history.current.start + 1

    //redraw('slider')

  }

  // ABANDONED TOOL..REMOVE CAREFULLY

  function initSlider(slider) {
    /* set function vars */
    var min = slider.querySelector('.min');
    var max = slider.querySelector('.max');
    var rangemin = parseInt(min.getAttribute('min'));
    var rangemax = parseInt(max.getAttribute('max'));
    var avgvalue = (rangemin + rangemax)/2;
    //var legendnum = slider.getAttribute('data-legendnum');

    /* set data-values */
    min.setAttribute('data-value',rangemin);
    max.setAttribute('data-value',rangemax);

    /* set data vars */
    slider.setAttribute('data-rangemin',rangemin);
    slider.setAttribute('data-rangemax',rangemax);
    slider.setAttribute('data-thumbsize',thumbsize);
    slider.setAttribute('data-rangewidth',slider.offsetWidth);

    /* write labels */
    // var lower = document.createElement('span');
    // var upper = document.createElement('span');
    // lower.classList.add('lower','value');
    // upper.classList.add('upper','value');
    // lower.appendChild(document.createTextNode(rangemin));
    // upper.appendChild(document.createTextNode(rangemax));
    // slider.insertBefore(lower,min.previousElementSibling);
    // slider.insertBefore(upper,min.previousElementSibling);

    /* write legend */
    // var legend = document.createElement('div');
    // legend.classList.add('legend');
    // var legendvalues = [];
    // for (var i = 0; i < legendnum; i++) {
    //     legendvalues[i] = document.createElement('div');
    //     var val = Math.round(rangemin+(i/(legendnum-1))*(rangemax - rangemin));
    //     legendvalues[i].appendChild(document.createTextNode(val));
    //     legend.appendChild(legendvalues[i]);
    //
    // }
    // slider.appendChild(legend);

    /* draw */
    drawSlider(slider,avgvalue);

    /* events */
    min.addEventListener("input", function() {updateSlider(min);});
    max.addEventListener("input", function() {updateSlider(max);});
  }

  function updateSlider(el){

    /* set function vars */
    var slider = el.parentElement;
    var min = slider.querySelector('#min');
    var max = slider.querySelector('#max');
    var minvalue = Math.floor(min.value);
    var maxvalue = Math.floor(max.value);

    /* set inactive values before draw */
    min.setAttribute('data-value',minvalue);
    max.setAttribute('data-value',maxvalue);

    var avgvalue = (minvalue + maxvalue)/2;

    /* draw */
    drawSlider(slider,avgvalue);
  }

  var sliders = document.querySelectorAll('.min-max-slider');
  sliders.forEach( function(slider) {
      initSlider(slider);
  });

  // ===============================================

  let modal = {
    position: 'fixed',
    zIndex: 1,
    left: 0,
    top: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'white',
    overflow: 'auto'
  }

  let styleSelected = '2px solid dodgerblue'

  let listOfPresetTypes = presetTypes.map(type => {

    let listOfPresets = presets.map((preset, i) => {

      let color = (preset.color.substr(0,7) === '#000000') ? 'white' : 'black'

      let style = {
        backgroundColor: preset.color,
        color: color
      }

      if (type.id === preset.typeId) {

        //if (Number(style.presetId) === Number(preset.presetId)) console.log('YAAAAYYY')

        //console.log(`style.presetId: ${style.presetId} presetid: ${preset.presetId}`)

        return (

          <div key={preset.presetId.toString()} style={{display: 'inline-block', border: Number(style.presetId) === Number(preset.presetId) ? styleSelected : 'none'}}>

            <button className='stylePreset' style={style} name='preset' data-i={i} data-id={preset.presetId} data-name={preset.description} onClick={stylePreset}>{preset.description}</button>

          </div>
        )

      }

    })

    return (
      <div>
        <h3>{type.description}</h3>
        {listOfPresets}
      </div>
    )

  })

  let listOfDrawings = null
  let listOfIssues = null
  let listOfTests = null
  let listOfLab = null
  let listOfPresats = null

  if (props.distinctPresets.length > 0) {

    listOfDrawings = arrayDraw.current.map((data, i) => {

      let presetName = data.presetId === 0 ? 'Custom' : data.presetName
      let type = data.type === null ? '' : data.type
      let elev = data.elev === null ? '' : data.elev
      let description = data.description === null ? '' : data.description
      let entryBy = data.entryby === null ? '' : data.entryby
      let entryTime = data.entrytime === null ? '' : data.entrytime
      let preset = props.distinctPresets.find(preset => preset.id === data.presetId)
      if (preset === undefined) console.warn(`listOfDrawing-->preset-->undefined.`)

      // console.log(`
      //   distinctPresets: ${JSON.stringify(props.distinctPresets)}
      //   data.presetName: ${data.presetName}
      // `)

      //if (new Date(entryTime) >= new Date(layer.startDate) && new Date(entryTime) <= new Date(layer.endDate))

      let startDate = layer.startDate === '' ? new Date('01/01/2000') : new Date(layer.startDate)
      let endDate = layer.endDate === '' ? new Date() : new Date(layer.endDate)

      let filter = filterData(data, props.searchLayer)

      if (
        (!layer.issue) &&
        (!layer.failingTest && preset !== undefined && preset.status) &&
        ((tool.history && new Date(entryTime) >= startDate && new Date(entryTime) <= endDate) || !tool.history) &&
        (props.searchLayer === '' || filter)
      ) {
      
        arrayDraw.current[i].visible = true

        let location = data.n !== null && data.e !== null && data.n !== '' && data.e !== '' ? true : false

        return (
          <tr key={data.drawId.toString()} data-i={i} onClick={selectDrawing} onMouseOver={highlightDrawing}>
            <td style={{display: 'none'}}>{i}</td>
            <td><Icon name={location ? 'directions' : 'directions_off'} color={location ? 'dodgerblue' : 'tomato'} i={i} id='draw' onClick={directions} /></td>
            <td><input type="checkbox" data-i={i} data-id='draw' onClick={toggleVisible} defaultChecked /></td>
            <td>{presetName}</td>
            <td>{type}</td>
            <td>{elev}</td>
            <td className='wrapText'>{description}</td>
            <td>{entryBy}</td>
            <td>{entryTime}</td>
          </tr>
        )
      } else {

        arrayDraw.current[i].visible = false

      }

    })

    listOfIssues = arrayIssue.current.map((data, i) => {

      let issueNo = data.issueNo === null ? '' : data.issueNo.toString()
      let description = data.description === null ? '' : data.description
      let issueDate = data.issueDate === null ? '' : data.issueDate
      let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : ''
      //let entryTime = data.entrytime === null ? '' : formatDateTime(data.entrytime)

      //console.log(`issues: ${JSON.stringify(distinctPresets)}`)
      //console.log(`issuesl: ${distinctPresets.length}`)

      let status = props.distinctPresets[props.distinctPresets.length - 6].status      

      let startDate = layer.startDate === '' ? new Date('01/01/2000') : new Date(layer.startDate)
      let endDate = layer.endDate === '' ? new Date() : new Date(layer.endDate)

      let filter = filterData(data, props.searchLayer)

      if (
        status &&      
        (!layer.failingTest || (layer.issue && layer.failingTest)) &&
        ((tool.history && new Date(issueDate) >= startDate && new Date(issueDate) <= endDate) || !tool.history) &&
        (props.searchLayer === '' || filter)
      ) {        

        arrayIssue.current[i].visible = true

        let location = data.n !== null && data.e !== null && data.n !== '' && data.e !== '' ? true : false

        return (
          <tr key={data.id.toString()} data-i={i} onClick={selectIssue} onMouseOver={highlightDrawing}>
              <td style={{display: 'none'}}>{i}</td>
              <td><Icon name={location ? 'directions' : 'directions_off'} color={location ? 'dodgerblue' : 'tomato'} i={i} id='issue' onClick={location ? directions : missingLocation} /></td>
              <td><input type="checkbox" data-i={i} data-id='issue' onClick={toggleVisible} disabled={location ? false : true} defaultChecked /></td>
              <td>Issue</td>
              <td>{issueNo}</td>
              <td></td>
              <td className='wrapText'>{data.resolved == 1 ? `Resolved: ${description}` : data.resolved == 2 ? `Archived: ${description}` : description}</td>
              <td>{entryBy}</td>
              <td>{issueDate}</td>
          </tr>
        )
      } else {

        arrayIssue.current[i].visible = false

      }

    })

    listOfTests = arrayTest.current.map((data, i) => {

      let elevD = data.elevd === null ? '' : data.elevd.toString()
      let pre = data.pre === null ? '' : data.pre
      let number = data.testno === null ? '' : data.testno
      let suf = data.suf === null ? '' : data.suf
      let testNo = `${pre} ${number} ${suf}`
      let testDate = data.testdate === null ? '' : data.testdate
      let passFail = data.passfail === null ? '' : data.passfail === 'P' ? 'pass' : data.passfail === 'F' ? 'fail' : '?'
      let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : data.tech !== null ? data.tech : ''
      //let entryTime = data.entrytime === null ? '' : formatDateTime(data.entrytime)

      let status = props.distinctPresets[props.distinctPresets.length - 5].status

      // this is for failingTest
      let failingTest = null

      if (data.passfail !== 'P') {
        //console.log(`test.passfail: ${test.passfail}`)
        failingTest = arrayTest.current.find(retest => retest.testno === data.testno && retest.passfail === 'P')
        //if (failingTest === undefined) console.warn(`listOfTests-->failingTest-->undefined.`)
      }

      let startDate = layer.startDate === '' ? new Date('01/01/2000') : new Date(layer.startDate)
      let endDate = layer.endDate === '' ? new Date() : new Date(layer.endDate)

      let filter = filterData(data, props.searchLayer)

      if (
        status &&
        (!layer.issue || (layer.issue && (layer.failingTest && failingTest === undefined))) &&
        (!layer.failingTest || (layer.failingTest && failingTest === undefined)) &&
        ((tool.history && new Date(testDate) >= startDate && new Date(testDate) <= endDate) || !tool.history) &&
        (props.searchLayer === '' || filter)
      ) {        

        arrayTest.current[i].visible = true

        let location = data.n !== null && data.e !== null && data.n !== '' && data.e !== '' ? true : false

        return (
          <tr key={data.id.toString()} data-i={i} onClick={selectTest} onMouseOver={highlightDrawing}>
              <td style={{display: 'none'}}>{i}</td>
              <td><Icon name={location ? 'directions' : 'directions_off'} color={location ? 'dodgerblue' : 'tomato'} i={i} id='test' onClick={location ? directions : missingLocation} /></td>
              <td><input type="checkbox" data-i={i} data-id='test' onClick={toggleVisible} disabled={location ? false : true} defaultChecked /></td>
              <td>Test</td>
              <td>{testNo.trim()}</td>
              <td>{elevD}</td>
              <td>{passFail}</td>
              <td>{entryBy}</td>
              <td>{testDate}</td>
          </tr>
        )
      } else {

        arrayTest.current[i].visible = false

      }

    })

    listOfLab = arrayLab.current.map((data, i) => {

      let sampleNo = data.sampleno === null ? '' : data.sampleno.toString()
      //let location = data.location === null ? '' : data.location
      let sampleDate = data.sampledate === null ? '' : data.sampledate
      let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : ''
      let entryTime = data.entrytime === null ? '' : data.entrytime

      let status = props.distinctPresets[props.distinctPresets.length - 4].status      

      let startDate = layer.startDate === '' ? new Date('01/01/2000') : new Date(layer.startDate)
      let endDate = layer.endDate === '' ? new Date() : new Date(layer.endDate)

      let filter = filterData(data, props.searchLayer)

      if (
        status &&
        !layer.issue &&
        !layer.failingTest &&
        ((tool.history && new Date(sampleDate) >= startDate && new Date(sampleDate) <= endDate) || !tool.history) &&
        (props.searchLayer === '' || filter)
      ) {        

        arrayLab.current[i].visible = true

        let location = data.n !== null && data.e !== null && data.n !== '' && data.e !== '' ? true : false

        return (
          <tr key={data.id.toString()} data-i={i} onClick={selectLab} onMouseOver={highlightDrawing}>
              <td style={{display: 'none'}}>{i}</td>
              <td><Icon name={location ? 'directions' : 'directions_off'} color={location ? 'dodgerblue' : 'tomato'} i={i} id='lab' onClick={location ? directions : missingLocation} /></td>
              <td><input type="checkbox" data-i={i} data-id='test' onClick={toggleVisible} disabled={location ? false : true} defaultChecked /></td>
              <td>Lab</td>
              <td>{sampleNo}</td>
              <td></td>
              <td className='wrapText'>{data.location}</td>
              <td>{entryBy}</td>
              <td>{sampleDate}</td>
          </tr>
        )
      } else {

        arrayLab.current[i].visible = false

      }

    })

    listOfPresats = arrayPresat.current.map((data, i) => {

      //let sampleNo = data.sampleno === null ? '' : data.sampleno.toString()
      //let location = data.location === null ? '' : data.location
      let sampleDate = data.sampleDate === null ? '' : data.sampleDate
      let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : ''
      let entryTime = data.entrytime === null ? '' : data.entrytime

      let status = props.distinctPresets[props.distinctPresets.length - 3].status      

      let startDate = layer.startDate === '' ? new Date('01/01/2000') : new Date(layer.startDate)
      let endDate = layer.endDate === '' ? new Date() : new Date(layer.endDate)

      let filter = filterData(data, props.searchLayer)

      if (
        status &&
        !layer.issue &&
        !layer.failingTest &&
        ((tool.history && new Date(sampleDate) >= startDate && new Date(sampleDate) <= endDate) || !tool.history) &&
        (props.searchLayer === '' || filter)
      ) {        

        arrayPresat.current[i].visible = true

        let location = data.n !== null && data.e !== null && data.n !== '' && data.e !== '' ? true : false

        return (
          <tr key={data.id.toString()} data-i={i} onClick={selectPresat} onMouseOver={highlightDrawing}>
            <td style={{display: 'none'}}>{i}</td>
            <td><Icon name={location ? 'directions' : 'directions_off'} color={location ? 'dodgerblue' : 'tomato'} i={i} id='presat' onClick={location ? directions : missingLocation} /></td>
            <td><input type="checkbox" data-i={i} data-id='test' onClick={toggleVisible} disabled={location ? false : true} defaultChecked /></td>
            <td>Presat</td>
            <td></td>
            <td></td>
            <td className='wrapText'>{data.location}, {data.represents}</td>
            <td>{entryBy}</td>
            <td>{sampleDate}</td>
          </tr>
        )
      } else {

        arrayPresat.current[i].visible = false

      }

    })

  }

  //console.log(`test? ${JSON.stringify(props.distinctPresets)}`)
  let listOfDistinctPresets = props.distinctPresets.map((preset, i) => {

    let color, backGroundColor, presetName, border
    
    if (preset.id === 0) {

      color = 'dodgerblue'
      backGroundColor = 'white'
      presetName = preset.presetName
      border = '1px solid dodgerblue'

    } else {

      color = preset.color.substr(0,7) === '#000000' ? 'white' : 'black'
      backGroundColor = preset.color
      presetName = preset.presetName
      border = 'none'

    } 

    let style = {
      backgroundColor: preset.status ? backGroundColor : 'white',
      border: preset.status ? border : '1px solid gray',
      color: preset.status ? color : 'gray',
      textDecoration: preset.status ? 'none' : 'line-through'
    }

    return <button className='stylePreset' style={style} name='preset' data-i={i} data-id={preset.id} onClick={layerPreset}>{presetName}</button>

  })

  let contentLayer = (
    arrayDraw.current.length > 0 || arrayTest.current.length > 0 || arrayLab.current.length > 0 ?
    <div style={{display: 'flex', flexFlow: 'column', width: '100%', height: '100%'}}>

      <div style={{flex: '0 1 auto'}}>

        <div style={{display: 'flex', justifyContent: 'space-between'}}>

          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {props.user.device !== 'mobile' ? <Icon name='close' color='tomato' onClick={closeLayer} title='Close Layers' /> : null}
            <Icon name='history' color={tool.history ? 'dodgerblue' : 'gray'} onClick={toggleHistory} title='View history' />
            <Icon name='announcement' color={layer.issue ? 'tomato' : 'gray'} onClick={layerIssue} title='View issues' />
            <Icon name='warning' color={layer.failingTest ? 'tomato' : 'gray'} onClick={layerFailingTest} title='View failing tests which have no re-test' />
            <div style={{display: 'inline-block'}}>
              <button
                className='stylePreset'
                style={{
                  background: layer.testElev ? 'black' : 'white',
                  border: layer.testElev ? 'none' : '1px solid gray',
                  color: layer.testElev ? 'white' : 'gray'
                }}
                onClick={toggleTestElev}
              >Test Elev
              </button>
            </div>
            <div style={{display: 'inline-block'}}>
              <button
                className='stylePreset'
                style={{
                  background: layer.grading ? 'orange' : 'white',
                  border: layer.grading ? 'none' : '1px solid gray',
                  color: layer.grading ? 'white' : 'gray'
                }}
                onClick={toggleGrading}
              >Grading
              </button>
            </div>
            <div style={{display: 'inline-block'}}>
              <button
                className='stylePreset'
                style={{
                  background: layer.post ? 'dodgerblue' : 'white',
                  border: layer.post ? 'none' : '1px solid gray',
                  color: layer.post ? 'white' : 'gray'
                }}
                onClick={togglePost}
              >Post
              </button>
            </div>
          </div>
          
        </div>

      </div>

      {tool.history ?

        <div style={{display: 'flex', alignItems: 'center'}}>
          <label style={{margin: 10}}>Start</label>
          <input id='startDate' className='input' type="date" name='startDate' onInput={filterDate} defaultValue={layer.startDate} />
          <label style={{margin: 10}}>End</label>
          <input id='endDate' className='input' type="date" name='endDate' onInput={filterDate} defaultValue={layer.endDate} />
          <Icon name='close' color='gray' onClick={clearFilterDate} title='Clear Dates' />
        </div> : null

      }

      <SearchBar search={search} clearSearch={clearSearch} searchValue={props.searchLayer} />

      <div style={{flex: '0 1 auto', justifyContent: 'center'}}>
        {listOfDistinctPresets}
      </div>

      <div style={{flex: '2', justifyContent: 'center', width: '100%', overflow: 'auto', marginTop: 10}}>

        <table>

          <thead>
            <tr>
              <th>Find</th>
              <th>Show</th>
              <th>Preset</th>
              <th>Type</th>
              <th>Elev</th>
              <th>Descrip.</th>
              <th>by</th>
              <th>time</th>
            </tr>
          </thead>

          <tbody>
            {listOfIssues}
            {listOfDrawings}
            {listOfTests}
            {listOfLab}
            {listOfPresats}
          </tbody>

        </table>

      </div>

    </div> :
    <p>No drawings, tests, or lab found.</p>
  )

  // <Tests user={props.user} filter={props.filter} close={closeTest}  planMode={'plansTest'} modal={true} zIndex={5} selectNuke={props.selectNuke} /> : null}
  //{isInput.issue ? <Issue user={props.user} filter={props.filter} close={closeIssue} planMode={'plansIssue'} modal={true} zIndex={5} /> : null}

  const updateFetchedData = () => {}

  const updateFetchedAction = (data) => setFetchedAction(data)

  let styleBtn = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 10,
    border: '2px solid dodgerblue',
    margin: 10,
    width: 100
  }

  return (
    <div>
      {isInput.alert ? <Alert content={isModal.alertContent} close={closeAlert} /> : null}      
      {isInput.issue ?
        <IssueInput                
          close={closeIssue}
          closeAction={closeAction}
          fetchedData={arrayIssue.current}
          fetchedAction={fetchedAction}
          location={coordsXY(arrayTempX.current[0] * props.pdf.scale, arrayTempY.current[0] * props.pdf.scale,props.planInfo,props.pdf.scale,props.mode)}
          updateFetchedData={updateFetchedData}
          updateFetchedAction={updateFetchedAction}
          isModal={isInput}
          user={props.user}
          filter={props.filter}
          planMode={props.planMode}
          component={props.component}
          zIndex={5}
        /> : null
      }
      {isInput.test ? 
        <TestInput                
          close={closeTest} 
          fetchedData={arrayTest.current}
          location={coordsXY(arrayTempX.current[0] * props.pdf.scale, arrayTempY.current[0] * props.pdf.scale,props.planInfo,props.pdf.scale,props.mode)}
          updateFetchedData={updateFetchedData}
          selectNuke={props.selectNuke}
          isModal={isInput}
          user={props.user}
          filter={props.filter}
          planMode={props.planMode}
          zIndex={5}
        /> : null
      }
      {isInput.lab ? 
        <LabInput                
          close={closeLab} 
          fetchedData={arrayLab.current}
          location={coordsXY(arrayTempX.current[0] * props.pdf.scale, arrayTempY.current[0] * props.pdf.scale,props.planInfo,props.pdf.scale,props.mode)}
          updateFetchedData={updateFetchedData}
          isModal={isInput}
          user={props.user}
          filter={props.filter}
          planMode={props.planMode}
          zIndex={5}
        /> : null
      }
      {isInput.presat ? 
        <PresatInput                
          close={closePresat} 
          fetchedData={arrayPresat.current}
          location={coordsXY(arrayTempX.current[0] * props.pdf.scale, arrayTempY.current[0] * props.pdf.scale,props.planInfo,props.pdf.scale,props.mode)}
          updateFetchedData={updateFetchedData}
          isModal={isInput}
          user={props.user}
          filter={props.filter}
          planMode={props.planMode}
          zIndex={5}
        /> : null
      }      
      
      <div className='testing' style={{display: 'flex', width: '100%', height: '100%', zIndex: 4}} onKeyPress={onEnter} tabIndex='0'>        

        { tool.layer ? 
          <div className='testingLayer' style={{maxWidth: props.user.device === 'tablet' ? 600 : 'none'}}>

            {
              tool.layer && props.user.device !== 'mobile' ? contentLayer :
              <Modal closeModal={closeLayer} content={contentLayer} />              
            }

          </div> : null

        }

        { layer.issueDerp ? 
          <div className='testingLayer' style={{maxWidth: props.user.device === 'tablet' ? 600 : 'none'}}>

            <Issue user={props.user} filter={props.filter} close={closeIssue} planMode={'plansIssue'} modal={layer.issue && props.user.device !== 'mobile' ? false : true} zIndex={5} />
           
          </div> : null

        }

        <div id="planscontainer">

          <div id="canvascontainer">

            <canvas id="canvasPlan" className="canvas" ref={props.canvasPlan}></canvas>
            <canvas id="canvasdraw" className="canvas" ref={props.canvasDraw}></canvas>

          </div>

          <div>

            <div className="plansCompass" style={{transform: `rotate(${props.planInfo.angle}deg)`}}>&#x2191;</div>
            <div className="plansCompassN" style={{transform: `rotate(${props.planInfo.angle}deg)`}}>N</div>

            <p id="planscoordsmsg"></p>            

            {props.mode !== 'plansmanage' ?

              <>

                <div className='toolDrawMenu' style={{position: 'absolute', top: 10, left: 10, zIndex: 2}}>

                  <div style={{display: 'inline-block'}}>

                    <Icon name='close' color='tomato' onClick={toolClose} title='Close Plan' />

                    <Icon name='layers' color={tool.layer ? 'dodgerblue' : 'gray'} onClick={toolLayer} title='Toggle Layers' />

                    {tool.edit === 'derp' ?
                      <>
                        <Icon name='clear' color='tomato' onClick={toolEditCancel} title='Cancel Edits' />
                        <Icon name='check_circle' color='dodgerblue' onClick={toolEditAccept} title='Accept Edits' />
                      </> : null
                    }

                    {!tool.edit && props.user.userlevel !== 'guest' ?
                      <>

                        <Icon name='draw' color={tool.draw ? 'dodgerblue' : 'gray'} outline={true} onClick={props.mode === 'plansdraw' ? toolDraw : toolDrawOther} title='Draw' />

                        {
                          (props.mode === 'plansIssue' || style.tool === 'issue') && tool.draw ?
                          <button className='stylePreset' style={{backgroundColor: 'tomato', color: 'white'}} onClick={toolStyle}>Issue</button> :
                          (props.mode === 'plansTest' || style.tool === 'test') && tool.draw ?
                          <button className='stylePreset' style={{backgroundColor: 'black', color: 'white'}} onClick={toolStyle}>Test</button> :
                          (props.mode === 'plansLab' || style.tool === 'lab') && tool.draw ?
                          <button className='stylePreset' style={{backgroundColor: 'brown', color: 'white'}} onClick={toolStyle}>Lab</button> :
                          (props.mode === 'plansPresat' || style.tool === 'presat') && tool.draw ?
                          <button className='stylePreset' style={{backgroundColor: 'blue', color: 'white'}} onClick={toolStyle}>Presat</button> :
                          (style.tool === 'temporary') && tool.draw ?
                          <button className='stylePreset' style={{backgroundColor: 'gold', color: 'black'}} onClick={toolStyle}>Temporary</button> :
                          props.mode === 'plansFilterPolygon' && tool.draw ?
                          <button className='stylePreset' style={{backgroundColor: 'orange', color: 'white'}}>Filter</button> :
                          tool.draw && style.presetId !== '' && style.presetId !== null ?
                          <button className='stylePreset' style={{backgroundColor: style.color, color: style.color.substr(0,7) === '#000000' ? 'white' : 'black'}} onClick={toolStyle}>{style.presetName}</button> : null
                        }

                      </> : null
                    }

                  </div>

                </div>

                <div className='toolDrawMenu' style={{position: 'absolute', top: 50, left: 10, zIndex: 2}}>

                  <div style={{display: 'inline-block'}}>

                    <div><Icon name='zoom_in' onClick={toolZoomIn} title='Zoom in' /></div>

                    <div><Icon name='zoom_out' onClick={toolZoomOut} title='Zoom out' /></div>

                    <div><Icon name='announcement' color={layer.issue ? 'tomato' : 'gray'} onClick={layerIssue} title='View issues' /></div>

                    <div><Icon name='warning' color={layer.failingTest ? 'tomato' : 'gray'} onClick={layerFailingTest} title='View failing tests which have no re-test' /></div>                 

                    <div><Icon name='help_outline' onClick={openHelp} title='Help' /></div>

                    <div><Icon name='more_horiz' onClick={toggleMore} title='More' /></div>

                    {tool.more ?

                      <>                        

                        {props.user.userlevel !== 'guest' ? <div><Icon name='construction' color={tool.edit ? 'dodgerblue' : 'gray'} onClick={toolEdit} title='Edit drawings' /></div> : null}

                        {props.user.userlevel !== 'guest' ? <div><Icon name='add_a_photo' color={tool.photo ? 'dodgerblue' : 'gray'} onClick={toolPhoto} /></div> : null}

                        {props.user.userlevel !== 'guest' ? <div><Icon name='bolt' color={tool.fastDrawing ? 'dodgerblue' : 'gray'} onClick={toolFastDrawing} /></div> : null}

                        <div><Icon name='linear_scale' color={tool.scaleDrawings ? 'dodgerblue' : 'gray'} onClick={toolScaleDrawings} /></div>

                        <div><Icon name='refresh' onClick={toolRefresh} title='Refresh drawings' /></div>

                        <div><Icon name='restore_page' onClick={props.reloadPDF} title='Reload Plan' /></div>

                        {tool.direction === 'hide for now' ?
                          <div>

                            {tool.direction ? <><Icon id='up' name='keyboard_arrow_up' onClick={toolDirection} /><br/></> : null }

                            {tool.direction ? <Icon id='left' name='keyboard_arrow_left' onClick={toolDirection} /> : null }

                            <Icon name='gamepad' onClick={toggleDirection} />

                            {tool.direction ? <><Icon id='right' name='keyboard_arrow_right' onClick={toolDirection} /><br/></> : null }

                            {tool.direction ? <Icon id='down' name='keyboard_arrow_down' onClick={toolDirection} /> : null }

                          </div> : null
                        }

                      </> : null

                    }

                  </div>

                </div>

              </> : null

            }

            {(tool.draw || tool.edit) && props.mode !== 'plansmanage' && props.user.userlevel !== 'guest' ?

              <div className='toolDrawMenu' style={{position: 'absolute', top: 10, right: 10, zIndex: 2}}>

                {tool.draw || tool.edit ?

                  <>

                    <div><Icon name='architecture' color={tool.ref ? 'dodgerblue' : 'gray'} onClick={toolRef} title='Start from know point' /></div>

                    <div><Icon name='merge_type' color={tool.snap ? 'dodgerblue' : 'gray'} onClick={toolSnap} title='Snap to point/line' /></div>

                    <div><Icon name='hdr_strong' color={tool.offset ? 'dodgerblue' : 'gray'} onClick={toolOffset} title='Offset from finger/mouse' /></div>

                  </> : null

                }

                {tool.edit ?

                  <>

                    <div><Icon name='flip_to_front' color={tool.movePt ? 'dodgerblue' : 'gray'} onClick={toggleMovePt} title='Move Pts' /></div>

                    <div><Icon iconClass={tool.insertPt ? 'material-icons' : 'material-icons-outlined'} name='add_box' color={tool.insertPt ? 'dodgerblue' : 'gray'} onClick={toggleInsertPt} title='Insert Pt into line' /></div>

                    <div><Icon iconClass={tool.deletePt ? 'material-icons' : 'material-icons-outlined'} name='disabled_by_default' color={tool.deletePt ? 'tomato' : 'gray'} onClick={toggleDeletePt} title='Delete Pt inside line' /></div>

                    {props.user.userlevel === 'admin' ? <div><Icon iconClass={tool.offsetPt ? 'material-icons' : 'material-icons-outlined'} name='animation' color={tool.offsetPt ? 'dodgerblue' : 'gray'} onClick={toggleOffsetPt} title='Adjust coordinates with constant' /></div> : null}

                    <div><Icon name='cancel' color='tomato' onClick={toolEditCancel} title='Cancel Edits' outline={true} /></div>

                    <div><Icon name='check_circle' color='dodgerblue' onClick={toolEditAccept} title='Accept Edits' /></div>

                  </> : null

                }

                {tool.draw ?

                  <>

                    <div><Icon name='add_location' color={tool.locate ? 'dodgerblue' : 'gray'} onClick={toolLocate} title='Add point by N/E' /></div>

                    <div><Icon name='center_focus_strong' onClick={toolCenter} title='Center on last drawn point' /></div>

                    <div><Icon name='format_color_fill' onClick={toolFill} title='Fill polygon' /></div>

                    <div><Icon name='close_fullscreen' onClick={toolJoin} title='Join two points' /></div>

                    <div><Icon name='cancel' color='tomato' onClick={toolDelete} title='Cancel Drawing' outline={true}/></div>

                    <div><Icon name='undo' onClick={toolUndo} title='Undo' /></div>

                    <div><Icon name='redo' onClick={toolRedo} title='Redo' /></div>

                    {style.tool === 'temporary' ? null :

                      <div>
                        <Icon 
                          name='check_circle' 
                          onClick={
                            props.mode === 'plansIssue' || 
                            props.mode === 'plansTest' || 
                            props.mode === 'plansLab' || 
                            props.mode === 'plansPresat' || 
                            props.mode === 'plansFilterPolygon' ? 
                            toolCoordsAccept : toolAdd
                          } 
                          title='Save' />
                      </div>

                    }

                  </> : null

                }

              </div> : null

            }

            {props.mode === 'plansmanage' ?

              <div className='toolDrawMenu' style={{position: 'absolute', top: 10, left: 10, zIndex: 2}}>

                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                  <Icon name='clear' color='tomato' onClick={props.closeModal} title='Cancel Edits' />

                  <span style={{marginRight: 10}}><b>Pt1</b></span>

                  <input style={{width: 75}} className='input' type="number" min={0} step={0.01} pattern="\d{1,}(\.\d{1,})?" placeholder='North' name='n1' onInput={validate} onChange={props.changedData} defaultValue={props.planInfo.n1} required />

                  <input style={{width: 75}} className='input' type="number" min={0} step={0.01} pattern="\d{1,}(\.\d{1,})?" placeholder='East' name='e1' onInput={validate} onChange={props.changedData} defaultValue={props.planInfo.e1} required />

                  <span style={{marginRight: 10}}><b>Pt2</b></span>

                  <input style={{width: 75}} className='input' type="number" min={0} step={0.01} pattern="\d{1,}(\.\d{1,})?" placeholder='North' name='n2' onInput={validate} onChange={props.changedData} defaultValue={props.planInfo.n2} required />

                  <input style={{width: 75}} className='input' type="number" min={0} step={0.01} pattern="\d{1,}(\.\d{1,})?" placeholder='East' name='e2' onInput={validate} onChange={props.changedData} defaultValue={props.planInfo.e2} required />

                  {tool.edit === 'derpy' ?

                    <>

                    <span style={{marginRight: 10}}><b>Angle</b></span>

                    <input style={{width: 75}} className='input' type="number" min={0} step={0.01} pattern="\d{1,}(\.\d{1,})?" name='' onInput={validate} onChange={null} defaultValue={props.planInfo.angle} required />

                    </> : null

                  }

                  <Icon name='check_circle' color='dodgerblue' onClick={toolEditAccept} title='Accept Edits' />

                </div>

              </div> : null

            }

          </div>

        </div>

      </div>

      {tool.layer === 'derp' ? <Modal closeModal={closeLayer} content={contentLayer} /> : null}

      {tool.fastDrawing && tool.draw ?

        <div className="fastDrawingContainter">

          <div className='fastDrawingContents'>      

            <div style={{display: 'inline-block'}}>
              <label className='label'>Elev.</label>
              <input style={{width: 75}} className='input' type="number" step={0.01} pattern="-?\d{1,}(\.\d{1,})?" name='elev' onInput={validate} onChange={isModal.edit ? isEdited : null} placeholder='Optional' defaultValue={isModal.edit ? isValidatedEdit.elev : isValidated.elev} required />
            </div>

            <div style={{display: 'inline-block'}}>
              <label className='label'>Description</label>
              <textarea className='textArea' pattern="[a-zA-Z0-9]{1,}" name='description' onInput={validate} onChange={isModal.edit ? isEdited : null} placeholder='Optional if preset' defaultValue={isModal.edit ? isValidatedEdit.description : isValidated.description} required></textarea>
            </div>            

          </div>

        </div> : null

      }

      {tool.offsetPt && tool.edit ?

        <div className="fastDrawingContainter">

          <div className='fastDrawingContents'>          

            <div style={{display: 'inline-block'}}>
              <label className='label'>N Offset</label>
              <input style={{width: 75}} className='input' type="number" step={0.01} pattern="-?\d{1,}(\.\d{1,})?" name='n' onInput={validateOffsetPt} placeholder='Optional' />
            </div>

            <div style={{display: 'inline-block'}}>
              <label className='label'>E Offset</label>
              <input style={{width: 75}} className='input' type="number" step={0.01} pattern="-?\d{1,}(\.\d{1,})?" name='e' onInput={validateOffsetPt} placeholder='Optional' />
            </div>

            <Icon name='control_point_duplicate' color='dodgerblue' onClick={offsetPtEdit} title='Preview offset' outline={true} />

          </div>

        </div> : null

      }

      {tool.scaleDrawings ?

        <div className="fastDrawingContainter">

          <div className='fastDrawingContents'>

            <div className="slideContainer">
              <input type="range" min="0.1" max="2" step='0.1' defaultValue={sliderScale.current} name='sliderScale' onInput={updateSliderScale} />
            </div>        

          </div>

        </div> : null

      }

      {tool.locate || tool.add || isModal.edit ?
        <Modal
          closeModal={isModal.edit ? closeEdit : closeModal}
          add_alt={tool.locate && (style.tool === 'planspencil' || style.tool === 'plansmarker' || style.tool === 'planarrow' || style.tool === 'plansslope' || props.mode === 'plansFilterPolygon') ? addLocation : null}
          add={            
            props.mode === 'plansIssue' || props.mode === 'plansTest' || props.mode === 'plansLab' || props.mode === 'plansPresat' || props.mode === 'plansFilterPolygon' ? 
            toolCoordsAccept :
            isModal.edit ? editDrawing : addDrawing
          }
          delete={isModal.edit ? deleteDrawing : null}
          height={'none'}
          maxHeight={'calc(100% - 30px)'}
          maxWidth={300}
          isValidated={isValidatedEdit}
          isModal={isModal}
          content={
            <div style={{display: 'inline-block', textAlign: 'center', margin: 10}}>

              <div style={{marginBottom: 5}}>
                {
                  props.mode === 'plansFilterPolygon' ?
                  <button className='stylePreset' style={{backgroundColor: 'black', color: 'white'}}>Filter</button> :
                  isModal.edit && styleEdit.current.presetId !== '' && styleEdit.current.presetId !== null ?
                  <button className='stylePreset' style={{backgroundColor: styleEdit.current.color, color: styleEdit.current.color.substr(0,7) === '#000000' ? 'white' : 'black'}} onClick={toolStyle}>{styleEdit.current.presetName}</button> :
                  tool.draw && style.presetId !== '' && style.presetId !== null ?
                  <button className='stylePreset' style={{backgroundColor: style.color, color: style.color.substr(0,7) === '#000000' ? 'white' : 'black'}} onClick={toolStyle}>{style.presetName}</button> : null
                }
              </div>

              {tool.locate || isModal.edit ?

                <>

                  <div style={{display: 'inline-block'}}>
                    <label>Northing</label><br/>
                    <input style={{width: 75}} className='input' type="number" min={0} step={0.01} pattern="\d{1,}(\.\d{1})?" name='n' onInput={validate} onChange={isModal.edit ? isEdited : null} defaultValue={isModal.edit ? isValidatedEdit.n : isValidated.n} required />
                  </div>

                  <div style={{display: 'inline-block'}}>
                    <label>Easting</label><br/>
                    <input style={{width: 75}} className='input' type="number" min={0} step={0.01} pattern="\d{1,}(\.\d{1})?" name='e' onInput={validate} onChange={isModal.edit ? isEdited : null} defaultValue={isModal.edit ? isValidatedEdit.e : isValidated.e} required />
                  </div>

                </> : null

              }

              {isModal.edit && (isValidatedEdit.tool === 'attitude' || isValidatedEdit.tool === 'point') || !isModal.edit && (style.tool === 'attitude' || style.tool === 'point') ?

                <div style={{display: 'inline-block'}}>
                  <label>Type</label><br/>
                  <select className='select' pattern=".{1,}" name='type' onInput={validate} onChange={isModal.edit ? isEdited : null} defaultValue={isModal.edit ? isValidatedEdit.type : isValidated.type} required>
                    <option value=""></option>
                    {isModal.edit && isValidatedEdit.tool === 'attitude' || !isModal.edit && style.tool === 'attitude' ?
                      <>
                        <option value="bedding">Bedding</option>
                        <option value="oBedding">Overturned Bedding</option>
                        <option value="hBedding">Horizontal Bedding</option>
                        <option value="vBedding">Vertical Bedding</option>
                        <option value="joint">Joint</option>
                        <option value="vJoint">Vertical Joint</option>
                        <option value="foliation">Foliation</option>
                        <option value="vFoliation">Vertical Foliation</option>
                        <option value="faultShear">Fault of Shear</option>
                        <option value="vFault">Vertical Fault</option>
                        <option value="SlipSlide">Slip Surface or Slide Plane</option>
                      </> : null
                    }
                    {isModal.edit && isValidatedEdit.tool === 'point' || !isModal.edit && style.tool === 'point' ?
                      <>
                        <option value="controlPoint">Control Point</option>
                        <option value="pointOfInterest">Point of Interest</option>
                        <option value="contact">Contact</option>
                      </> : null
                    }
                  </select>
                </div> : null

              }

              {isModal.edit && isValidatedEdit.tool === 'attitude' || !isModal.edit && style.tool === 'attitude' ?

                <>

                  <p className="plansdraw_style_header-text" style={{margin: 5}}><i>Option A</i></p>

                  <div style={{display: 'inline-block'}}>
                    <label>Strike</label><br/>
                    <select style={{width: 75}} className='select' pattern=".{1,}" name='bearingStrike' onInput={validate} onChange={isModal.edit ? isEdited : null} defaultValue={isModal.edit ? isValidatedEdit.bearingStrike : isValidated.bearingStrike} required>
                      <option value=""></option>
                      <option value="W">NW</option>
                      <option value="E">NE</option>
                    </select>
                  </div>

                  <div style={{display: 'inline-block'}}>
                    <label>Bearing</label><br/>
                    <input style={{width: 75}} className='input' type="number" pattern="\d{1,2}" name='bearing90' placeholder="0-90" onInput={validate} onChange={isModal.edit ? isEdited : null} defaultValue={isModal.edit ? isValidatedEdit.bearing90 : isValidated.bearing90} required />
                  </div>

                  <div style={{display: 'inline-block'}}>
                    <label>Dip Angle</label><br/>
                    <input style={{width: 75}} className='input' type="number" pattern="\d{1,4}" name='dip' onInput={validate} onChange={isModal.edit ? isEdited : null} defaultValue={isModal.edit ? isValidatedEdit.dip : isValidated.dip} required />
                  </div>

                  <div style={{display: 'inline-block'}}>
                    <label>Dip Direction</label><br/>
                    <select style={{width: 75}} className='select' pattern=".{1,}" name='bearingDipDirection' onInput={validate} onChange={isModal.edit ? isEdited : null} defaultValue={isModal.edit ? isValidatedEdit.bearingDipDirection : isValidated.bearingDipDirection} required>
                      <option value=""></option>
                      <option value="N">N</option>
                      <option value="S">S</option>
                      <option value="W">W</option>
                      <option value="E">E</option>
                    </select>
                  </div>

                  <p className="plansdraw_style_header-text" style={{margin: 5}}><i>Option B</i></p>

                  <div style={{display: 'inline-block'}}>
                    <label>Bearing</label><br/>
                    <input style={{width: 75}} className='input' type="number" pattern="\d{1,3}" name='bearing' placeholder="0-360" onInput={validate} onChange={isModal.edit ? isEdited : null} value={isModal.edit ? isValidatedEdit.bearing : isValidated.bearing} required />
                  </div>

                </> : null

              }

              <div style={{display: 'inline-block'}}>
                <label>Elev.</label><br/>
                <input style={{width: 75}} className='input' type="number" step={0.01} pattern="-?\d{1,}(\.\d{1,})?" name='elev' onInput={validate} onChange={isModal.edit ? isEdited : null} placeholder='Optional' defaultValue={isModal.edit ? isValidatedEdit.elev : isValidated.elev} required />
              </div>

              <div>
                <label>Description
                  <textarea className='textArea' pattern="[a-zA-Z0-9]{1,}" name='description' onInput={validate} onChange={isModal.edit ? isEdited : null} placeholder='Optional if preset' defaultValue={isModal.edit ? isValidatedEdit.description : isValidated.description} required></textarea>
                </label>
              </div>
              
              {
                isModal.edit && isValidatedEdit.image !== '' ? 
                <a href={`images/image${isValidatedEdit.id}.jpeg`}>View Photo</a> : 
                isValidated.image !== '' ? <a href={`images/image${isValidated.id}.jpeg`}>View Photo</a> : null
              }

              <div><CircleButton iconName='add_a_photo' onClick={toolPhoto} /></div>

            </div>
          }
        /> : null
      }

      {tool.style ?
        <Modal
          closeModal={closeStyle}
          height={'none'}
          maxHeight={'calc(100% - 30px)'}
          content={
            <div>

              <div className="plansdraw_style_hide">

                <div className="plansdraw_style_preset-container">

                  <div className="plansdraw_style_header border_bottom">

                    <p className="plansdraw_style_header-text" style={{margin: 5}}><i>Presets</i></p>

                  </div>
        
                  <div className="plansdraw_style_preset-options border_bottom">

                    <div>
                      <h3>General</h3>

                      <div style={{display: 'inline-block'}}>
                        <button className='stylePreset' style={{backgroundColor: 'tomato', color: 'black'}} name='preset' data-name='issue' onClick={stylePreset}>Issue</button>
                      </div>

                      <div style={{display: 'inline-block'}}>
                        <button className='stylePreset' style={{backgroundColor: 'black', color: 'white'}} name='preset' data-name='test' onClick={stylePreset}>Test</button>
                      </div>

                      <div style={{display: 'inline-block'}}>
                        <button className='stylePreset' style={{backgroundColor: 'brown', color: 'black'}} name='preset' data-name='lab' onClick={stylePreset}>Lab</button>
                      </div>

                      <div style={{display: 'inline-block'}}>
                        <button className='stylePreset' style={{backgroundColor: 'blue', color: 'white'}} name='preset' data-name='presat' onClick={stylePreset}>Presat</button>
                      </div>

                      <div style={{display: 'inline-block'}}>
                        <button className='stylePreset' style={{backgroundColor: 'gold', color: 'black'}} name='preset' data-name='temporary' onClick={stylePreset}>Temporary</button>
                      </div>
                    </div>

                  </div>

                  <div className="plansdraw_style_preset-options border_bottom">

                    {listOfPresetTypes}

                  </div>

                </div>

              </div>

              <div className="plansdraw_style_custom-container">

                <div className="plansdraw_style_header border_bottom">

                    <p
                      className="plansdraw_style_header-text"
                      style={{margin: 5, cursor: 'pointer', textDecoration: tool.custom ? 'none' : 'line-through'}}
                      onClick={toggleCustom}
                    >
                      <i>Custom</i>
                    </p>

                </div>

                {tool.custom ?
                  <CustomTools
                    style={style}
                    styleTool={styleTool}
                    styleColor={styleColor}
                    styleColorOther={styleColorOther}
                    styleWidth={styleWidth}
                    styleLine={styleLine}
                    who={'planDrawings'}
                  /> : null
                }

              </div>

            </div>
          }
        /> : null
      }

      {isColor ?
        <Modal
          closeModal={null}
          content={
            <div className="inputcontainer textaligncenter">

                <div id="enter-area">

                    <input id="slider-hue" type="range" min="0" max="360" value="170" />

                    <div id="color-info"></div>

                </div>

                <div id="table-wrap">

                   <table id="swatch-table"></table>

                </div>

            </div>
          }
        /> : null
      }

      {isModal.help ?
        <Modal
          closeModal={closeHelp}
          content={
            <div>

              <table>
                <thead>
                  <tr>
                    <th>Tool</th>
                    <th>Descrip.</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td><Icon name='close' color='tomato' /></td>
                    <td style={{textAlign: 'left'}}><u><b>Close:</b></u> closes menus and plan view. This will not delete a drawing, unless you close plan view, but you will be warned first</td>
                  </tr>

                  <tr>
                    <td><Icon name='edit' /></td>
                    <td style={{textAlign: 'left'}}><b>Draw:</b> allows drawing objects</td>
                  </tr>

                  <tr>
                    <td><Icon name='layers' /></td>
                    <td style={{textAlign: 'left'}}><b>Layers:</b> allows filtering and searching of drawings</td>
                  </tr>

                  <tr>
                    <td><Icon name='construction' /></td>
                    <td style={{textAlign: 'left'}}><b>Edit:</b> allows editing the positions of drawings, tests and lab</td>
                  </tr>

                  <tr>
                    <td><Icon name='flip_to_front' /></td>
                    <td style={{textAlign: 'left'}}><b>Move Pt:</b> enables moving an object in Edit Mode</td>
                  </tr>

                  <tr>
                    <td><Icon name='add_box' /></td>
                    <td style={{textAlign: 'left'}}><b>Insert Pt:</b> allows inserting of points into lines in Edit Mode</td>
                  </tr>

                  <tr>
                    <td><Icon name='disabled_by_default' color='tomato' /></td>
                    <td style={{textAlign: 'left'}}><b>Delete Pt:</b> allows deleting of points inside lines in Edit Mode</td>
                  </tr>

                  <tr>
                    <td><Icon name='architecture' /></td>
                    <td style={{textAlign: 'left'}}><b>Ref:</b> allows starting from a known reference point and then offsetting a know distance to begin drawing</td>
                  </tr>

                  <tr>
                    <td><Icon name='merge_type' /></td>
                    <td style={{textAlign: 'left'}}><b>Snap:</b> allows snapping to a point or line</td>
                  </tr>

                  <tr>
                    <td><Icon name='hdr_strong' /></td>
                    <td style={{textAlign: 'left'}}><b>Offset:</b> drawing point is offset from finger/mouse for better visibility</td>
                  </tr>

                  <tr>
                    <td><Icon name='add_location' /></td>
                    <td style={{textAlign: 'left'}}><b>Locate:</b> locate drawing with a know Northing and Easting</td>
                  </tr>

                  <tr>
                    <td><Icon name='center_focus_strong' /></td>
                    <td style={{textAlign: 'left'}}><b>Center:</b> returns focus to last drawn point. If you start to draw, and then scroll away to view something, this feature will return you to the drawing.</td>
                  </tr>

                  <tr>
                    <td><Icon name='format_color_fill' /></td>
                    <td style={{textAlign: 'left'}}><b>Fill:</b> allows filling in a closed polygon</td>
                  </tr>

                  <tr>
                    <td><Icon name='close_fullscreen' /></td>
                    <td style={{textAlign: 'left'}}><b>Join:</b> joins the starting point and last drawn point for a polygon</td>
                  </tr>

                  <tr>
                    <td><Icon name='cancel' color='tomato' outline={true}/></td>
                    <td style={{textAlign: 'left'}}><b>Cancel:</b> cancels a drawing or edits</td>
                  </tr>

                  <tr>
                    <td><Icon name='undo' /></td>
                    <td style={{textAlign: 'left'}}><b>Undo:</b> removes the last segment of the polygon</td>
                  </tr>

                  <tr>
                    <td><Icon name='redo' /></td>
                    <td style={{textAlign: 'left'}}><b>Redo:</b> redraws the last segment of the polygon</td>
                  </tr>

                  <tr>
                    <td><Icon name='check_circle' /></td>
                    <td style={{textAlign: 'left'}}><b>Done:</b> saves the drawing</td>
                  </tr>

                  <tr>
                    <td><Icon name='add_circle' /></td>
                    <td style={{textAlign: 'left'}}><b>Add:</b> when using Locate, this will add a line segment</td>
                  </tr>

                  <tr>
                    <td><Icon name='refresh' /></td>
                    <td style={{textAlign: 'left'}}><b>Refresh:</b> refreshes the drawings. If another user edits the drawings, you can refresh and see the edits</td>
                  </tr>

                  <tr>
                    <td><Icon name='restore_page' /></td>
                    <td style={{textAlign: 'left'}}><b>Reload:</b> reloads the PDF</td>
                  </tr>

                  <tr>
                    <td><Icon name='gamepad' /></td>
                    <td style={{textAlign: 'left'}}><b>Navigate:</b> while in drawing mode, scrolling is disabled. Navigate allows you to move the screen</td>
                  </tr>

                  <tr>
                    <td><Icon name='warning' color='tomato' /></td>
                    <td style={{textAlign: 'left'}}><b>Required ReTests:</b> while using filter, shows only tests without a retest</td>
                  </tr>
                </tbody>
              </table>

            </div>
          }
        /> : null
      }

      {tool.photo && props.user.userlevel === 'admin' ?
        <Modal
          closeModal={closePhoto}
          content={
            <div>

              {camera.start ?

                <div style={styleBtn} onClick={startCamera}>
                  <Icon name='play_arrow' outline={true} />
                  <span style={{marginRight: 10}}><b>Start Camera</b></span>
                </div> : null

              }

              {camera.retry ?

                <div style={{display: 'flex'}}>

                  <div style={styleBtn} onClick={startCamera}>
                    <Icon name='reset_image' outline={true} />
                    <span style={{marginRight: 10}}><b>Try Again</b></span>
                  </div>

                  <div style={styleBtn} onClick={savePhoto}>
                    <Icon name='add_a_photo' outline={true} />
                    <span style={{marginRight: 10}}><b>Save Photo</b></span>
                  </div>

                </div> : null

              } 
              
              <video id="video" onLoad={startCamera} autoplay></video>
              
              <canvas id="canvasPhoto" ref={canvasPhoto.current}></canvas>

              {camera.takePhoto ?

                <div style={styleBtn} onClick={takePhoto}>
                  <Icon name='photo_camera' outline={true} />
                  <span style={{marginRight: 10}}><b>Take Photo</b></span>
                </div> : null

              }

            </div>
          }
        /> : null
      }

    </div>
  )
}

export default PlanDrawings
